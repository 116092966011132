const ESTADO_INICIAL = {
    manejador: "",
    imagemPerfil: '',
    verificadorBloqueioFinanceiro: false,
    revelarTutorial: false,
    expandirNavegacao: true,
    revelarCancelamentoDeConta: false,
    revelarAtribuirLinhas: false,
    revelarDesatribuirLinhas: false,
    revelarIotTelecom: false,
    revelarAtivarLinhasAWS: false,
    revelarLiberarSms: false,
    revelarBloquearSms: false,
    revelarEnviarSms: false,
    revelarAdicionarPacoteExtra: false,
    revelarReset: false,
    revelarTesteConexao: false,
    revelarFalhaNaOperadora: false,
    revelarSuporteTecnico: false,
    revelarRealocacao: false,
    revelarCancelamentoReposicao: false,
    revelarCancelamento: false,
    revelarDesbloqueioCiclo: false, 
    revelarAtivarLinha: false,
    revelarReativarLinha: false,
    revelarDesbloquearUso: false,
    revelarRemoverBloqueioTemporario: false,
    revelarSuspenderLinha: false,
    revelarBloquearUso: false,
    revelarBloquearTemporariamento: false,
    revelarAlterarPlanoValor: false,
    revelarRenegociarContrato: false,
    revelarTrocaDeTitularidade: false,
    revelarAtivarDemonstracao: false,
    revelarDesativarDemonstracao: false,
    revelarMapa: false,
    revelarImportarDescricao: false,
    revelarImportarClientesUsuarios: false,
    revelarRelatorioConsumo: false,
    desabilitarAcoes: true,
    conteudoBusca: '',
    revelarAlterarGrupo: false,
    revelarConfigurarGrupo: false,
    limpouBusca: false,
    realizouBusca: false,
    realizouFiltro: false,
    revelarExibirColunas: false,
    revelarNovoPedidoSMS: false,
    ordenador: '',
    separadorLinha: "",
    simcardSelecionados: [],
    renderiza: false,
    setRenderiza: '',
    operadorasSelecionadas: [],
    revelarNovoUsuario: false,
    revelarEditarUsuario: false,
    revelarExcluirUsuario: false,
    revelarNovoCliente: false,
    revelarEditarCliente: false,
    revelarExcluirCliente: false,
    revelarNovoElemento: false,
    revelarEditarElemento: false,
    revelarExcluirElemento: false,
    revelarNovoModulo: false,
    revelarEditarModulo: false,
    revelarExcluirModulo: false,
    revelarNovoPerfil: false,
    revelarEditarPerfil: false,
    revelarExcluirPerfil: false,
    revelarNovoSistema: false,
    revelarEditarSistema: false,
    revelarExcluirSistema: false,
    revelarNovoTela: false,
    revelarEditarTela: false,
    revelarExcluirTela: false,
    exportarLinhas: false,
    exportarLinhasOperadora: false,
    exportarOperadora:false,
    exportarCdSms:false,
    modoSelecaoSimcard: false,
    esconderAcoesDispositivosClientes: false,
    revelarNovoMenu: false,
    revelarEditarMenu: false,
    revelarExcluirMenu: false,
    revelarTransferirLinhaClienteFinal: false,
    revelarNovoPedidoImportado: false,
    revelarDeletarPedidoImportado: false,
    RevelarBuscarEFiltro: true,
    revelarRelatorioDisponibilidade: false,
    exportarLinhasDesbloqueioCiclo: false,
}

export const manejadorReducer = (state = ESTADO_INICIAL, action) => {
    if (action.type === 'MUDAR_MANEJADOR') {
        return {
            ...state,
            manejador: action.parametro
        };
    } else if (action.type === "IMAGE_PERFIL") {
        return {
            ...state,
            imagemPerfil: action.parametro
        }
    } else if (action.type === "MODO_SELECAO_SIMCARD") {
        return {
            ...state,
            modoSelecaoSimcard: action.parametro
        }
    } else if (action.type === "VERIFICADOR_BLOQUEIO_FINANCEIRO") {
        return {
            ...state,
            verificadorBloqueioFinanceiro: action.parametro
        }
    } else if (action.type === "REVELAR_RELATORIO_CONSUMO_DISPOSITIVOS") {
        return {
            ...state,
            revelarRelatorioConsumo: action.parametro
        }
    }
    else if (action.type === "REVELAR_RELATORIO_DISPONIBILIDADE_DISPOSITIVOS") {
        return {
            ...state,
            revelarRelatorioDisponibilidade: action.parametro
        }
    }
    else if (action.type === "EXPORTAR_LINHAS_DISPOSITIVOS") {
        return {
            ...state,
            exportarLinhas: action.parametro
        }
    } else if (action.type === "EXPORTAR_LINHAS_DISPOSITIVOS_OPERADORA") {
        return {
            ...state,
            exportarLinhas: action.parametro,
            exportarOperadora: true
        }
    } else if (action.type === "EXPORTAR_OPERADORA") {
        return {
            ...state,
            exportarOperadora: action.parametro,
        }
    } else if (action.type === "REVELAR_TUTORIAL") {
        return {
            ...state,
            revelarTutorial: action.parametro
        }
    } else if (action.type === "EXPANDIR_NAVEGACAO") {
        return {
            ...state,
            expandirNavegacao: action.parametro
        }
    } else if (action.type === "EXPANDIR_NAVEGACAO_HOVER") {
        return {
            ...state,
            expandirNavegacaoHover: action.parametro
        }
    } else if (action.type === "REVELAR_CANCELAMENTO_DE_CONTA") {
        return {
            ...state,
            revelarCancelamentoDeConta: action.parametro
        }
    } else if (action.type === "REVELAR_ATRIBUIR_LINHAS") {
        return {
            ...state,
            revelarAtribuirLinhas: action.parametro
        }
    } else if (action.type === "REVELAR_DESATRIBUIR_LINHAS") {
        return {
            ...state,
            revelarDesatribuirLinhas: action.parametro
        }
    } else if (action.type === "REVELAR_ATIVACAO_SIMCARD") {
        return {
            ...state,
            revelarIotTelecom: action.parametro
        }
    }  else if (action.type === "REVELAR_ATIVACAO_LINHAS_AWS") {
        return {
            ...state,
            revelarAtivarLinhasAWS: action.parametro
        }
    } else if (action.type === "REVELAR_LIBERAR_SMS") {
        return {
            ...state,
            revelarLiberarSms: action.parametro
        }
    }else if (action.type === "REVELAR_DESBLOQUEIO_CICLO") {
        return {
            ...state,
            revelarDesbloqueioCiclo: action.parametro
        }
    } else if (action.type === "REVELAR_BLOQUEAR_SMS") {
        return {
            ...state,
            revelarBloquearSms: action.parametro
        }
    } else if (action.type === "exportar_CdSms") {
        return {
            ...state,
            exportarCdSms: action.parametro
        }

    } else if (action.type === "REVELAR_EXPORTAR_DESBLOQUEIO_CICLO")
    {
        return {
            ...state , 
            exportarLinhasDesbloqueioCiclo: action.parametro
        }
    }
    else if (action.type === "REVELAR_ENVIAR_SMS") {
        return {
            ...state,
            revelarEnviarSms: action.parametro
        }
    } else if (action.type === "REVELAR_ADICIONAR_PACOTE_EXTRA") {
        return {
            ...state,
            revelarAdicionarPacoteExtra: action.parametro
        }
    } else if (action.type === "REVELAR_RESET") {
        return {
            ...state,
            revelarReset: action.parametro
        }
    } else if (action.type === "REVELAR_TESTE_CONEXAO") {
        return {
            ...state,
            revelarTesteConexao: action.parametro
        }
    } else if (action.type === "REVELAR_FALHAR_NA_OPERADORA") {
        return {
            ...state,
            revelarFalhaNaOperadora: action.parametro
        }
    } else if (action.type === "REVELAR_SUPORTE_TECNICO") {
        return {
            ...state,
            revelarSuporteTecnico: action.parametro
        }
    } else if (action.type === "REVELAR_REALOCACAO") {
        return {
            ...state,
            revelarRealocacao: action.parametro
        }
    } else if (action.type === "REVELAR_CANCELAMENTO_REPOSICAO") {
        return {
            ...state,
            revelarCancelamentoReposicao: action.parametro
        }
    } else if (action.type === "REVELAR_CANCELAMENTO") {
        return {
            ...state,
            revelarCancelamento: action.parametro
        }
    } else if (action.type === "REVELAR_ATIVAR_LINHA") {
        return {
            ...state,
            revelarAtivarLinha: action.parametro
        }
    } else if (action.type === "REVELAR_REATIVAR_LINHA") {
        return {
            ...state,
            revelarReativarLinha: action.parametro
        }
    } else if (action.type === "REVELAR_DESBLOQUEAR_USO") {
        return {
            ...state,
            revelarDesbloquearUso: action.parametro
        }
    } else if (action.type === "REVELAR_REMOVER_BLOQUEIO_TEMPORARIO") {
        return {
            ...state,
            revelarRemoverBloqueioTemporario: action.parametro
        }
    } else if (action.type === "REVELAR_SUSPENDER_LINHA") {
        return {
            ...state,
            revelarSuspenderLinha: action.parametro
        }
    } else if (action.type === "REVELAR_BLOQUEAR_USO") {
        return {
            ...state,
            revelarBloquearUso: action.parametro
        }
    } else if (action.type === "REVELAR_BLOQUEAR_TEMPORARIAMENTE") {
        return {
            ...state,
            revelarBloquearTemporariamento: action.parametro
        }
    } else if (action.type === "REVELAR_ALTERAR_PLANO_VALOR") {
        return {
            ...state,
            revelarAlterarPlanoValor: action.parametro
        }
    } else if (action.type === "REVELAR_RENEGOCIAR_CONTRATO") {
        return {
            ...state,
            revelarRenegociarContrato: action.parametro
        }
    }  else if (action.type === "REVELAR_TROCA_DE_TITULARIDADE") {
        return {
            ...state,
            revelarTrocaDeTitularidade: action.parametro
        }
    } else if (action.type === "REVELAR_ATIVAR_DEMONSTRACAO") {
        return {
            ...state,
            revelarAtivarDemonstracao: action.parametro
        }
    } else if (action.type === "REVELAR_DESATIVAR_DEMONSTRACAO") {
        return {
            ...state,
            revelarDesativarDemonstracao: action.parametro
        }
    } else if (action.type === "CONTEUDO_BUSCA") {
        return {
            ...state,
            conteudoBusca: action.parametro
        }
    } else if (action.type === "SEPARADOR_LINHA_BUSCA") {
        return {
            ...state,
            separadorLinha: action.parametro
        }
    } else if (action.type === "SIMCARD_SELECIONADOS") {
        return {
            ...state,
            simcardSelecionados: action.parametro
        }
    } else if (action.type === "RENDERIZA") {
        return {
            ...state,
            renderiza: action.parametro
        }
    } else if (action.type === "SET_RENDERIZA") {
        return {
            ...state,
            setRenderiza: action.parametro
        }
    }
    else if (action.type === "OPERADORAS_SELECIONADAS") {
        return {
            ...state,
            operadorasSelecionadas: action.parametro
        }
    }
     else if (action.type === "DESABILITAR_ACOES") {
        return {
            ...state,
            desabilitarAcoes: action.parametro
        }
    } else if (action.type === "LIMPOU_BUSCA") {
        return {
            ...state,
            limpouBusca: action.parametro
        }
    } else if (action.type === "REALIZOU_BUSCA") {
        return {
            ...state,
            realizouBusca: action.parametro
        }
    } else if (action.type === "REALIZOU_FILTRO") {
        return {
            ...state,
            realizouFiltro: action.parametro
        }
    } else if (action.type === "REVELAR_MAPA") {
        return {
            ...state,
            revelarMapa: action.parametro
        }
    } else if (action.type === "REVELAR_ALTERAR_GRUPO") {
        return {
            ...state,
            revelarAlterarGrupo: action.parametro
        }
    } else if (action.type === "REVELAR_CONFIGURAR_GRUPO") {
        return {
            ...state,
            revelarConfigurarGrupo: action.parametro
        }
    } else if (action.type === "EXIBIR_COLUNAS") {
        return {
            ...state,
            revelarExibirColunas: action.parametro
        }
    } else if (action.type === "REVELAR_IMPORTAR_DESCRICAO") {
        return {
            ...state,
            revelarImportarDescricao: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_PEDIDO_SMS") {
        return {
            ...state,
            revelarNovoPedidoSMS: action.parametro
        }
    }else if (action.type === 'REVELAR_RELATORIO_ENVIO_SMS'){
        return {
            ...state,
            revelarRelatorioEnvioSms : action.parametro
        }
    } else if (action.type === "MUDAR_ORDENADOR") {
        return {
            ...state,
            ordenador: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_USUARIO") {
        return {
            ...state,
            revelarNovoUsuario: action.parametro
        }
    } else if (action.type === "REVELAR_EDITAR_USUARIO") {
        return {
            ...state,
            revelarEditarUsuario: action.parametro
        }
    } else if (action.type === "REVELAR_EXCLUIR_USUARIO") {
        return {
            ...state,
            revelarExcluirUsuario: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_CLIENTE") {
        return {
            ...state,
            revelarNovoCliente: action.parametro
        }
    } else if (action.type === "REVELAR_EDITAR_CLIENTE") {
        return {
            ...state,
            revelarEditarCliente: action.parametro
        }
    } else if (action.type === "REVELAR_EXCLUIR_CLIENTE") {
        return {
            ...state,
            revelarExcluirCliente: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_ELEMENTO") {
        return {
            ...state,
            revelarNovoElemento: action.parametro
        }
    } else if (action.type === "REVELAR_EDITAR_ELEMENTO") {
        return {
            ...state,
            revelarEditarElemento: action.parametro
        }
    } else if (action.type === "REVELAR_EXCLUIR_ELEMENTO") {
        return {
            ...state,
            revelarExcluirElemento: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_MODULO") {
        return {
            ...state,
            revelarNovoModulo: action.parametro
        }
    } else if (action.type === "REVELAR_EDITAR_MODULO") {
        return {
            ...state,
            revelarEditarModulo: action.parametro
        }
    } else if (action.type === "REVELAR_EXCLUIR_MODULO") {
        return {
            ...state,
            revelarExcluirModulo: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_PERFIL") {
        return {
            ...state,
            revelarNovoPerfil: action.parametro
        }
    } else if (action.type === "REVELAR_EDITAR_PERFIL") {
        return {
            ...state,
            revelarEditarPerfil: action.parametro
        }
    } else if (action.type === "REVELAR_EXCLUIR_PERFIL") {
        return {
            ...state,
            revelarExcluirPerfil: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_SISTEMA") {
        return {
            ...state,
            revelarNovoSistema: action.parametro
        }
    } else if (action.type === "REVELAR_EDITAR_SISTEMA") {
        return {
            ...state,
            revelarEditarSistema: action.parametro
        }
    } else if (action.type === "REVELAR_EXCLUIR_SISTEMA") {
        return {
            ...state,
            revelarExcluirSistema: action.parametro
        }
    } else if (action.type === "REVELAR_NOVO_TELA") {
        return {
            ...state,
            revelarNovoTela: action.parametro
        }
    } else if (action.type === "REVELAR_EDITAR_TELA") {
        return {
            ...state,
            revelarEditarTela: action.parametro
        }
    } else if (action.type === "REVELAR_EXCLUIR_TELA") {
        return {
            ...state,
            revelarExcluirTela: action.parametro
        }
    }
    else if (action.type === "ESCONDER_ACOES_DISPOSITIVOS_BLOQUEIO") {
        return {
            ...state,
            esconderAcoesDispositivosClientes: action.parametro
        }
    }
    else if (action.type === "REVELAR_NOVO_MENU") {
        return {
            ...state,
            revelarNovoMenu: action.parametro
        }
    }
    else if (action.type === "REVELAR_EDITAR_MENU") {
        return {
            ...state,
            revelarEditarMenu: action.parametro
        }
    }
    else if (action.type === "REVELAR_EXCLUIR_MENU") {
        return {
            ...state,
            revelarExcluirMenu: action.parametro
        }
    }
    else if (action.type === "REVELAR_NOVO_RELEASE") {
        return {
            ...state,
            revelarNovoRelease: action.parametro
        }
    }
    else if (action.type === "REVELAR_TRANSFERIR_LINHA_CLIENTE_FINAL") {
        return {
            ...state,
            revelarTransferirLinhaClienteFinal: action.parametro
        }
    }
    else if (action.type === "REVELAR_NOVO_PEDIDO_IMPORTADO") {
        return {
            ...state,
            revelarNovoPedidoImportado: action.parametro
        }
    }
    else if (action.type === "REVELAR_DELETAR_PEDIDO_IMPORTADO") {
        return {
            ...state,
            revelarDeletarPedidoImportado: action.parametro
        }
    }
    else if (action.type === "REVELAR_BUSCAR_E_FILTRO") {
        return {
            ...state,
            RevelarBuscarEFiltro: action.parametro
        }
    }
    else if (action.type === "REVELAR_IMPORTAR_CLIENTES_USUARIOS") {
        return {
            ...state,
            revelarImportarClientesUsuarios: action.parametro
        }
    }
    else {
        return state
    }
}
