import React, { useState, useEffect } from "react"; /*eslint-disable*/
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import $ from "jquery";

import "../../css/pavFiltros/filtros.css";
import "./filtroMigracaoPlano.css";

import Calendario from "../calendario/calendario";
import { elipseVerde, setaDropdown } from "../../utils/alternanciaIcones";
import { api_migracao } from "../../conexoes/api";
import {
  filtrosArrayBarra,
  revelarBarraFiltragem,
  recarregarBarraFiltragem,
  renderizaOperadora,
  renderizaFiltroStatusMigracaoPlano,
  maximoTagsExibidas,
  mudarFiltros,
} from "../../redux/actions";
import SpinerVeye from "../../components/spinnerVeye/spinnerVeye";
import { limparFiltr } from "../../utils/alternanciaIcones";
import SelectMigracaoPlano from "../componentesModais/selects/selectMigracaoPlano";
import GrupoMinMax from "./GrupoMinMax";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const keyOperadoraStorage = "keyOperadoraMigracao";
const strOperadora = "OPERADORA";
const keyStatusMigracao = "StatusMigracao";

let filtroOperadoratt = [];
let filtroStatustt = [];

const defaultStatus = [
  { cdStatus: "Sucesso", dsStatus: "Sucesso", checked: false },
  { cdStatus: "Falha", dsStatus: "Falha", checked: false },
  { cdStatus: "Sem migração", dsStatus: "Sem migração", checked: false },
];

function Filtros(props) {
  const despacho = useDispatch();

  const tema = localStorage.getItem("tema");
  const isEscuro = tema === "escuro";

  const corDoRadio = {
    paddingLeft: 0,
    color: isEscuro ? "#F0F4F8" : "#4F6275",
    "&.Mui-checked": {
      color: "#33CC99",
    },
  };

  let recarregarBarraFiltragemValor = useSelector(
    (state) => state.filtrosState.recarregarBarraFiltragem
  );
  let filtros = useSelector((state) => state.filtrosState.filtros);
  let arrayFiltrosSimcard = [];

  // SPINNER
  const [spinAtivo, setSpinAtivo] = useState(false);

  function hendlerGetdadosFiltros() {
    if (!filtros.vazio) {
      const auxFiltroOper = filtros[strOperadora];
      const auxFiltroStatus = filtros[keyStatusMigracao];

      //seta os valores dos inputs
      if (auxFiltroOper) {
        setFiltroOperadora(auxFiltroOper.split(","));
        filtroOperadoratt = auxFiltroOper.split(",");
        setArrDadosOperadoras((prev) =>
          prev.map((item) =>
            filtroOperadoratt.includes(String(item.cd_dsp_operadora))
              ? { ...item, checked: true }
              : item
          )
        );
      }

      if (auxFiltroStatus) {
        setFiltroStatusMigracao(auxFiltroStatus.split(","));
        filtroStatustt = auxFiltroStatus.split(",");
        setArrDadosStatusMigracao((prev) =>
          prev.map((item) =>
            filtroStatustt.includes(item.dsStatus)
              ? { ...item, checked: true }
              : item
          )
        );
      }

      if (filtros.DtRequisicaoInicio) {
        let auxData = new Date(filtros.DtRequisicaoInicio);
        setfiltroDtRequisicaoDataInicio(auxData);
      }
      if (filtros.DtRequisicaoFim) {
        let auxData = new Date(filtros.DtRequisicaoFim);
        setfiltroDtRequisicaoDataFinal(auxData);
      }

      if (filtros.CicloEfetivacaoInicio) {
        let auxData = getDateObjByMonthYearStr(filtros.CicloEfetivacaoInicio);
        setfiltroCicloEfetivacaoDataInicio(auxData);
      }
      if (filtros.CicloEfetivacaoFim) {
        let auxData = getDateObjByMonthYearStr(filtros.CicloEfetivacaoFim);
        setfiltroCicloEfetivacaoDataFinal(auxData);
      }

      if (filtros.clienteCodigo) {
        setFiltroClienteFinal(filtros.clienteCodigo);
      }

      if (filtros.clienteNome) {
        setFiltroNomeClienteFinal(filtros.clienteNome);
        setDsClienteNome(filtros.clienteNome);
      } else {
        setDsClienteNome("");
      }

      if (filtros.PLanoClienteInicio) {
        setFiltroPlanoClienteInicio(filtros.PLanoClienteInicio);
        setFiltroPlanoClienteFim(filtros.PLanoClienteFim);
      }

      if (filtros.PlanoOperadoraInicio || filtros.PlanoOperadoraInicio == "") {
        setFiltroPlanoOperadoraInicio(filtros.PlanoOperadoraInicio);
        setFiltroPlanoOperadoraFim(filtros.PlanoOperadoraFim);
      }

      if (
        filtros.ConsumoCicloAnteriorInicio ||
        filtros.ConsumoCicloAnteriorInicio == ""
      ) {
        setFiltroConsumoCicloAnteriorInicio(filtros.ConsumoCicloAnteriorInicio);
        setFiltroConsumoCicloAnteriorFim(filtros.ConsumoCicloAnteriorFim);
      }
      if (
        filtros.ConsumoConsideradoInicio ||
        filtros.ConsumoConsideradoInicio == ""
      ) {
        setFiltroConsumoConsideradoInicio(filtros.ConsumoConsideradoInicio);
        setFiltroConsumoConsideradoFim(filtros.ConsumoConsideradoFim);
      }
    }
  }

  function getDateObjByMonthYearStr(dateString) {
    const [month, year] = dateString.split("/"); // Split the string
    return new Date(year, month - 1); // Subtract
  }

  useEffect(() => {
    hendlerGetdadosFiltros();
  }, []);

  useEffect(() => {
    if (filtros.vazio) {
      setFiltroOperadora([]);
      setFiltroStatusMigracao([]);
    }
  }, []);

  //VERIFICANDO 1

  function handlerCheckBox(nome, codigo) {
    let validacao;

    if (filtros.vazio != "vazio") {
      if (filtros[nome]) {
        filtros[nome].split(",").map((item) => {
          if (item == codigo) {
            validacao = true;
          }
        });
      }
    }

    if (validacao === true) {
      return true;
    } else {
      return false;
    }
  }

  async function handleFuncaoEstadosRedux() {
    if (
      filtroOperadora.length > 0 &&
      filtroOperadora &&
      filtroOperadora != undefined &&
      filtroOperadora != ""
    ) {
      let operadorasTemp = "";
      // let cdOperadora = []
      await Promise.all(
        dadoSelectOperadora.map((item) => {
          filtroOperadora.map((item_filtro) => {
            if (item.cd_dsp_operadora == item_filtro) {
              operadorasTemp +=
                ", " +
                item.ds_dsp_operadora?.trim() +
                " " +
                item.cd_dsp_operadora;
            }
          });
        })
      );
      operadorasTemp = operadorasTemp.replace(",", "");
      let quebrado = operadorasTemp.split(",");
      for (let i = 0; i < quebrado.length; i++)
        arrayFiltrosSimcard.push(quebrado[i]);
      despacho(renderizaOperadora(dadoSelectOperadora));
    }

    if (filtroStatusMigracao?.length > 0) {
      let statusTemp = "";
      await Promise.all(
        arrDadosStatusMigracao.map((item) => {
          filtroStatusMigracao.map((item_filtro) => {
            if (item.cdStatus == item_filtro) {
              // statusTemp += ", " + item.dsStatus?.trim() + " "+ item.cdStatus
              statusTemp += ", Status migração: " + item.cdStatus;
            }
          });
        })
      );
      statusTemp = statusTemp.replace(",", "");
      let quebrado = statusTemp.split(",");

      for (let i = 0; i < quebrado.length; i++)
        arrayFiltrosSimcard.push(quebrado[i]);
      despacho(renderizaFiltroStatusMigracaoPlano(arrDadosStatusMigracao));
    }

    if (
      filtroDtRequisicaoDataInicio !== "" &&
      filtroDtRequisicaoDataFinal !== "" &&
      filtroDtRequisicaoDataInicio &&
      filtroDtRequisicaoDataFinal
    ) {
      arrayFiltrosSimcard.push(
        "Data da requisição: Período de " +
          moment(filtroDtRequisicaoDataInicio).format("DD-MM-YYYY") +
          " até " +
          moment(filtroDtRequisicaoDataFinal).format("DD-MM-YYYY") +
          "?DtRequisicaoInicio"
      );
    }

    if (filtroCicloEfetivacaoDataInicio && filtroCicloEfetivacaoDataFinal) {
      arrayFiltrosSimcard.push(
        "Ciclo de efetivação: Período de " +
          moment(filtroCicloEfetivacaoDataInicio).format("MM/YYYY") +
          " até " +
          moment(filtroCicloEfetivacaoDataFinal).format("MM/YYYY") +
          "?CicloEfetivacaoInicio"
      );
    }

    if (filtroClienteFinal) {
      arrayFiltrosSimcard.push(
        "Código do cliente: " + filtroClienteFinal + "?clienteCodigo"
      );
    }
    if (dsClienteNome && dsClienteNome !== "" && dsClienteNome) {
      arrayFiltrosSimcard.push("Cliente: " + dsClienteNome + "?clienteNome");
    }

    if (filtroPlanoClienteInicio && filtroPlanoClienteFim) {
      arrayFiltrosSimcard.push(
        "Plano cliente: " +
          "de " +
          filtroPlanoClienteInicio +
          "MB até " +
          filtroPlanoClienteFim +
          "MB?PLanoCliente"
      );
    }
    if (filtroPlanoOperadoraInicio && filtroPlanoOperadoraFim) {
      arrayFiltrosSimcard.push(
        "Plano operadora: " +
          "de " +
          filtroPlanoOperadoraInicio +
          "MB até " +
          filtroPlanoOperadoraFim +
          "MB?PlanoOperadora"
      );
    }

    if (filtroConsumoCicloAnteriorInicio && filtroConsumoCicloAnteriorFim) {
      arrayFiltrosSimcard.push(
        "Consumo ciclo anterior: " +
          "de " +
          filtroConsumoCicloAnteriorInicio +
          "MB até " +
          filtroConsumoCicloAnteriorFim +
          "MB?ConsumoCicloAnterior"
      );
    }

    if (filtroConsumoConsideradoInicio && filtroConsumoConsideradoFim) {
      arrayFiltrosSimcard.push(
        "Consumo considerado: " +
          "de " +
          filtroConsumoConsideradoInicio +
          "MB até " +
          filtroConsumoConsideradoFim +
          "MB?ConsumoConsiderado"
      );
    }

    // arrayFiltrosTagsRestante
    despacho(maximoTagsExibidas(props.setRevelarDropFiltros));
    despacho(filtrosArrayBarra(arrayFiltrosSimcard));
    despacho(revelarBarraFiltragem(true));
    despacho(recarregarBarraFiltragem(!recarregarBarraFiltragemValor));
  }

  const [dadoSelectOperadora, setDadoSelectOperadora] = useState([]);

  //HOOKS DOS VALORES DOS FILTROS
  const [filtroOperadora, setFiltroOperadora] = useState([]);
  const [arrDadosOperadoras, setArrDadosOperadoras] = useState([]);
  const [filtroStatusMigracao, setFiltroStatusMigracao] = useState([]);
  const [arrDadosStatusMigracao, setArrDadosStatusMigracao] = useState([
    ...defaultStatus,
  ]);
  const [filtroDtRequisicaoDataInicio, setfiltroDtRequisicaoDataInicio] =
    useState("");
  const [filtroDtRequisicaoDataFinal, setfiltroDtRequisicaoDataFinal] =
    useState("");

  const [filtroCicloEfetivacaoDataInicio, setfiltroCicloEfetivacaoDataInicio] =
    useState("");
  const [filtroCicloEfetivacaoDataFinal, setfiltroCicloEfetivacaoDataFinal] =
    useState("");

  const [renderOperadora, setRenderOperadora] = useState(false);
  const [renderOperadoraParte2Verificar, setRenderOperadoraParte2Verificar] =
    useState(false);
  const [renderOperadoraParte2, setRenderOperadoraParte2] = useState(false);

  //HOOKS FILTROS - Cliente FINAL
  const [permitirFiltroCodigoCliente, setPermitirFiltroCodigoCliente] =
    useState(true);
  const [filtroClienteFinal, setFiltroClienteFinal] = useState("");
  const [filtroNomeClienteFinal, setFiltroNomeClienteFinal] = useState("");

  const filtroClienteNomeChecked = !!(
    filtroNomeClienteFinal || !permitirFiltroCodigoCliente
  );

  const [dsClienteNome, setDsClienteNome] = useState("");
  const [cdClienteNome, setCdClienteNome] = useState("");
  const [revelarSelectClienteNome, setRevelarSelectClienteNome] =
    useState(false);
  // fim HOOKS FILTROS - Cliente FINAL

  const [filtroPlanoClienteInicio, setFiltroPlanoClienteInicio] = useState("");
  const [filtroPlanoClienteFim, setFiltroPlanoClienteFim] = useState("");

  const [filtroPlanoOperadoraInicio, setFiltroPlanoOperadoraInicio] =
    useState("");
  const [filtroPlanoOperadoraFim, setFiltroPlanoOperadoraFim] = useState("");

  const [
    filtroConsumoCicloAnteriorInicio,
    setFiltroConsumoCicloAnteriorInicio,
  ] = useState("");
  const [filtroConsumoCicloAnteriorFim, setFiltroConsumoCicloAnteriorFim] =
    useState("");

  const [filtroConsumoConsideradoInicio, setFiltroConsumoConsideradoInicio] =
    useState("");
  const [filtroConsumoConsideradoFim, setFiltroConsumoConsideradoFim] =
    useState("");

  useEffect(() => {
    //Check se todas as variaveis estao limpas
    function handlerCheckarTudoLimpo() {
      let filtrosPrenchidos = 0;
      let erroFiltro = false;

      function validarMinMax(min, max) {
        if (!min && !max) return;

        if (parseFloat(min) <= parseFloat(max)) {
          filtrosPrenchidos++;
        } else {
          erroFiltro = true;
        }
      }

      if (filtroOperadora.length > 0) {
        filtrosPrenchidos++;
      }

      if (filtroStatusMigracao.length > 0) {
        filtrosPrenchidos++;
      }

      if (
        filtroDtRequisicaoDataInicio != "" ||
        filtroDtRequisicaoDataFinal != ""
      ) {
        filtrosPrenchidos++;
      }

      if (
        filtroCicloEfetivacaoDataInicio != "" ||
        filtroCicloEfetivacaoDataFinal != ""
      ) {
        filtrosPrenchidos++;
      }

      if (
        filtroClienteFinal ||
        cdClienteNome != "" ||
        filtroNomeClienteFinal ||
        dsClienteNome
      ) {
        filtrosPrenchidos++;
      }

      validarMinMax(filtroPlanoClienteInicio, filtroPlanoClienteFim);

      validarMinMax(filtroPlanoOperadoraInicio, filtroPlanoOperadoraFim);

      validarMinMax(
        filtroConsumoCicloAnteriorInicio,
        filtroConsumoCicloAnteriorFim
      );

      validarMinMax(
        filtroConsumoConsideradoInicio,
        filtroConsumoConsideradoFim
      );

      return filtrosPrenchidos > 0 && !erroFiltro;
    }

    async function handlerAtivarBotoes() {
      //se for alterado algo vai habilitar os botoes
      const primeiraVezENaoFoiLimpado = props.primeiraVez && !props.foiLimpado;
      const isFiltroTudoLimpo = await handlerCheckarTudoLimpo();

      if (primeiraVezENaoFoiLimpado) {
        if (isFiltroTudoLimpo) {
          props.setFoiSelecionado(true);
        } else {
          props.setFoiSelecionado(false);
        }
      }
    }

    //limpa os dados de todas as variaveis
    if (props.foiLimpado) {
      setFiltroOperadora([]);
      handleAlterarStatusMigracao("", true);

      limparFiltrosCliente();
      limparFiltrosMinMax();

      props.setFoiLimpado(false);
    }

    handlerAtivarBotoes();
    props.setPrimeiraVez(true);
  }, [
    filtroOperadora,
    filtroStatusMigracao,
    filtroDtRequisicaoDataInicio,
    filtroDtRequisicaoDataFinal,
    filtroCicloEfetivacaoDataInicio,
    filtroCicloEfetivacaoDataFinal,
    filtroClienteFinal,
    filtroNomeClienteFinal,
    permitirFiltroCodigoCliente,
    filtroPlanoClienteInicio,
    filtroPlanoClienteFim,
    filtroPlanoOperadoraInicio,
    filtroPlanoOperadoraFim,
    filtroConsumoCicloAnteriorInicio,
    filtroConsumoCicloAnteriorFim,
    filtroConsumoConsideradoInicio,
    filtroConsumoConsideradoFim,
    props.foiLimpado,
  ]);

  function limparFiltrosCliente() {
    limparFiltroClienteCodigo();
    limparFiltroClienteNome();
  }

  function limparFiltroClienteCodigo() {
    setFiltroClienteFinal("");
    setPermitirFiltroCodigoCliente(false);
    setCdClienteNome("");
  }

  function limparFiltroClienteNome() {
    setFiltroNomeClienteFinal("");
    setDsClienteNome("");
    setRevelarSelectClienteNome(false);
    setPermitirFiltroCodigoCliente(true);
  }

  function limparFiltrosMinMax() {
    setFiltroPlanoClienteInicio("");
    setFiltroPlanoClienteFim("");
    setFiltroPlanoOperadoraInicio("");
    setFiltroPlanoOperadoraFim("");
    setFiltroConsumoCicloAnteriorInicio("");
    setFiltroConsumoCicloAnteriorFim("");
    setFiltroConsumoConsideradoInicio("");
    setFiltroConsumoConsideradoFim("");
  }

  function validarNumero(id, valor) {
    const filtro = /^[0-9]+$/;
    if (valor === "" || filtro.test(valor)) {
      if (id === "campo-texto-cliente-final") {
        setFiltroClienteFinal(valor);
        ativarRadioButton("codigoCliente");
      }
    }
  }

  function handleClienteFinalNome(value) {
    setFiltroNomeClienteFinal(value);
    ativarRadioButton("nomeCliente");
  }

  function ativarRadioButton(nome) {
    if (nome === "codigoCliente") {
      limparFiltroClienteNome();
    } else if (nome === "nomeCliente") {
      limparFiltroClienteCodigo();
    }
  }

  async function handleAlterarOperadora(codigo, limpar) {
    let validadorRetirado = false;
    let filtroOperadoraTemp = JSON.parse(JSON.stringify(filtroOperadoratt));

    if (limpar) {
      auxLimparCheckbox();
      filtroOperadoraTemp = [];
      filtroOperadoratt = [];
      setFiltroOperadora([]);
      return;
    }

    await Promise.all(
      filtroOperadoratt.map((item, i) => {
        if (item == codigo) {
          filtroOperadoraTemp.splice(i, 1);
          validadorRetirado = true;
        }
      })
    );
    filtroOperadoraTemp.push(codigo.toString());
    if (validadorRetirado) {
      filtroOperadoraTemp = filtroOperadoraTemp.filter(
        (operadoraCodigo) => operadoraCodigo != codigo
      );
    }

    filtroOperadoratt = JSON.parse(JSON.stringify(filtroOperadoraTemp));
    setFiltroOperadora(filtroOperadoratt);
  }

  async function handleAlterarStatusMigracao(codigo, limpar) {
    let validadorRetirado = false;
    let filtroStatusTemp = JSON.parse(JSON.stringify(filtroStatustt));

    if (limpar) {
      auxLimparCheckbox();
      filtroStatusTemp = [];
      filtroStatustt = [];
      setFiltroStatusMigracao([]);
      return;
    }

    filtroStatustt.map((item, i) => {
      if (item == codigo) {
        filtroStatusTemp.splice(i, 1);
        validadorRetirado = true;
      }
    });

    filtroStatusTemp.push(codigo.toString());
    if (validadorRetirado) {
      filtroStatusTemp = filtroStatusTemp.filter(
        (statusCodigo) => statusCodigo != codigo
      );
    }

    filtroStatustt = JSON.parse(JSON.stringify(filtroStatusTemp));
    setFiltroStatusMigracao(filtroStatustt);
  }

  async function renderCheckboxesOperadoras(
    arrDadosOperadoras,
    noOperadorasInLocalStorage
  ) {
    const qtdOperadoras = arrDadosOperadoras.length;

    if (qtdOperadoras > 3) {
      // await checkboxesPoucasOperadoras(arrDadosOperadoras);

      let hasOnInput = false;
      if (noOperadorasInLocalStorage) hasOnInput = true;

      await checkboxesMuitasOperadoras(arrDadosOperadoras, hasOnInput);
    }
  }

  async function checkboxesMuitasOperadoras(arrDadosOperadoras, hasOnInput) {
    const qtdOperadoras = arrDadosOperadoras.length;

    let objetoServico = await Promise.all(
      arrDadosOperadoras.map(async (operadora) => {
        const onChange = () =>
          handleAlterarOperadora(operadora.cd_dsp_operadora);
        const restProps = {};

        if (hasOnInput) {
          restProps.onInput = onChange;
        } else {
          restProps.onChange = onChange;
        }

        return (
          <CheckboxOperadora
            defaultChecked={handlerCheckBox(
              strOperadora,
              operadora.cd_dsp_operadora
            )}
            operadora={operadora}
            {...restProps}
          />
        );
      })
    );

    setRenderOperadora(objetoServico);

    let objetoServico2 = await Promise.all(
      arrDadosOperadoras.slice(3).map(async (operadora) => {
        return (
          <CheckboxOperadora
            onChange={() => handleAlterarOperadora(operadora.cd_dsp_operadora)}
            operadora={operadora}
            defaultChecked={handlerCheckBox(
              strOperadora,
              operadora.cd_dsp_operadora
            )}
          />
        );
      })
    );

    setRenderOperadoraParte2(objetoServico2);
    setRenderOperadoraParte2Verificar(true);
  }

  function getDadosOperadora() {
    const dadosOperadora = JSON.parse(
      localStorage.getItem(keyOperadoraStorage)
    );

    return dadosOperadora;
  }

  useEffect(() => {
    async function renderOperadora() {
      setSpinAtivo(true);

      if (!localStorage.hasOwnProperty(keyOperadoraStorage)) {
        const operadorasPadroes = await api_migracao.get(
          "/api/v2/migration/list/carrier"
        );

        let auxDadosOperadora = operadorasPadroes.data.map((item) => ({
          cd_dsp_operadora: item.CdOperadora,
          ds_dsp_operadora: item.Operadora,
        }));

        auxDadosOperadora = auxDadosOperadora.filter(
          (item) => item.cd_dsp_operadora
        );

        setDadoSelectOperadora(auxDadosOperadora);
        localStorage.setItem(
          keyOperadoraStorage,
          JSON.stringify(auxDadosOperadora)
        );

        const arrayDadosOperadoras = getDadosOperadora();
        setArrDadosOperadoras([...arrayDadosOperadoras]);
        await renderCheckboxesOperadoras(arrayDadosOperadoras, true);
      } else {
        const arrayDadosOperadoras = getDadosOperadora();
        setDadoSelectOperadora(arrayDadosOperadoras);
        const auxArrayDadosOperadoras = arrayDadosOperadoras.map((item) => ({
          ...item,
          checked: false,
        }));

        setArrDadosOperadoras(auxArrayDadosOperadoras);

        await renderCheckboxesOperadoras(auxArrayDadosOperadoras);
      }

      hendlerGetdadosFiltros();

      setSpinAtivo(false);
    }
    renderOperadora();
  }, []);

  useEffect(() => {
    if (filtros.vazio || !filtros[strOperadora]) {
      filtroOperadoratt = [];
    }

    if (filtros.vazio || !filtros[keyStatusMigracao]) {
      filtroStatustt = [];
    }
  }, []);

  async function handleCancelar() {
    props.setRevelarDropFiltros(false);
  }

  async function handleLimparFiltros() {
    props.limparFiltros();

    filtroStatustt = [];
    filtroOperadoratt = [];
    setArrDadosStatusMigracao(defaultStatus);
    setArrDadosOperadoras((prev) =>
      prev.map((item) => ({ ...item, checked: false }))
    );
    handleAlterarOperadora("", true);
    handleAlterarStatusMigracao("", true);
    setDadoSelectOperadora([]);
    setfiltroDtRequisicaoDataInicio("");
    setfiltroDtRequisicaoDataFinal("");
    setFiltroStatusMigracao([]);

    setfiltroCicloEfetivacaoDataInicio("");
    setfiltroCicloEfetivacaoDataFinal("");

    limparFiltrosCliente();
    limparFiltrosMinMax();
  }

  const filterModalFormRow = {
    maxWidth: "917px",
  };

  return (
    <Form
      ref={props.modal}
      onSubmit={props.enviarFiltros}
      className="drop-acoes-container-filtro"
    >
      {spinAtivo && (
        <div className="componente-spinner-overlay-abrir-filtro-simcard">
          <div className="componente-spinner-container">
            <SpinerVeye />
          </div>
        </div>
      )}
      <div className="campo-select-triangulo-menor-cor-2-simcard"></div>
      <div className="drop-acoes-corpo-filtro fundo-cor-13">
        <div className="modal-filtro-simcard fundo-cor-13">
          <Form.Row style={filterModalFormRow}>
            <Col>
              <img
                className="icone-label-janela nao-selecionavel"
                src={elipseVerde()}
                alt="elipse"
              />
              <Form.Label>
                <div className="fonte-cor-1 label-janela">Filtros</div>
              </Form.Label>
              <Button
                className="botao-fechar-filtro"
                variant="danger"
                onClick={() => handleCancelar()}
              >
                VOLTAR
              </Button>
              <Button
                type="button"
                onClick={handleLimparFiltros}
                className="botao-limpar-filtro-simcard fonte-cor-12"
                variant="default"
              >
                LIMPAR FILTROS
                <img
                  src={limparFiltr()}
                  alt="fechar-janela"
                  className="icone-menu-filtro"
                />
              </Button>

              {/* chama a função enviarFiltros */}
              <Button
                disabled={props.foiSelecionado ? false : true}
                type="submit"
                onClick={() => handleFuncaoEstadosRedux()}
                className={
                  props.foiSelecionado
                    ? "botao-aplicar-filtro-simcard-habilitado fonte-cor-2 fonte-cor-4"
                    : "botao-aplicar-filtro-simcard-disable"
                }
                variant="default"
              >
                FILTRAR
              </Button>
            </Col>
          </Form.Row>
          <Form.Row class={"filtro-conteudo-container-migracao-plano"}>
            <Col>
              <div className="grupo-alterar-ciclo-vida-1-filtro fundo-filtro fundo-cor-14">
                {arrDadosOperadoras.length <= 3 && (
                  <>
                    <div className="coluna-filtro-operadora-migracao">
                      <Form.Label className="fonte-cor-1 label-campo-filtro campo-operadoras">
                        Operadoras
                      </Form.Label>
                      <div className="break"></div>
                      <>
                        {arrDadosOperadoras.map((operadora) => {
                          return (
                            <CheckboxOperadora
                              key={`${operadora.ds_dsp_operadora}-${operadora.cd_dsp_operadora}`}
                              defaultChecked={operadora?.checked || false}
                              onChange={(checked) => {
                                setArrDadosOperadoras((prev) =>
                                  prev.map((item) =>
                                    item.cd_dsp_operadora ===
                                    operadora.cd_dsp_operadora
                                      ? {
                                          ...item,
                                          checked: checked?.target?.checked,
                                        }
                                      : item
                                  )
                                );

                                handleAlterarOperadora(
                                  operadora.cd_dsp_operadora
                                );
                              }}
                              operadora={operadora}
                            />
                          );
                        })}
                      </>
                    </div>
                  </>
                )}

                {arrDadosOperadoras.length > 3 && (
                  <ListCheckboxOperadora
                    {...{
                      renderOperadora,
                      renderOperadoraParte2Verificar,
                      renderOperadoraParte2,
                    }}
                  />
                )}
                {/* ------------------- */}

                <>
                  <div className="coluna-alterar-ciclo-vida-1-status_migracao_plano">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-status_migracao_plano">
                      Status migração
                    </Form.Label>
                    <div className="break" />

                    <div className="container-checkbox-status-migracao">
                      {arrDadosStatusMigracao.map((status, index) => {
                        return (
                          <CheckboxStatusMigracao
                            key={`${status.cdStatus}-${status.checked}-${index}`}
                            status={status}
                            defaultChecked={status?.checked || false}
                            onChange={(checked) => {
                              setArrDadosStatusMigracao((prev) =>
                                prev.map((item) =>
                                  item.cdStatus === status.cdStatus
                                    ? {
                                        ...item,
                                        checked: checked?.target?.checked,
                                      }
                                    : item
                                )
                              );

                              handleAlterarStatusMigracao(status.cdStatus);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>

                  {/* ------------------- */}
                  <div className="div-filtro-campo-data">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-ultimo-evento">
                      Data da requisição
                    </Form.Label>
                    <div className="break-3"></div>
                    <Form.Group>
                      <CampoData
                        name="DtRequisicaoInicio"
                        value={filtroDtRequisicaoDataInicio}
                        setValue={setfiltroDtRequisicaoDataInicio}
                        dataMaior={filtroDtRequisicaoDataFinal}
                      />
                      <CampoData
                        name="DtRequisicaoFim"
                        value={filtroDtRequisicaoDataFinal}
                        setValue={setfiltroDtRequisicaoDataFinal}
                        dataMenor={filtroDtRequisicaoDataInicio}
                      />
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                  <div className="div-filtro-campo-data">
                    <Form.Label className="fonte-cor-1 label-campo-filtro campo-ultimo-evento">
                      Ciclo de efetivação
                    </Form.Label>
                    <div className="break-3"></div>
                    <Form.Group>
                      <CampoMesAno
                        name="CicloEfetivacaoInicio"
                        value={filtroCicloEfetivacaoDataInicio}
                        setValue={setfiltroCicloEfetivacaoDataInicio}
                        dataMaior={filtroCicloEfetivacaoDataFinal}
                      />
                      <CampoMesAno
                        name="CicloEfetivacaoFim"
                        value={filtroCicloEfetivacaoDataFinal}
                        setValue={setfiltroCicloEfetivacaoDataFinal}
                        dataMenor={filtroCicloEfetivacaoDataInicio}
                      />
                    </Form.Group>
                  </div>

                  <div className="coluna-alterar-ciclo-vida-1-cliente-final migracao-cliente-container-cliente-final">
                    <Form.Label className="fonte-cor-1 label-campo-filtro">
                      Cliente Final
                    </Form.Label>
                    <div className="break-4"></div>
                    <Form.Group>
                      <div className="row filtros-texto-linha-margin">
                        {/* Cliente Final - Código  */}
                        <div className="div-filtro-cliente-final-codigo">
                          <Radio
                            disableRipple
                            sx={corDoRadio}
                            defaultChecked={!filtros.clienteNome}
                            checked={!filtroClienteNomeChecked}
                            onChange={() => ativarRadioButton("codigoCliente")}
                            value=""
                            name="CLIENTE_FINAL"
                          />
                          <Form.Control
                            style={{ width: "100%" }}
                            name="clienteCodigo"
                            className="filtros-cliente-final campo-texto-cor-3"
                            type="text"
                            maxLength="20"
                            value={filtroClienteFinal}
                            onChange={(e) =>
                              validarNumero(
                                "campo-texto-cliente-final",
                                e.target.value
                              )
                            }
                            placeholder="Código do cliente"
                            autoComplete="off"
                          />
                        </div>

                        {/* Cliente Final - Nome  */}
                        <div className="div-filtro-cliente-final-nome">
                          <Radio
                            disableElevation
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            sx={corDoRadio}
                            defaultChecked={filtros.clienteNome}
                            checked={filtroClienteNomeChecked}
                            onChange={() => ativarRadioButton("nomeCliente")}
                            value=""
                            name="CLIENTE_FINAL"
                          />
                          {/* <label className="label-radio-filtros-2 fonte-cor-16">
                            <input
                              defaultChecked={filtros.clienteNome}
                              checked={filtroClienteNomeChecked}
                              type="radio"
                              name="CLIENTE_FINAL"
                              value=""
                              onClick={() => ativarRadioButton("nomeCliente")}
                            />
                            <span className="checkbox-filtros-b campo-texto-cor-3"></span>
                          </label> */}
                          {/* ------------------- */}

                          <div className="select-autocomplete-nome-cliente">
                            <Form.Group>
                              <Form.Control
                                name="clienteNome"
                                type="text"
                                value={dsClienteNome}
                                className="d-none"
                              />
                              {!revelarSelectClienteNome && (
                                <Button
                                  className="campo-texto-cor-3 campo-select"
                                  onClick={() =>
                                    setRevelarSelectClienteNome(
                                      !revelarSelectClienteNome
                                    )
                                  }
                                >
                                  <div className="fonte-cor-1 label-campo">
                                    {dsClienteNome || "Nome do cliente"}
                                  </div>
                                  <img
                                    className={
                                      revelarSelectClienteNome
                                        ? "campo-select-icone-ativado nao-selecionavel"
                                        : "campo-select-icone-desativado nao-selecionavel"
                                    }
                                    src={setaDropdown()}
                                    alt="drop"
                                  />
                                </Button>
                              )}

                              {revelarSelectClienteNome && (
                                <SelectMigracaoPlano
                                  paramRota={"clients"}
                                  setCodigo={handleClienteFinalNome}
                                  setDescricao1={setDsClienteNome}
                                  setRevelarSelect={setRevelarSelectClienteNome}
                                  placeholder={
                                    dsClienteNome || "Nome do cliente"
                                  }
                                  sufixo={""}
                                  tipo="autocomplete"
                                />
                              )}
                            </Form.Group>
                          </div>
                        </div>
                        {/* ------------------- */}
                      </div>
                    </Form.Group>
                  </div>
                  {/* ------------------- */}
                </>
              </div>

              <div className="grupo-filtros-min-max fundo-filtro-4 fundo-cor-14">
                <GrupoMinMax
                  titulo="Plano cliente"
                  unidade="MB"
                  nomeCampoMinimo="PLanoClienteInicio"
                  nomeCampoMaximo="PLanoClienteFim"
                  filtroMinimo={filtroPlanoClienteInicio}
                  filtroMaximo={filtroPlanoClienteFim}
                  setFiltroMinimo={setFiltroPlanoClienteInicio}
                  setFiltroMaximo={setFiltroPlanoClienteFim}
                  setFoiSelecionado={props.setFoiSelecionado}
                  foiLimpado={props.foiLimpado}
                />

                <GrupoMinMax
                  titulo="Plano operadora"
                  unidade="MB"
                  nomeCampoMinimo="PlanoOperadoraInicio"
                  nomeCampoMaximo="PlanoOperadoraFim"
                  filtroMinimo={filtroPlanoOperadoraInicio}
                  filtroMaximo={filtroPlanoOperadoraFim}
                  setFiltroMinimo={setFiltroPlanoOperadoraInicio}
                  setFiltroMaximo={setFiltroPlanoOperadoraFim}
                  setFoiSelecionado={props.setFoiSelecionado}
                  foiLimpado={props.foiLimpado}
                />

                <GrupoMinMax
                  titulo="Consumo ciclo anterior"
                  unidade="MB"
                  nomeCampoMinimo="ConsumoCicloAnteriorInicio"
                  nomeCampoMaximo="ConsumoCicloAnteriorFim"
                  filtroMinimo={filtroConsumoCicloAnteriorInicio}
                  filtroMaximo={filtroConsumoCicloAnteriorFim}
                  setFiltroMinimo={setFiltroConsumoCicloAnteriorInicio}
                  setFiltroMaximo={setFiltroConsumoCicloAnteriorFim}
                  setFoiSelecionado={props.setFoiSelecionado}
                  foiLimpado={props.foiLimpado}
                />

                <GrupoMinMax
                  titulo="Consumo considerado"
                  unidade="MB"
                  nomeCampoMinimo="ConsumoConsideradoInicio"
                  nomeCampoMaximo="ConsumoConsideradoFim"
                  filtroMinimo={filtroConsumoConsideradoInicio}
                  filtroMaximo={filtroConsumoConsideradoFim}
                  setFiltroMinimo={setFiltroConsumoConsideradoInicio}
                  setFiltroMaximo={setFiltroConsumoConsideradoFim}
                  setFoiSelecionado={props.setFoiSelecionado}
                  foiLimpado={props.foiLimpado}
                />
              </div>
            </Col>
          </Form.Row>
        </div>
      </div>
    </Form>
  );
}

function ListCheckboxOperadora({
  renderOperadora,
  renderOperadoraParte2Verificar,
  renderOperadoraParte2,
}) {
  return (
    <>
      <div className="coluna-filtro-operadora-migracao">
        <Form.Label className="fonte-cor-1 label-campo-filtro campo-operadoras">
          Operadoras
        </Form.Label>
        <div className="break"></div>
        <>{renderOperadora}</>
      </div>
      <div className="operadoras-filtro">
        <>{renderOperadoraParte2Verificar && renderOperadoraParte2}</>
      </div>
    </>
  );
}

function CheckboxOperadora({ operadora, defaultChecked, onChange }) {
  const { ds_dsp_operadora, cd_dsp_operadora } = operadora;

  const tema = localStorage.getItem("tema");
  const isEscuro = tema === "escuro";

  const corDoCheckbox = {
    paddingLeft: 0,
    color: isEscuro ? "#F0F4F8" : "#4F6275",
    "&.Mui-checked": {
      color: "#33CC99",
    },
  };

  const corDoLabel = {
    color: isEscuro ? "#F8FAFB" : "#243B53",
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          key={`${cd_dsp_operadora}-${defaultChecked}`}
          disableRipple
          defaultChecked={defaultChecked}
          sx={corDoCheckbox}
          name={strOperadora}
          onChange={onChange}
          value={cd_dsp_operadora}
        />
      }
      label={ds_dsp_operadora}
      sx={corDoLabel}
    />
  );
}

function CheckboxStatusMigracao({ status, defaultChecked, onChange }) {
  const { dsStatus, cdStatus } = status;

  const tema = localStorage.getItem("tema");
  const isEscuro = tema === "escuro";
  const corDoCheckbox = {
    paddingLeft: 0,
    color: isEscuro ? "#F0F4F8" : "#4F6275",
    "&.Mui-checked": {
      color: "#33CC99",
    },
  };

  const corDoLabel = {
    color: isEscuro ? "#F8FAFB" : "#243B53",
  };

  return (
    <FormControlLabel
      key={`${cdStatus}-${defaultChecked}`}
      control={
        <Checkbox
          disableRipple
          defaultChecked={defaultChecked}
          sx={corDoCheckbox}
          name={keyStatusMigracao}
          onChange={onChange}
          value={cdStatus}
        />
      }
      label={dsStatus}
      sx={corDoLabel}
    />
  );
}

function CampoData({ name, value, setValue, dataMenor, dataMaior }) {
  //HOOKS DE DATAS BASICAS
  const dataMin = 1990;
  const dataMax = new Date();
  const changeValue = (v) => {
    if (v?.getHours() === 0) {
      const gmt = v.toGMTString().split(":")[0].substr(-1, 1);

      if (dataMaior != null) {
        v.setUTCHours(gmt, 0, 0, 0);
      } else {
        v.setUTCHours(parseInt(gmt) + 23, 59, 59, 0);
      }
    }

    setValue(v);
  };

  const showValue = value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : value;

  if (dataMaior != null) {
    return (
      <div className="conteudo-calendario-filtro">
        <Form.Control
          name={name}
          type="text"
          value={showValue}
          className="d-none"
        />
        <Calendario
          imputCustomisado="De:"
          data={value}
          setData={changeValue}
          dataMax={dataMaior || dataMax}
          dataMin={dataMin}
          rota={"filtroSimcard-c"}
        ></Calendario>
      </div>
    );
  }

  return (
    <div className="conteudo-calendario-filtro">
      <Form.Control
        name={name}
        type="text"
        value={showValue}
        className="d-none"
      />
      <Calendario
        imputCustomisado="Até:"
        data={value}
        setData={changeValue}
        dataMax={dataMax}
        dataMin={dataMenor || dataMin}
        rota={"filtroSimcard-c"}
      ></Calendario>
    </div>
  );
}

function CampoMesAno({ name, value, setValue, dataMenor, dataMaior }) {
  if(dataMaior === null) dataMaior = ''
  //HOOKS DE DATAS BASICAS
  const dataMin = 1990;
  const dataMax = new Date();

  const changeValue = (v) => {
    setValue(v);
  };

  // Testar "Invalid Date" (após filtrar, abrir o filtro novamente)
  const showValue =
    value && value !== "Invalid Date" ? moment(value).format("MM/YYYY") : value;
  if (dataMaior != null) {
    return (
      <div className="conteudo-calendario-filtro">
        <Form.Control
          name={name}
          type="text"
          value={showValue}
          className="d-none"
        />
        <Calendario
          formato="MM/YYYY"
          imputCustomisado="De:"
          data={value}
          setData={changeValue}
          dataMax={dataMaior || dataMax}
          dataMin={dataMin}
          rota={"filtroSimcard-c"}
        ></Calendario>
      </div>
    );
  }

  return (
    <div className="conteudo-calendario-filtro">
      <Form.Control
        name={name}
        type="text"
        value={showValue}
        className="d-none"
      />
      <Calendario
        formato="MM/YYYY"
        imputCustomisado="Até:"
        data={value}
        setData={changeValue}
        dataMax={dataMax}
        dataMin={dataMenor || dataMin}
        rota={"filtroSimcard-c"}
      ></Calendario>
    </div>
  );
}

function auxLimparCheckbox() {
  let inputs = $("input[type=checkbox]"); // TODO: alterar essa linha para TIRAR biblioteca jquery ?
  inputs.attr("checked", false);
  inputs.prop("checked", false);
}

export default Filtros;
