import React, { Fragment } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  setaVoltar,
  dividerVertical,
  divisaDireita,
} from "../../utils/alternanciaIcones";
import { getItemBreadCrumbDados } from "./tituloPaginas";
import "../../css/breadCrumbs.css";

/* Bread Crumbs: Para futuras melhorias pode consultar: 
        https://developer.mozilla.org/pt-BR/docs/Web/CSS/Layout_cookbook/Breadcrumb_Navigation
*/

function BotaoVoltar() {
  const history = useHistory();

  return (
    <div onClick={() => history.goBack()} id="breadcrumbs-div-voltar">
      <img id="breadcrumbs-seta-voltar" src={setaVoltar()} alt="voltar" />
      <img
        id="breadcrumbs-divider-vertical"
        src={dividerVertical()}
        alt="divisor"
      />
    </div>
  );
}

const SetaDireita = () => (
  <img
    className="breadcrumbs-seta-direita"
    src={divisaDireita()}
    alt="seta direita"
  />
);

function BreadCrumbs() {
  const history = useHistory();

  const manejador = useSelector((state) => state.manejadorState.manejador);
  const breadCrumbsObj = useSelector(
    (state) => state.breadCrumbsState.breadCrumbsObj
  );
  let breadCrumbArr;

  if (!manejador || manejador === "telaInicial") return null;

  if (breadCrumbsObj?.length > 0) {
    const locatinPathname = history.location.pathname;

    breadCrumbArr = getItemBreadCrumbDados(
      breadCrumbsObj,
      manejador,
      locatinPathname
    );
  } else {
    return null;
  }

  function getTituloPagina() {
    if (breadCrumbArr?.length > 0) {
      const lastIdx = breadCrumbArr?.length - 1;
      const item = breadCrumbArr[lastIdx];
      const titulo = item.tituloPagina || item.label || "";

      return tratarTitulo(titulo);
    } else {
      return null;
    }
  }

  function tratarTitulo(titulo) {
    switch (titulo) {
      case "mensagens":
        return "Mensagens";

      case "Papéis":
        return "Papéis de permissionamento";

      default:
        return titulo;
    }
  }

  const tituloPagina = getTituloPagina();

  return (
    <nav aria-label="Breadcumb" className="container-bread-crumbs">
      <BotaoVoltar />

      <div id="div-bread-crumbs">
        <h4 id="titulo-pagina">{tituloPagina}</h4>
        <div>
          <Link to="/veye" className="fonte-cor-17">
            Home
          </Link>

          {breadCrumbArr.map(({ rota, label }) => {
            const text = label === "mensagens" ? tituloPagina : label;

            return (
              <Fragment key={text}>
                <SetaDireita />
                {rota ? (
                  <Link to={rota} className="fonte-cor-18">
                    {text}
                  </Link>
                ) : (
                  <span className="fonte-cor-18">{text}</span>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </nav>
  );
}

export default BreadCrumbs;
