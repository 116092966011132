//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import {Image, Button} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import {formatarData} from '../../../utils/formatadorDataTabela'
//IMPORTAÇÕES ICONES
import { elipseVerde, elipseVermelho, elipseAzul, setaDropdown } from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, desabilitarAcoes, revelarRealocacao, solicitacaReenviarTrocaOcorrenciaVirtueyes, limpadorFiltros, recarregarBarraFiltragem,filtrosArrayBarra,modoSelecaoSimcard, desabilitarAcoesCliente, limpouBusca, realizouBusca, solicitacaConcluirVirtueyes,solicitacaCancelarVirtueyes,solicitacaReenviarProtocoloVirtueyes, mudarFiltros, revelarBarraFiltragem, realizouFiltro} from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import DetalhamentoSolicitacaoCliente from '../detalhamentoSolicitacao/clientes/tabela'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import decryptJWT from '../../../utils/decryptJWT'
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
import Realocacao from '../../painelDispositivos/dspCancelamentoReposicao/realocacao'
import LogReset from './acoes/logReset'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import '../../../css/painelVirtueyes/solicitacaoCliente/tabela.css'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let dadosSelecionados = [];
let dadosSelecionadosGerais = [];
let itensVar = [];

function TabelaClientesAcoes(props){
    //HOOKS TABELA

    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);   // eslint-disable-next-line
    const [mensagemErro, setMensagemErro] = useState('')
    const [mensagemAtencao, setMensagemAtencao] = useState('')
    const [mensagemSucesso, setMensagemSucesso] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemAtencaoTrocaOcorrencia, setRevelarMensagemAtencaoTrocaOcorrencia] = useState(false)
    const [filtroSolicitacoes, setFiltroSolicitacoes] = useState({vazio:true})
    const [selecionadoCdUsuario, setSelecionadoCdUsuario] = useState()
    const [selecionadoCliente, setSelecionadoCliente] = useState()
    const [selecionadoCdCliente, setSelecionadoCdCliente] = useState()
    const [selecionadoProtocoloProtheus, setSelecionadoProtocoloProtheus] = useState()
    const [selecionadoEmailUsuario, setSelecionadoEmailUsuario] = useState()
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    const solicitacaConcluir = useSelector(state => state.virtueyesState.solicitacaConcluir);
    const solicitacaCancelar = useSelector(state => state.virtueyesState.solicitacaCancelar);
    const solicitacaReenviarProtocolo = useSelector(state => state.virtueyesState.solicitacaReenviarProtocolo);
    const solicitacaReenviarTrocaOcorrencia = useSelector(state => state.virtueyesState.solicitacaReenviarTrocaOcorrenciaVirtueyes);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let reduxrevelarRealocacao = useSelector(state => state.manejadorState.revelarRealocacao);
    let revelarLogReset =  useSelector(state => state.virtueyesState.revelarLogReset);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    const despacho = useDispatch();
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(415) // 'solicitacaoClienteTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        restaurarConfigsIniciaisPagina()
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        async function enviarAcao() {
            if(solicitacaConcluir){
                //filtra os dados selecionados para apenas os que sao permitidos pela solicitacao
                // let cd_crm_solicitacao = await dadosSelecionados.filter(solicitacao => 
                //     solicitacao.CD_STATUS_SOLICITACAO == 1 || solicitacao.CD_STATUS_SOLICITACAO == 4).map(function(solicitacao){
                //         return(solicitacao.CD_SOLICITACAO)
                //     })
                //filtrar para apenas o cd da solicitacao               
                let cd_crm_solicitacao = await dadosSelecionados.map(function(solicitacao){ return(solicitacao.CD_SOLICITACAO) })

                await api.post('/m1/alterar/solicitacao',{
                    dados: {cd_crm_solicitacao_status:2, /* 2 e o codigo para concluido */
                           cd_pav_usuario_updated: decryptJWT('codigo') 
                    },
                    condicao:{cd_crm_solicitacao},
                    painel_virtueyes:true
                })
                    .then(function(resposta){
                        if(resposta.data.status == 200){
                            setMensagemSucesso("A solicitação foi concluida.")
                            setRevelarMensagemSucesso(true)
                        }
                        if(resposta.data.status == 500){
                            setRevelarMensagemErro(true)
                            setMensagemErro("Ouve algum erro interno na sua requisicao")
                        }
                        return resposta.data; 
                    }) 
                setRenderizar(!renderizar)
                despacho(solicitacaConcluirVirtueyes(false));
            }
            if(solicitacaCancelar){ 
                //filtrar para apenas o cd da solicitacao               
                let cd_crm_solicitacao = await dadosSelecionados.map(function(solicitacao){ return(solicitacao.CD_SOLICITACAO) })

                await api.post('/m1/alterar/solicitacao',{
                    dados: {cd_crm_solicitacao_status:3,  /* 3 e o codigo para cancelar */
                        cd_pav_usuario_updated: decryptJWT('codigo') 
                    },
                    condicao:{cd_crm_solicitacao},
                    painel_virtueyes:true
                })
                    .then(function(resposta){
                        if(resposta.data.status == 200){
                            setMensagemSucesso("A solicitação foi cancelada.")
                            setRevelarMensagemSucesso(!revelarMensagemSucesso)
                        }
                        if(resposta.data.status == 500){
                            setRevelarMensagemErro(true)
                            setMensagemErro("Ouve algum erro interno na sua requisicao")
                        }
                        return resposta.data; 
                    }) 
                    setRenderizar(!renderizar)
                despacho(solicitacaCancelarVirtueyes(false));
            }
            if(solicitacaReenviarProtocolo){
                //filtrar para apenas o cd da solicitacao onde o protocole de protheus nao existir 
                let cd_crm_solicitacao = await dadosSelecionados.filter(solicitacao => 
                    solicitacao.PROTOCOLO_PROTHEUS == null).map(function(solicitacao){
                        return(solicitacao.CD_SOLICITACAO)
                    })

                if(cd_crm_solicitacao.length !=0){
                    await api.post('/m1/cadastrar/reenvio_solicitacao_protheus',{
                        cd_crm_solicitacao
                    })
                        .then(function(resposta){
                            if(resposta.data.status == 200){
                                setMensagemSucesso("O reenvio das solicitação feito com sucesso")
                                setRevelarMensagemSucesso(!revelarMensagemSucesso)
                            }
                            if(resposta.data.status == 500){
                                setMensagemErro("Ouve algum erro interno na sua requisicao")
                                setRevelarMensagemErro(true)
                            }
                            return resposta.data; 
                        })
                        .catch(function(error){
                            setMensagemErro("Ouve algum erro interno na sua requisicao")
                            setRevelarMensagemErro(true)
                        })
                }
                else{
                    setMensagemAtencao("Os Itens selecionados ja possuem Protocolo Protheus")
                    setRevelarMensagemAtencao(true)
                }
                despacho(solicitacaReenviarProtocoloVirtueyes(false))
            }
        }
        enviarAcao();
    }, [solicitacaConcluir,solicitacaCancelar,solicitacaReenviarProtocolo]);

    useEffect(()=>{
        async function handlerTrocaOcorrencia(){
            let cd_crm_solicitacoes = [];
            despacho(solicitacaReenviarTrocaOcorrenciaVirtueyes(false))
            if(Array.isArray(dadosSelecionados) && dadosSelecionados.length >= 2){
                setRevelarMensagemAtencaoTrocaOcorrencia(true)
                return null;
            }

            //se nao for solicitacao do tipo 2 (canselamento de simcard) ele nao ira fazer nada
            let validadorCdSolicitacao = true
            if(Array.isArray(dadosSelecionados)){
                await Promise.all(dadosSelecionados.map((item_dadosSelecionados,i)=>{
                    //validador
                    if(item_dadosSelecionados.CD_TIPO_SOLICITACAO != 2){
                        validadorCdSolicitacao = false
                    }
                    //cds das solicitacoes
                    cd_crm_solicitacoes.push(item_dadosSelecionados.CD_SOLICITACAO)

                }))
            }
            else{
                setRevelarMensagemAtencaoTrocaOcorrencia(true)
                return null;
            }
            
            if(!validadorCdSolicitacao){
                setRevelarMensagemAtencaoTrocaOcorrencia(true)
                return null;
            }
            //
            if(cd_crm_solicitacoes.length > 0){

                setFiltroSolicitacoes({cd_crm_solicitacoes:cd_crm_solicitacoes})
                setSelecionadoEmailUsuario(dadosSelecionados[0].USUARIO_EMAIL)
                setSelecionadoCdUsuario(dadosSelecionados[0].USUARIO_CRIADOR)
                setSelecionadoCliente(dadosSelecionados[0].CODIGO_CLIENTE)
                setSelecionadoCdCliente(dadosSelecionados[0].cd_pav_cliente)
                setSelecionadoProtocoloProtheus(dadosSelecionados[0].PROTOCOLO_PROTHEUS)
                despacho(revelarRealocacao(true))
                despacho(modoSelecaoSimcard(true))

            }
            
            
        }
        if(solicitacaReenviarTrocaOcorrencia){
            handlerTrocaOcorrencia()
        }
    },[solicitacaReenviarTrocaOcorrencia])

    useEffect(() => {
        setSpinAtivo(true) 
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));
        despacho(mudarManejador("solicitacaoCliente"));
        async function carregarTabela(){
            // //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/solicitacao?fl_operadora=0&paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&painel_virtueyes=true');
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    fl_operadora:0,
                    filtros: filtros,
                    painel_virtueyes:true,
                    buscar: conteudoBusca
                }
                
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/solicitacao", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/solicitacao?fl_operadora=0&paginaAtual='+contador+'&carregarLimit='+valorLimite+'&painel_virtueyes=true');
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, filtros])

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu===true?"expand-row-tabela-dispositivos-1":"expand-row-tabela-dispositivos-2"}>
                <div className="container-row-expand-central-acoes-solicitacao-operadora">
                    <DetalhamentoSolicitacaoCliente 
                        cdSolicitacao={row.CD_SOLICITACAO}
                        razaoSocial={row.RAZAO_SOCIAL}
                        nomeFantasia={row.NOME_FANTASIA}
                    />
                </div>
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="cabecalho-linha-expandida"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };
    const colunas = [{
            dataField: 'CODIGO_CLIENTE',
            headerClasses: 'nao-selecionavel',
            text: 'Cód. cliente',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'CD_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text: 'ID',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DESC_TIPO_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Tipo',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'DESC_STATUS_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Status',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'NOME_FANTASIA',
            headerClasses: 'nao-selecionavel',
            text: 'Descrição cliente',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'PROTOCOLO_PROTHEUS',
            headerClasses: 'nao-selecionavel',
            text: 'Protocolo protheus',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "Não enviado"}</p>;
            }
        },
        {
            dataField: 'DESC_SOLICITACAO',
            headerClasses: 'nao-selecionavel',
            text: 'Motivo',
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell != '' && cell != undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'ds_status_api',
            headerClasses: 'nao-selecionavel',
            text: 'Status API',
            sort: true,
            hidden: true,
            formatter: (cell, row) => {
                if(cell != '' && cell != undefined){
                    if(cell == 'Parcial'){
                        return <button className="fonte-cor-1 label-tabela-1 btn-status-tabela"><Image className="img-eclipse-status" src={ elipseAzul() }/> {cell}</button>
                    }else if(cell == 'Retornado'){
                        return <p className="fonte-cor-1 label-tabela-1"><Image className="img-eclipse-status" src={ elipseVerde() }/> {cell}</p>
                    }else{
                        return <button className="fonte-cor-1 label-tabela-1 btn-status-tabela"><Image className="img-eclipse-status" src={ elipseVermelho() }/> {cell}</button>
                    }
                }
                else{
                    return <p className="fonte-cor-1 label-tabela-1">-</p>;
                }
            }
        },
        {
            dataField: 'DT_INICIO',
            headerClasses: 'nao-selecionavel',
            text: 'Data de solicitação',
            sort: true,
            formatter: (cell, row) => {
                return formatarData(cell);
            }
        },
    ]

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const selecaoLinhas = {
        mode: 'checkbox' ,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionar(row.CD_SOLICITACAO)
                handleSelecionarTudo(row)
            }else{
                handleDesselecionar(row.CD_SOLICITACAO)
                handleDesselecionarTudo(row)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if(isSelect){
                handleSelecionarTodos()
            }else{
                handleDesselecionarTodos()
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-simcard" { ...rest }/>
                    <label class="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-header-simcard" { ...rest }/>
                    <label class="label-checkbox-header-simcard"></label>
                </>
            )
        },
        bgColor: 'row-index-bigger-than-2101'
    };

    function handleSelecionarTodos(){
        ColetaClickstream(423) // 'solicitacaoClienteTabelaHandleSelecionarTodos'
        itens.map((item,i)=>{
            if(itens[i].CD_SOLICITACAO){
                dadosSelecionados.push(itens[i]);
                dadosSelecionadosGerais.push(itens[i]);
            }
        })
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
        
    }

    function handleDesselecionarTodos(){
        ColetaClickstream(424) // 'solicitacaoClienteTabelaHandleDesselecionarTodos'
        dadosSelecionados = []
        dadosSelecionadosGerais = []
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(false));
    }

    function handleSelecionar(simcard){
        ColetaClickstream(425) // 'solicitacaoClienteTabelaHandleSelecionar'
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].CD_SOLICITACAO === simcard){
                dadosSelecionados.push(itens[i]);
                break;
            }
        }
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }
    function handleSelecionarTudo(simcard){
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].CD_SOLICITACAO === simcard.CD_SOLICITACAO){
                dadosSelecionadosGerais.push(itens[i]);
                break;
            }
        }
    }

    function handleDesselecionar(simcard){
        for (let i = 0; i < dadosSelecionados.length; i++) {
            if (dadosSelecionados[i].CD_SOLICITACAO === simcard){
                dadosSelecionados.splice(i, 1);
                break;
            }
        }
        if(dadosSelecionados.length == 0){
            despacho(desabilitarAcoes(true));
            despacho(desabilitarAcoesCliente(false));
        }
    }
    function handleDesselecionarTudo(simcard){
        for (let i = 0; i < dadosSelecionadosGerais.length; i++) {
            if (dadosSelecionadosGerais[i].CD_SOLICITACAO === simcard.CD_SOLICITACAO){
                dadosSelecionadosGerais.splice(i, 1);
                break;
            }
        }
    }


    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function inserirData(data){
        // setItens('')
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){

                    // itensVar[k] = data[j]
                    itensVar[k] = {
                        CD_SOLICITACAO: data[j].CD_SOLICITACAO,
                        CD_STATUS_SOLICITACAO: data[j].CD_STATUS_SOLICITACAO,
                        CD_TIPO_SOLICITACAO: data[j].CD_TIPO_SOLICITACAO,
                        CODIGO_CLIENTE: data[j].CODIGO_CLIENTE,
                        DATA_CRIACAO: data[j].DATA_CRIACAO,
                        DESC_SOLICITACAO: data[j].DESC_SOLICITACAO,
                        DESC_STATUS_SOLICITACAO: data[j].DESC_STATUS_SOLICITACAO,
                        DESC_TIPO_SOLICITACAO: data[j].DESC_TIPO_SOLICITACAO,
                        DT_FIM: data[j].DT_FIM,
                        DT_INICIO: data[j].DT_INICIO,
                        NOME_FANTASIA: data[j].NOME_FANTASIA + ' '+ data[j].CPF,
                        PREVISAO: data[j].PREVISAO,
                        PROTOCOLO_PROTHEUS: data[j].PROTOCOLO_PROTHEUS,
                        RAZAO_SOCIAL: data[j].RAZAO_SOCIAL,
                        TELEFONE: data[j].TELEFONE,
                        USUARIO: data[j].USUARIO,
                        USUARIO_CRIADOR: data[j].USUARIO_CRIADOR,
                        ds_status_api: data[j].ds_status_api,
                    }
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) { 
            apiData = await api.get('/m1/consultar/solicitacao?fl_operadora=0&contar=true&buscar='+conteudoBusca+'&painel_virtueyes=true');
        }else{
            const dados = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                fl_operadora: 0,
                filtros: filtros,
                contar: true,
                painel_virtueyes:true,
                buscar: conteudoBusca
            }
            apiData = await api.post("/m1/filtrar/solicitacao", dados);
        }
        if(apiData.data.dados){
            totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
            totalItens = apiData.data.dados[0].CONTADOR;
            for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                arrayItens.push(i)
            }
            itensVar = arrayItens
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
        }
        if(confirmacao){
            setRenderizar(!renderizar)
        }
    }
    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            {   reduxrevelarRealocacao === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <Realocacao
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            itensSelecionados={[]}
                            solicitacaoCliente={true}
                            filtros={filtroSolicitacoes}
                            USUARIO_EMAIL={selecionadoEmailUsuario}
                            USUARIO={selecionadoCdUsuario}
                            CLIENTE={selecionadoCliente}
                            cd_pav_cliente={selecionadoCdCliente}
                            PROTOCOLO_PROTHEUS={selecionadoProtocoloProtheus}
                        >
                        </Realocacao>
                    </div>
                </div>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            {revelarLogReset === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <LogReset
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            itensSelecionados={dadosSelecionados}
                        />
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={() =>   setRevelarMensagemErro(!revelarMensagemErro)}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Ocorreu um erro. Tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o Suporte."}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencao &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={() =>   setRevelarMensagemAtencao(!revelarMensagemAtencao)}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo3={mensagemAtencao}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemAtencaoTrocaOcorrencia &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={() =>   setRevelarMensagemAtencaoTrocaOcorrencia(!revelarMensagemAtencaoTrocaOcorrencia)}
                            mensagemTitulo={"Atenção"}
                            mensagemParagrafo2={"Selecione apenas uma solicitação"}
                            mensagemParagrafo3={'do tipo "Cancelamento Simcard"'}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                classes="tabela"
                                condensed={true}
                                keyField='CD_SOLICITACAO' 
                                data={itens} 
                                columns={colunas} 
                                expandRow={itemExpandido}
                                selectRow={ selecaoLinhas }
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
        visualizarDados: state.virtueyesState.visualizarDados,
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaClientesAcoes));