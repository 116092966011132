//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {formatarData} from '../../../utils/formatadorDataTabela'/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/

import { ButtonToolbar } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
//COMPONENTES
import CadastroTela from './cadastro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, limpouBusca, limpadorFiltros, realizouBusca, revelarBarraFiltragem, realizouFiltro, mudarFiltros, desabilitarAcoes } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelAdministrativo/pavTela/pavTela.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function Grid(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    const [cdModulo, setCdModulo] = useState("");
    const [dsModulo, setDsModulo] = useState("");
    const [cdTela, setCdTela] = useState("");
    const [dsTela, setDsTela] = useState("");
    const [dsRota, setDsRota] = useState("");
    const [dsDescricao, setDsDescricao] = useState("");
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS MENSAGENS
    const [desativaAtivar, setDesativarAtivar] = useState(false);
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //HOOKS MODAL
    const [renderizarCadastro, setRenderizarCadastro] = useState(false)
    const [renderizarEdicao, setRenderizarEdicao] = useState(false)
    const [revelarCadastro, setRevelarCadastro] = useState(false)
    const [revelarEdicao, setRevelarEdicao] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let novaTela = useSelector(state => state.manejadorState.revelarNovoTela);
    let editarTela = useSelector(state => state.manejadorState.revelarEditarTela);
    let temFiltros = false;

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(87) // 'pavTelaTabelaGrid'
            setColetaUsabilidade(false)
        }
    },[])
    
    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("tela"))
        despacho(desabilitarAcoes(true))
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/tela?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/tela", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/tela?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
        if (contador === i){
            let k = i
            for(let j=0;j<data.length;j++){
            itensVar[k] = data[j]
            k++
            }
        }
        if(i == (totalItens-1)){
            setItens(JSON.parse(JSON.stringify(itensVar)))
        }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/tela?contar="true"&buscar='+conteudoBusca);
            }else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/tela", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    
    const colunas = [{
        dataField: 'cd_pav_tela',
        text: 'ID',
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'cd_pav_modulo',
        headerClasses: 'nao-selecionavel',
        text: 'ID módulo',
        hidden: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_modulo',
        headerClasses: 'nao-selecionavel',
        text: 'Módulo',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_tela',
        headerClasses: 'nao-selecionavel',
        text: 'Tela',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_descricao',
        headerClasses: 'nao-selecionavel',
        text: 'Descrição',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_rota',
        headerClasses: 'nao-selecionavel',
        text: 'Rota',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'dt_created_at',
        headerClasses: 'nao-selecionavel',
        text: 'Data de criação',
        sort: true,
        formatter: (cell, row) => {
            let dataBr = '-'
            if(cell && cell != "0000-00-00 00:00:00"){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'dt_updated_at',
        text: 'Última alteração',
        sort: true,
        headerClasses: 'tabela-coluna-ultima-header nao-selecionavel',
        formatter: (cell, row) => {
            let dataBr = '-'
            if(cell && cell != "0000-00-00 00:00:00"){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: 'dt_deleted_at',
        text: colunaAcoes(),
        headerClasses: 'centralizar tabela-coluna-ultima-header nao-selecionavel',
        classes: 'botoes-barra-acoes',
        formatter: (cell, row) => {
            let checado = cell === "0000-00-00 00:00:00" ? true:false;
            return (
                <ButtonToolbar>{/* 
                    <Button variant="info" className="botoes-tabela-acoes" size="sm" onClick={() => handleEditar()}>
                        <img className="icone-botao-acao-tabela" src={editarVerde()} alt="editar-linha"/>
                    </Button> */}
                    {/* <Button variant="danger" className="botoes-tabela-acoes" size="sm" onClick={() => handleMensagemAtencao(false)}>
                        <img className="icone-botao-acao-tabela" src={lixeiraVermelho()} alt="remover-linha"/>
                    </Button> */}
                    <div class="container-switch-menu-dropdown-tabelas">
                        <input type="checkbox" class="flipswitch-tiny-cb" name={"name_"+row.cd_pav_tela} id={"switch-ativar-desativar"+row.cd_pav_tela} checked={checado} onClick={() => {handleMensagemAtencao(false); setDesativarAtivar(checado)}}/>
                        <label class="flipswitch-tiny-label" for={"switch-ativar-desativar"+row.cd_pav_tela}>
                            <div class="flipswitch-tiny-inner"></div>
                            <div class="flipswitch-tiny-switch"></div>
                        </label>
                    </div>
                </ButtonToolbar>
            )
        }
    }]

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setCdModulo(row.cd_pav_modulo);
                setDsModulo(row.ds_pav_modulo);
                setCdTela(row.cd_pav_tela);
                setDsTela(row.ds_pav_tela);
                setDsRota(row.ds_rota);
                setDsDescricao(row.ds_descricao);
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            // setCdModulo(row.cd_pav_modulo);
            // setDsModulo(row.ds_pav_modulo);
            // setCdTela(row.cd_pav_tela);
            // setDsTela(row.ds_pav_tela);
            // setDsRota(row.ds_rota);
            // setDsDescricao(row.ds_descricao);
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }




    function colunaAcoes(){
        return(
            <p className="acoes">Ativo</p>
        );
    }

    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/tela?cd_pav_tela='+cdTela)
        .then(function (response) {
            handleMensagemSucesso(false)
            setRenderizar(!renderizar)
            setSpinAtivo(false)
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    function handleCadastrar(){
        setRevelarCadastro(true)
        setRenderizarCadastro(!renderizarCadastro)
    }
    
    function handleEditar(){
        setRevelarEdicao(true)
        setRenderizarEdicao(!renderizarEdicao)
    }

    function handleCancelar(){
        setRevelarMensagemAtencao(false)
        setRevelarMensagemErro(false)
        setRevelarMensagemSucesso(false)
        setRevelarCadastro(false)
        setRevelarEdicao(false)
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            ColetaClickstream(1000) // pavTelaTabelaGridDesativarTela
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                ColetaClickstream(1370) // pavTelaTabelaGridDesativarTelaDesativar
                handleDeletar()
            } else {
                ColetaClickstream(1371) // pavTelaTabelaGridDesativarTelaCancelar
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            { novaTela === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroTela
                            tituloJanela={'Cadastrar'}
                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroTela>
                    </div>
                </div>
            }
            { editarTela === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroTela
                            tituloJanela={'Editar'}
                            cdTela={cdTela}
                            cdModulo={cdModulo}
                            dsModulo={dsModulo}
                            dsRota={dsRota}
                            dsNome={dsTela}
                            dsDescricao={dsDescricao}
                            setRevelarCadastro={setRevelarCadastro}
                            setRevelarEdicao={setRevelarEdicao}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroTela>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_pav_tela' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={desativaAtivar ? "Tela "+ dsTela +" desativada." : "Tela "+ dsTela +" ativada."}
                    mensagemBotao={"Continuar"}
                />
            }
            { revelarMensagemAtencao &&
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={desativaAtivar ? "Desativar "+dsTela : "Ativar "+dsTela}
                    mensagemParagrafo1={desativaAtivar ? "Você está desativando esse registro" : "Você está Ativando esse registro"}
                    mensagemBotao={desativaAtivar ? "Desativar" : "Ativar"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));