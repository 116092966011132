//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';/*eslint-disable*/
import {Image} from 'react-bootstrap';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
//COMPONENTES
import CadastroOperadora from './cadastro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarOrdenador, mudarManejador, limpouBusca, limpadorFiltros, realizouBusca, revelarBarraFiltragem, realizouFiltro, mudarFiltros, revelarNovaOperadora, revelarEditarOperadora, revelarExcluirOperadora, desabilitarAcoes } from "../../../redux/actions/index";
// SPINNER
import {setaDropdown} from '../../../utils/alternanciaIcones';
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatOperadoras/fatOperadora.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaOperadoras(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    const [cdOperadora, setCdOperadora] = useState()
    const [dsApelido, setDsApelido] = useState()
    const [dsNome, setDsNome] = useState()
    const [cdTipoFebraban, setCdTipoFebraban] = useState()
    const [n0Codigo, setN0Codigo] = useState()
    const [n0Ddi, setN0Ddi] = useState()
    const [cdPais, setCdPais] = useState([])
    const [dsPais, setDsPais] = useState([])
    const [flPadrao, setFlPadrao] = useState()
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS MENSAGENS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let novaOperadora = useSelector(state => state.faturasState.revelarNovaOperadora);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let editarOperadora = useSelector(state => state.faturasState.revelarEditarOperadora);
    let excluirOperadora = useSelector(state => state.faturasState.revelarExcluirOperadora);
    let temFiltros = false;

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(691) // 'fatOperadorasTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("faturasOperadoras"))
        despacho(desabilitarAcoes(true))
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(ordenador){
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&ordenador='+ordenador+'&fatura=true&cd_pav_usuario='+decryptJWT('codigo')

                    const { data } = await api.get('/m1/consultar/fat_operadora?'+queryString);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar: conteudoBusca,
                    ordenador: ordenador,
                    fatura: true,
                    filtros: filtros,
                    cd_pav_usuario: decryptJWT('codigo')
                }
                if (controladorPagina <= paginaAtual) {

                    const { data } = await api.post("/m1/filtrar/fat_operadora", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&ordenador='+ordenador+'&fatura=true&cd_pav_usuario='+decryptJWT('codigo')

                    const { data } = await api.get('/m1/consultar/fat_operadora?'+queryString);
                    inserirData(data.dados)
                }
            }
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 

                apiData = await api.get('/m1/consultar/fat_operadora?contar=true&buscar='+conteudoBusca+'&ordenador='+ordenador+'&fatura=true&cd_pav_usuario='+decryptJWT('codigo'));
            }else{
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar: conteudoBusca,
                    ordenador: ordenador,
                    fatura: true,
                    filtros: filtros,
                    contar: true,
                    cd_pav_usuario: decryptJWT('codigo')
                }

                apiData = await api.post("/m1/filtrar/fat_operadora", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    
    const colunas = [{
        dataField: 'cd_dsp_operadora',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("cd_dsp_operadora",'cd_dsp_operadora')}>
        Cód. operadora
        <Image id="cd_dsp_operadoracd_dsp_operadora" className='d-none' src={setaDropdown()}/>
        </button>,
        hidden: true,
        headerClasses: 'nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_dsp_operadora',
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_dsp_operadora",'ds_dsp_operadora')}>
        Nome
        <Image id="ds_dsp_operadorads_dsp_operadora" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_apelido',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_apelido",'ds_apelido')}>
        Pseudônimo
        <Image id="ds_apelidods_apelido" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'n0_ddi',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_ddi",'n0_ddi')}>
        DDI
        <Image id="n0_ddin0_ddi" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_pav_pais',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_pav_pais",'ds_pav_pais')}>
        País
        <Image id="ds_pav_paisds_pav_pais" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell ? cell.replace(/,/g, ', ') : "-"}</p>;
        }
    },
    {
        dataField: 'n0_simcards',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_simcards",'n0_simcards')}>
        Número de SIM cards
        <Image id="n0_simcardsn0_simcards" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    }]

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setCdOperadora(row.cd_dsp_operadora)
                setDsApelido(row.ds_apelido)
                setDsNome(row.ds_dsp_operadora)
                setCdTipoFebraban(row.cd_fat_tipo_febraban)
                setN0Codigo(row.n0_codigo)
                setN0Ddi(row.n0_ddi)
                setCdPais(row.cd_pav_pais)
                setDsPais(row.ds_pav_pais)
                if(row.fl_padrao !== 1){
                    despacho(desabilitarAcoes(false));
                }else{
                    despacho(desabilitarAcoes(true));
                }
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    async function handleDeletar(){
        setSpinAtivo(true)

        await api.get('/m1/deletar/fat_operadora?cd_dsp_operadora='+cdOperadora+'&cd_pav_usuario='+decryptJWT('codigo'))
        .then(function (response) {
            if(response.data.status === 600){
                setMensagemErro(response.data.info)
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirOperadora(false))
                handleMensagemErro(false)
                setSpinAtivo(false)
            }else{
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirOperadora(false))
                handleMensagemSucesso(false)
                setRenderizar(!renderizar)
                setSpinAtivo(false)
            }
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    useEffect(() => {
        if(excluirOperadora === true){
            setRevelarMensagemAtencao(true)
        }
    },[excluirOperadora])

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                ColetaClickstream(1276) // fatOperadorasTabelaDropAcoesRevelarExcluirOperadoraExcluir
                setRevelarMensagemAtencao(false)
                handleDeletar()
            } else {
                ColetaClickstream(1275) // fatOperadorasTabelaDropAcoesRevelarExcluirOperadoraCancelar
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            { novaOperadora === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroOperadora
                            tituloJanela={'Cadastrar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroOperadora>
                    </div>
                </div>
            }
            { editarOperadora === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroOperadora
                            tituloJanela={'Editar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cdOperadora={cdOperadora}
                            dsApelido={dsApelido}
                            dsNome={dsNome}
                            cdTipoFebraban={cdTipoFebraban}
                            n0Codigo={n0Codigo}
                            n0Ddi={n0Ddi}
                            cdPais={cdPais}
                            dsPais={dsPais}
                        >
                        </CadastroOperadora>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_dsp_operadora' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Operadora "+ dsApelido +" excluída."}
                    mensagemBotao={"Continuar"}
                />
            }
            { revelarMensagemAtencao &&
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Excluir operadora"}
                    mensagemParagrafo1={"Tem certeza que deseja excluir a operadora "+ dsApelido +"?"}
                    mensagemBotao={"Excluir"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaOperadoras));