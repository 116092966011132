//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {formatarData} from '../../../../utils/formatadorDataTabela';/*eslint-disable*/

import { useDispatch, useSelector } from 'react-redux';
import { mudarManejador, limpouBusca, realizouBusca, limpadorFiltros, mudarOrdenador } from "../../../../redux/actions/index.js";

import {api} from '../../../../conexoes/api';
import {Button, ButtonToolbar, Image} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
//ICONES
import {lupa, setaDropdown} from '../../../../utils/alternanciaIcones'
//COMPONENTES
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import MaisInformacoes from './maisInformacoes';
import BarraDadosCliente from '../../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../../componentesModais/barraFiltragem/barraFiltragem'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
import decryptJWT from '../../../../utils/decryptJWT';

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

export default function Grid(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState([])
    const [auxItens, setAuxItens] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1)
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const [maisInfo, setMaisInfo] = useState([])
    const [revelarMaisInformacoes, setRevelarMaisInformacoes] = useState(false)
    const [renderizarMaisInformacoes, setRenderizarMaisInformacoes] = useState(false)
    const [totalPaginas, setTotalPaginas] = useState(0)
    const [totalItens, setTotalItens] = useState(0)
    const [refazerContagem, setRefazerContagem] = useState(true)
    const [refazerChamadas, setRefazerChamadas] = useState(true)
    const [renderizar, setRenderizar] = useState(false)
    //HOOKS REDUX
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados)
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem)
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca)
    let ordenador = useSelector(state => state.manejadorState.ordenador)
    let filtros = useSelector(state => state.filtrosState.filtros)

    //REDUX VARIÁVEIS
    const despacho = useDispatch()
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(156) // 'logsRequestLogTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        resetarConfiguracoesTabela()
    },[ordenador, conteudoBusca, filtros])

    
    // useEffect(()=>{
    //     despacho(revelarBarraFiltragem(false))
    //     if(!filtros.vazio){
    //         despacho(mudarFiltros({vazio: 'vazio'}))
    //     }
    //     despacho(limpadorFiltros(true))
    // }, [])

    useEffect(() => {
        despacho(mudarManejador("requestLog"))
        setSpinAtivo(true)
        if(paginaAtual > 1){
            setOffset((paginaAtual-1)*limit)
        }else{
            setOffset(0)
        }
        async function carregarTabela(){            
            if(refazerContagem === true){
                const contagem = await api.post('/m1/log/consultar/requestLog',{
                    "cd_pav_cliente":decryptJWT('codigoCliente'),
                    "limit":limit,
                    "offset":offset,
                    "contar":true,
                    "ordenador":ordenador,
                    "buscar": conteudoBusca,
                    "filtros": filtros
                })
                contarData(contagem)
            }
            if(totalItens > 0 && refazerChamadas === true){
                const dados = await api.post('/m1/log/consultar/requestLog',{
                    "cd_pav_cliente":decryptJWT('codigoCliente'),
                    "limit":limit,
                    "offset":paginaAtual > 1 ? (paginaAtual-1)*limit : offset,
                    "contar":false,
                    "ordenador":ordenador,
                    "buscar": conteudoBusca,
                    "filtros": filtros
                })
                inserirData(dados.data.dados)
            }else if(totalItens > 0 && refazerChamadas === false){
                setRefazerChamadas(true)
                setItens(JSON.parse(JSON.stringify(auxItens)))
            }
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, totalItens, offset])
    
    function contarData(data){
        setRefazerContagem(false)
        setTotalPaginas(Math.ceil(data.data.dados[0].CONTADOR / limit))
        setTotalItens(data.data.dados[0].CONTADOR)
        for( var i = 0 ; i < data.data.dados[0].CONTADOR; i++){
            auxItens.push(i)
        }
    }

    function inserirData(data){
        setRefazerChamadas(false)
        for(let i=0;i<totalItens;i++){
            if (offset === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    auxItens[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(auxItens)))
            }
        }
        setSpinAtivo(false)
    }

    function resetarConfiguracoesTabela(){
        setRefazerContagem(true)
        setRefazerChamadas(true)
        setTotalPaginas(0)
        setOffset(0)
        setTotalItens(0)
        setPaginaAtual(1)
    }

    const colunas = [{
        dataField: 'id',
        text: 'ID',
        hidden:true,
    },
    {
        dataField: 'url',
        text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("url",'url')}>
                URL
                <Image id="urlurl" className='d-none' src={setaDropdown()}/>
            </button>,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'status_resposta',
        text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("status_resposta",'status_resposta')}>
                Status resposta
                <Image id="status_respostastatus_resposta" className='d-none' src={setaDropdown()}/>
            </button>,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'usuario',
        text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("usuario",'usuario')}>
                Usuário
                <Image id="usuariousuario" className='d-none' src={setaDropdown()}/>
            </button>,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell ? cell : '-'}</p>;
        }
    },
    {
        dataField: 'dt_created_at',
        text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_created_at",'dt_created_at')}>
                Data
                <Image id="dt_created_atdt_created_at" className='d-none' src={setaDropdown()}/>
            </button>,
        formatter: (cell, row) => {
            if(cell && cell != "0000-00-00 00:00:00"){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    {
        dataField: '',
        text: colunaAcoes(),
        headerClasses: 'centralizar tabela-coluna-ultima-header',
        classes: 'botoes-barra-acoes',
        formatter: (cell, row) => {
            return (
                <ButtonToolbar>
                    <Button variant="info" className="botoes-tabela-acoes" size="sm" onClick={() => handleVisualizarMaisInformacoes()}>
                        <img alt='lupa' className="icone-botao-acao-tabela" src={lupa()}/>
                    </Button>
                </ButtonToolbar>
            )
        }
    }
    ]

    function colunaAcoes(){
        return(
            <p className="acoes">Mais</p>
        );
    }

    function handleVisualizarMaisInformacoes(){
        setRevelarMaisInformacoes(true)
        setRenderizarMaisInformacoes(!renderizarMaisInformacoes)
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        ColetaClickstream(1055) // 'logsRequestLogTabelaHandlerOrdenador'
        if(decryptJWT("clientePrimario") != 2455){
            let isItDescrescente = false
            const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
            //verifica esta mudando a forma de ordenacao no mesmo elemento
            if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
                await ordenador.split(' ').map((item_ordenador,i)=>{
                    //verifica se existe tal item e muda para decrescente
                    if(item_ordenador === 'ASC'){
                        //Sera tal ordenador para decrescente
                        ordenador = nomeOrdenador +' DESC'
                        isItDescrescente = true
                        //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                        handlerDesativarImagens(imagem,'ASC')            
                    }
                    if(item_ordenador === 'DESC'){
                        //Sera tal ordenador para crescente
                        ordenador = ''
                        isItDescrescente = false
                        //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                        handleRemoverImagens(imagem)
                    }
                })
            }else if(!isItDescrescente){
                ordenador = nomeOrdenador +' ASC'
                //Adiciona a classe de crescente para a imagem e faz a limpeza dos outros icones
                handlerDesativarImagens(imagem,'DESC')
            }
            
            despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));
        }        
    }

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            let maisInfo = {}
            maisInfo.headers = row.headers
            maisInfo.ip = row.ip
            maisInfo.body= row.body
            maisInfo.query = row.query
            setMaisInfo(maisInfo)            
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: limit,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }
   
    return(
        <div className='container-tela'>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            {revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            { revelarMaisInformacoes === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MaisInformacoes
                            maisInfo={maisInfo}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            setRevelarMaisInformacoes={setRevelarMaisInformacoes}
                        >
                        </MaisInformacoes>
                    </div>
                </div>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='ID' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={limit}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{offset + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{offset + limit <= totalItens ? offset + limit : totalItens}</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}