//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { api } from '../../../conexoes/api';/*eslint-disable*/
import { Image, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import 'moment-timezone';
import moment from 'moment';
import { formatarData } from '../../../utils/formatadorDataTabela'
import { formatarMilhar } from '../../../utils/formatarMilhar'
import ReactDragListView from "react-drag-listview";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
//IMPORTAÇÕES ICONES
import { setaDropdown, elipseAzul, elipseVermelho, elipseVerde, elipseCinza, elipseAmarelo } from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { useDispatch, connect } from 'react-redux';
import { useSelector } from 'react-redux'
import { RevelarBuscarEFiltro, revelarRelatorioFaturaDispositivos, limpadorFiltros, modoSelecaoSimcard, revelarBarraFiltragem, mudarPermissaoElementoAcao, mudarFiltros, realizouFiltro, mudarManejador, desabilitarAcoes, limpouBusca, realizouBusca, desabilitarAcoesCliente, mudarOrdenador, separadorLinhaBusca, mudarBuscaArquivo, revelarDispositivosAlterarCicloDeVida, revelarDispositivosAlterarPlano, revelarResetFaturas, revelarLiberarSMSFaturas, revelarDesativarSMSFaturas, revelarDispositivosAlterarServicos, revelarDispositivosAlterarConta, revelarAlteracaoprazodepermanenciaFaturas, revelarAlterarICCIDFaturas } from "../../../redux/actions/index";
//IMPORTAÇÕES TELAS DE AÇÕES
import AlterarPrazoPermanencia from './acoes/alterarPrazoPermanencia'
import AlterarCicloDeVida from './acoes/alterarCicloDeVida'
import AlterarConta from './acoes/alterarConta'
import ModalAssociarClientes from '../fatFebraban/associarFatura/associarFatura'
import AtribuirLinhas from './acoes/atribuirLinhas'
import AlterarServico from './acoes/alterarServico'
import AlterarIccid from './acoes/alterarIccid'
import ImportarDescricao from './acoes/ImportarDescricao'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
import Reset from './acoes/reset'
import LiberarSms from './acoes/smsLiberar'
import BloquearSms from './acoes/smsBloquear'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import PersonalizarColunasFaturas from '../../personalizarColunasFaturas/personalizarColunasFaturas'
//IMPORTAÇÕES COMPONENTES
import Detalhamento from './detalhamento/detalhamento'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import { validadorPermissaoElemento } from '../../../utils/verificadorPermissaoElemento'
import MensagemInformacao from '../../componentesModais/mensagens/mensagemInformacao'
import decryptJWT from '../../../utils/decryptJWT';
//ESTILIZACAO
import '../../../css/painelFaturas/fatDispositivos/fatAcoes.css'
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
import BarraTabelaLegenda from '../../tabelaSelecao/barraTabelaLegenda';
import { clickDesselecionarCheckboxVerde, desselecionarTodosCheckboxes } from '../../tabelaSelecao/checkboxSelecao';
import { getListaIccidsNaoEncontrados, getListaLinhasNaoEncontradas, montarXlsLinhasNaoEncontradas } from '../../../utils/exportarArquivo';
import { limiteSolicitacoes } from '../../../utils/solicitacoes';
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao';
import { RenderMensagemSucesso } from '../../componentesModais/mensagens/mensagemAcao';
import AlterarPlano from "./acoes/alterarPlano";
import { AtencaoBaixarArquivoLinhasNaoEncontradas, BotaoInvisivelExportarLinhasNaoEncontradas, clickBotaoInvisivelExportarLinhasNaoEncontradas } from '../../buscaArquivo';

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 50
let operadorasSelecionadas = [];
let itensVar = []
let ordem = {}
let ciclo = undefined
let alternadorSelecao = false
let simcardsArray = []
let simcardsSelecionados = []; 

let chaveArquivoLinhasNaoEncontradas = false;

function TabelaSimcard(props) {
    //SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [filtro_arquivo, setFiltroArquivo] = useState(false);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    const [cicloAtual, setCicloAtual] = useState(0);
    const [permissaoDescricao, setPermissaoDescricao] = useState();
    const [arrayCdSimcard, setArrayCdSimcard] = useState([])
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [dadosParaDownloadLinhasNaoEncontradas, setDadosParaDownloadLinhasNaoEncontradas] = useState([['Linha', 'ICCID']])
    const [renderDadosParaDownloadLinhasNaoEncontradas, setRenderDadosParaDownloadLinhasNaoEncontradas] = useState(false)
    const [revelarContador, setRevelarContador] = useState(false)

    const [showCaption, setShowCaption] = useState(false);
    const [limpandoSelecao, setLimpandoSelecao] = useState(false);

    const [mensagemLimite, setMensagemLimite] = useState(false);
    const [revelarMensagemLimite, setRevelarMensagemLimite] = useState(false);
    const [mensagemSucesso, setMensagemSucesso] = useState(false)

    //REDUX HOOKS
    let reduxModoSelecaoSimcard = useSelector(state => state.manejadorState.modoSelecaoSimcard);
    let revelarExibirColunas = useSelector(state => state.manejadorState.revelarExibirColunas);
    let revelarVisualizarDados = useSelector(state => state.virtueyesState.visualizarDados);
    let revelarImportarDescricao = useSelector(state => state.manejadorState.revelarImportarDescricao);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);

    let associarFatura = useSelector(state => state.faturasState.revelarAssociarFatura);
    let revelarAlterarPrazoPermanenciaVar = useSelector(state => state.faturasState.revelarAlteracaoprazodepermanenciaFaturas);
    let revelarDispositivosAlterarServicosVar = useSelector(state => state.faturasState.revelarDispositivosAlterarServicos);
    let revelarDispositivosAlterarPlanoVar = useSelector(state => state.faturasState.revelarDispositivosAlterarPlano);
    let revelarDispositivosAlterarCicloDeVidaVar = useSelector(state => state.faturasState.revelarDispositivosAlterarCicloDeVida);
    let revelarResetFaturasVar = useSelector(state => state.faturasState.revelarResetFaturas);
    let revelarLiberarSmsFaturasVar = useSelector(state => state.faturasState.revelarLiberarSmsFaturas);
    let revelarBloquearSmsFaturasVar = useSelector(state => state.faturasState.revelarDesativarSMSFaturas);
    let revelarDispositivosAlterarContaVar = useSelector(state => state.faturasState.revelarDispositivosAlterarConta);
    let revelarDispositivosAlterarIccidVar = useSelector(state => state.faturasState.revelarAlterarICCIDFaturas);
    let controladorRelatorioFaturaDispositivos = useSelector(state => state.faturasState.revelarRelatorioFaturaDispositivos);


    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let separadorLinha = useSelector(state => state.manejadorState.separadorLinha);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let buscaArquivo = useSelector(state => state.buscaArquivoState.buscaArquivo);
    let limparFiltros = useSelector(state => state.filtrosState.limparFiltros);
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);
    const despacho = useDispatch();
    let temFiltros = false;

    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    const ultrapassouLimite = totalItens > limiteSolicitacoes && reduxModoSelecaoSimcard;

    function fecharModalAcao() {
        if(revelarDispositivosAlterarCicloDeVidaVar) {
            despacho(revelarDispositivosAlterarCicloDeVida(false))
        }
        else if(revelarResetFaturasVar) {
            despacho(revelarResetFaturas(false))
        }
        else if(revelarLiberarSmsFaturasVar) {
            despacho(revelarLiberarSMSFaturas(false))
        }
        else if(revelarBloquearSmsFaturasVar) {
            despacho(revelarDesativarSMSFaturas(false))
        }
        else if(revelarDispositivosAlterarPlanoVar) {
            despacho(revelarDispositivosAlterarPlano(false))
        }
        else if(revelarDispositivosAlterarContaVar) {
            despacho(revelarDispositivosAlterarConta(false))
        }
        else if(revelarDispositivosAlterarServicosVar) {
            despacho(revelarDispositivosAlterarServicos(false))
        }
        else if(revelarAlterarPrazoPermanenciaVar) {
            despacho(revelarAlteracaoprazodepermanenciaFaturas(false))
        }
        else if(revelarDispositivosAlterarIccidVar) {
            despacho(revelarAlterarICCIDFaturas(false))
        }
        // else alterar iccid
    }

    function controleLimiteSimcardsNaSolicitacao() {
        if(ultrapassouLimite && (
            revelarDispositivosAlterarCicloDeVidaVar || 
            revelarResetFaturasVar || 
            revelarLiberarSmsFaturasVar || 
            revelarBloquearSmsFaturasVar === true || 
            revelarDispositivosAlterarPlanoVar || 
            revelarDispositivosAlterarContaVar || 
            revelarDispositivosAlterarServicosVar ||
            revelarAlterarPrazoPermanenciaVar
        )) {
            fecharModalAcao();

            setMensagemLimite(`Essa solicitação não permite mais de ${limiteSolicitacoes.toLocaleString()} SIMcards selecionados`)
            setRevelarMensagemLimite(true);
        }
    }
    controleLimiteSimcardsNaSolicitacao();


    function onClickSelecaoParqueTodo(novoValor) {
        if(novoValor === false) { // limpar seleção
            desselecionarTodosCheckboxes('limparSelecao')

            setLimpandoSelecao(true);
            setShowCaption(false);
        }
        handleAlternarSelecao(novoValor);
    }

    useEffect(() => {
        if (coletaUsabilidade) {
            ColetaClickstream(1242) // 'fatFaturasDispositivos'
            setColetaUsabilidade(false)
        }
        
    }, [])

    useEffect(() => {
        async function buscarDadosRelatorio() {
            setSpinAtivo(true)

            let hasFilters;
            if (props.filtros.vazio == 'vazio') 
            {
                hasFilters = false;    
            } 
            else 
            {
                hasFilters = true;
            }

            const consultaSimcards = await api.post('/m1/filtrar/fatSimcard', {
                "cd_pav_usuario": decryptJWT('codigo'),
                "filtros": filtros,
                "buscaArquivo": buscaArquivo,
                "buscar": conteudoBusca,
                "simcards": arrayCdSimcard,
                "modoSelecaoSimcard": reduxModoSelecaoSimcard,
                "contar": false,
                "relatorio": true,
                "separador": separadorLinha
            })
                .then(function (resposta) {
                    let arrayAuxSimcard = []
                    if (resposta.data.dados != undefined) {
                        resposta.data.dados.map((item) => {
                            arrayAuxSimcard.push(item.CD_SIMCARD)
                        })

                        const dados = {
                            codigo_cliente: decryptJWT('codigoCliente'),
                            codigo_usuario: decryptJWT('codigo'),
                            descricao: 'Relatório de dispositivos de faturas',
                            tipo_arquivo: controladorRelatorioFaturaDispositivos,
                            tipo_relatorio: 11,
                            buscar: conteudoBusca,
                            buscaArquivo,
                            arrayCdSimcard: arrayAuxSimcard,
                            separador: separadorLinha,
                            parqueInteiro: reduxModoSelecaoSimcard,
                            temFiltros: hasFilters
                        }

                        api.post('/m1/cadastrar/relatorios', dados)
                        handleMensagemInformacao(true)
                        despacho(revelarRelatorioFaturaDispositivos(false))
                        return resposta.data.dados;
                    }
                })
                .catch(function (erro) {
                    throw erro;
                })
            setSpinAtivo(false)
        }
        if (controladorRelatorioFaturaDispositivos === "XLS" || controladorRelatorioFaturaDispositivos === "XLSX" || controladorRelatorioFaturaDispositivos === "CSV") {
            buscarDadosRelatorio()
        }
    }, [controladorRelatorioFaturaDispositivos])

    function handleMensagemInformacao(confirmacao) {
        if (revelarMensagemInformacao === false) {
            setRevelarMensagemInformacao(true)
        } else {
            setRevelarMensagemInformacao(false)
        }
    }

    function handleMensagemLimite(){
        if(revelarMensagemLimite === false){
            setRevelarMensagemLimite(true)
        }else{
            setRevelarMensagemLimite(false)
        }
    }

    function handleMensagemSucesso(confirmacao, arrMsgSucesso){
        if(mensagemSucesso === false){
            setMensagemSucesso(arrMsgSucesso)
        }else{
            setMensagemSucesso(false)
        }
        if(confirmacao){
            setRenderizar(!renderizar)
        }
    }

    async function handleMontarXlsLinhasNaoEncontradas(dados) {
        const dadosMontar = await montarXlsLinhasNaoEncontradas(dados);

        if(dadosMontar) {
            dadosParaDownloadLinhasNaoEncontradas.push(...dadosMontar);
            setRenderDadosParaDownloadLinhasNaoEncontradas(true) // provoca o useEffect abaixo (com renderDadosParaDownloadLinhasNaoEncontradas)
        }
    }

    function handleRespostaSolicitacao({response, error}) { 
        let arrMsgSucesso = {
            arrTexto: ["Sucesso", "Solicitação enviada"],
            btnTexto: "Continuar"
        };

        if(response) { // sucesso
            fecharModalAcao();

            const { linhasInvalidas } = response?.data
            const qtdInvalidas = linhasInvalidas?.length
            if(qtdInvalidas) {
                arrMsgSucesso = {
                    arrTexto: [
                        "Solicitação enviada",
                        `Foram encontradas ${qtdInvalidas} linhas inválidas na sua solicitação. 
                            Todas as demais linhas foram incluídas.`,
                        "Clique em baixar para verificar as linhas inválidas.",
                    ],
                    btnTexto: "Fechar",
                    linhasInvalidas,
                }
            } 

            handleMensagemSucesso(false, arrMsgSucesso);
        }
        else if(error) {
            const resp = error?.response;
            const respDados = resp?.data
            const mensagemErro = respDados?.info;
            
            if(mensagemErro && [400, 401].includes(resp?.status)) {
                return { atencao: mensagemErro }
            }
            else {
                console.error(error)
                return { erro:true }
            }
        }
    }

    function handleMensagemAtencao() {
        if (renderDadosParaDownloadLinhasNaoEncontradas) {
            clickBotaoInvisivelExportarLinhasNaoEncontradas();
            setRenderDadosParaDownloadLinhasNaoEncontradas(false)
            setDadosParaDownloadLinhasNaoEncontradas([['Linha', 'ICCID']])     
        }
    }

    useEffect(() => {
        function checarFiltros() {
            if (props.filtros.vazio == 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(() => {
        despacho(modoSelecaoSimcard(false))
        alternadorSelecao = reduxModoSelecaoSimcard
        async function handlerVerificarPermissaoAcao() {
            despacho(mudarPermissaoElementoAcao(!await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/acoes")))
            setPermissaoDescricao(await validadorPermissaoElemento(permissaoElemento, "veye/dispositivos/detalhamento"))
        }
        handlerVerificarPermissaoAcao()
    }, [])


    useEffect(() => {
        restaurarConfigsIniciaisPagina()
    }, [props.ordenador])

    useEffect(() => {
        async function pegarSeparador() {

            const separador = await api.get('/m1/consultar/preferencias?cd_pav_usuario=' + decryptJWT('codigo')).then(
                function (resposta) {
                    return resposta.data.dados[0];
                }
            ).catch(
                function (erro) {
                    throw erro.stack;
                }
            )
            despacho(separadorLinhaBusca(separador.busca_separador))
        }

        pegarSeparador();

    }, [])

    useEffect(() => {
        if (limparFiltros) {
            if (!filtros.vazio) {
                despacho(mudarFiltros({ vazio: 'vazio' }))
                despacho(revelarBarraFiltragem(false))
                despacho(limpadorFiltros(false))
            }
        }
    }, [])

    useEffect(() => {
        const controller = new AbortController();
        const controller2 = new AbortController();

        if (simcardsSelecionados.length === 0 && alternadorSelecao === false) {
            despacho(desabilitarAcoes(true));
        }

        let data = new Date();
        let ano = data.getFullYear();
        let mes = data.getMonth() + 1;
        let dia = data.getDate();
        let anoFormatado = String(ano);
        let mesFormatado = mes < 10 ? '0' + String(mes) : String(mes);
        let diaFormatado = dia < 10 ? '0' + String(dia) : String(dia);
        let dataFormatada = anoFormatado + '-' + mesFormatado + '-' + diaFormatado;

        async function carregarTabela() {
            let desselecionarCheckboxAposRecarregarTabela = false;

            setSpinAtivo(true)
            if (ciclo === undefined) {
                ciclo = await api.get('/m1/consultar/ciclo?dt_data=' + dataFormatada)
                setCicloAtual(ciclo)
            }

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if (limpouBuscaGeral) {
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if (realizouBuscaGeral) {
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if (realizouFiltragem) {
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            //necessario para pegar os dados do filtro, pois por algum motivo ele se perde e nao aciona os props.filtros
            if (temFiltros) {
                temFiltros = false;
                restaurarConfigsIniciaisPagina()
            }

            setItens([])

            if (totalPaginas != 0) {
                contador = (paginaAtual - 1) * valorLimite;
            }

            // O trecho abaixo foi comentado pois ele gerava o BUG da tela retornar os primeiros 50 elementos
            // mesmo não tendo sido enviado nenhum arquivo no filtro
            // esta alteração foi realizada em 02/09/2022
            // caso você lei esta mensagem cerca de 3 a 4 meses para frente, por gentileza 
            // faça a limpeza de todo trecho comentado abaixo
            //

            // let dadosSimcard = []
            // else if (!props.conteudoBusca) {
            //     despacho(RevelarBuscarEFiltro(false))
            //     const urlGet = `/m1/filtrar/fatSimcard?paguinaAtual=${contador}&carregarLimit=${valorLimite}&buscar=${conteudoBusca}&ordenador=${ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${buscaArquivoStr}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}`
            //     dadosSimcard = await api.get(urlGet, { signal: controller2.signal })

            //     inserirData(dadosSimcard.data.dados)
            //     setSpinAtivo(false)
            // }
            const limparBuscaPorArquivo = () => despacho(mudarBuscaArquivo({}));

            let buscaArquivoStr = JSON.stringify(buscaArquivo);
            if(filtros?.ICCID && buscaArquivo?.chave) {
                buscaArquivoStr = '{}';
                limparBuscaPorArquivo();
            }

            // CONTADOR
            if (totalPaginas == 0 || props?.buscaArquivo?.chave) { // solicitações precisa disso?
                setRevelarContador(false)
                let arrayItens = []
                let apiData = []

                const urlGet = `/m1/filtrar/fatSimcard?buscar=${conteudoBusca}&contar=${true}&separador=${separadorLinha}&carregarLimit=${valorLimite}&paguinaAtual=${contador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${buscaArquivoStr}&cd_pav_usuario=${decryptJWT('codigo')}`
                apiData = await api.get(urlGet, { signal: controller.signal })

                totalItens = apiData.data.dados[0].CONTADOR;
                totalPaginas = Math.ceil(totalItens / valorLimite)
                for (var i = 0; i < totalItens; i++) {
                    arrayItens.push(i)
                }

                itensVar = arrayItens

                setRevelarContador(true)
                despacho(RevelarBuscarEFiltro(true))

                // ---------------------------------------
                desselecionarCheckboxAposRecarregarTabela = true;
            }

            const aindaNaoGerouArquivoLinhasNaoEncontradas = (chave) => (chaveArquivoLinhasNaoEncontradas !== chave)

            if (props.conteudoBusca && props.filtros.vazio && !props.buscaArquivo?.chave) {
                const urlGet = `/m1/filtrar/fatSimcard?paguinaAtual=${contador}&carregarLimit=${valorLimite}&buscar=${conteudoBusca}&ordenador=${ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${buscaArquivoStr}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}`

                if (controladorPagina <= paginaAtual) {
                    const simcards = await api.get(urlGet, { signal: controller.signal })
                        .then(function (resposta) {
                            return resposta.data.dados;
                        })
                        .catch(function (erro) {
                            throw erro.stack;
                        })

                    inserirData(simcards)
                }
            } else if (!props.filtros.vazio && !buscaArquivo.VALIDADOR_ARQUIVO) {
                const urlGet = `/m1/filtrar/fatSimcard?paguinaAtual=${contador}&carregarLimit=${valorLimite}&buscar=${conteudoBusca}&ordenador=${ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${buscaArquivoStr}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}`

                if (controladorPagina <= paginaAtual) {
                    const simcards = await api.get(urlGet, { signal: controller.signal })
                        .then(function (resposta) {
                            return resposta.data;
                        })
                        .catch(function (erro) {
                            throw erro.stack;
                        })

                    if (simcards.data?.arquivo && simcards.data.status == 200) {
                        handleMontarXlsLinhasNaoEncontradas(simcards.data.arquivo)
                    }

                    inserirData(simcards.dados)
                }
            }
            else if (props.buscaArquivo && buscaArquivo.VALIDADOR_ARQUIVO  && aindaNaoGerouArquivoLinhasNaoEncontradas(buscaArquivo.chave)) { 
                chaveArquivoLinhasNaoEncontradas = buscaArquivo.chave;

                // armazena a chave e o código do usuario, para consultar no BD
                // as linhas ou iccids enviados no filtro arquivo 
                const dados = {
                    chave: buscaArquivo.chave,
                    usuario: decryptJWT('codigo')
                }

                // Faz a consulta ao BD e retorna TODOS OS ITENS ENVIADOS NO ARQUIVO DE FILTRO POR ARQUIVO
                const { data: dataLinhasNaoEncontradas } = await api.post("/m1/filtrar/fat_iccid_ou_linhas_nao_encontrados", dados);    

                // Esta consulta retorna os itens ENCONTRADOS no BD
                async function filtrarFatSimcard(urlGet) {
                    return api.get(urlGet, { signal: controller.signal })
                    .then(function (resposta) {
                        return resposta.data;
                        
                    })
                    .catch(function (erro) {
                        throw erro.stack;
                    })
                }

                if (controladorPagina <= paginaAtual) {
                    let urlGet = `/m1/filtrar/fatSimcard?filtros=${JSON.stringify(filtros)}&buscaArquivo=${buscaArquivoStr}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}`

                    const simcards = await filtrarFatSimcard(urlGet+`&campos=CALLERID,ICCID`); // traz só iccids e linhas

                    // variáveis auxiliares    

                    // Monta um array com os dados retornados do BD, 
                    // caso encontrado algum registro de ICCID/LINHA relevante a busca
                    if(simcards.dados !== undefined){
                        const listaIccids = await getListaIccidsNaoEncontrados({dataLinhasNaoEncontradas, simcards});
                        handleMontarXlsLinhasNaoEncontradas(listaIccids)


                        const listaLinhas = await getListaLinhasNaoEncontradas({dataLinhasNaoEncontradas, simcards});
                        handleMontarXlsLinhasNaoEncontradas(listaLinhas)

                        // Para que ele não faça download do mesmo arquivo
                        // limpamos os filtros
                        despacho(limpadorFiltros(true))
                    } 
                    
                    const simcards2 = await filtrarFatSimcard(urlGet+`&carregarLimit=${valorLimite}&paguinaAtual=${contador}`);
                    if(simcards2?.dados) inserirData(simcards2.dados)
                               
                }
            }
            else {
                const urlGet = `/m1/filtrar/fatSimcard?paguinaAtual=${contador}&carregarLimit=${valorLimite}&buscar=${conteudoBusca}&ordenador=${ordenador}&filtros=${JSON.stringify(filtros)}&buscaArquivo=${buscaArquivoStr}&separador=${separadorLinha}&contar=false&cd_pav_usuario=${decryptJWT('codigo')}`

                if (controladorPagina <= paginaAtual) {
                    const simcards = await api.get(urlGet, { signal: controller.signal })
                        .then(function (resposta) {
                            return resposta.data.dados;
                        })
                        .catch(function (erro) {
                            throw erro.stack;
                        })

                    inserirData(simcards)
                }
            }

            // quando altera dados da tabela e precisa desselecionar ----------
            if(desselecionarCheckboxAposRecarregarTabela && !simcardsSelecionados?.length) {
                if(alternadorSelecao) {
                    onClickSelecaoParqueTodo(false);
                } else {
                    desselecionarTodosCheckboxes(); 
                }
            }
        }
        carregarTabela()

        return () => {
            controller.abort();
        }

    }, [paginaAtual, props.conteudoBusca, renderizar, props.filtros, props.buscaArquivo, ordem, props.ordenador
        // , reduxModoSelecaoSimcard
    ])

    function inserirData(data) { 
        for (let i = 0; i < totalItens; i++) {
            if (contador === i) {
                let k = i
                for (let j = 0; j < data.length; j++) {
                    itensVar[k] = data[j]
                    k++
                }
            }
            if (i == (totalItens - 1)) {
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)

        if(limpandoSelecao) {
            clickDesselecionarCheckboxVerde(false);
        }

    }

    //Enconta dentro dos itens da tabela o item ao qual vai ser ter a linha expandida
    function mapearDadoDetalhamentoLinha(dados) {
        let detalhamento = ''
        itens.map((item_simcard, i) => {
            if (item_simcard.CD_SIMCARD === dados.CD_SIMCARD) {
                detalhamento = item_simcard;
            }
        })
        return [detalhamento]
    }

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu === true ? "expand-row-tabela-dispositivos-1" : "expand-row-tabela-dispositivos-2"}>
                <Detalhamento renderConsultaColunas={props.render} setRenderConsultaColunas={props.setRender} row={mapearDadoDetalhamentoLinha(row)} cdSimcard={row.CD_SIMCARD} gsm={row.FULLCALLERID} previsaoConsumo={row.PREVISAO} plano={row.PLANO + row.EXCEDENTE} cdPlano={row.CD_PLANO} renderizar={renderizar} setRenderizar={setRenderizar}></Detalhamento>
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="cabecalho-linha-expandida">
            </div>
        ),
        expandColumnRenderer: (rowKey) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown(rowKey.rowKey)}>
                    <Image id={rowKey.rowKey} className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()} />
                </Button>
            );
        },
        showExpandColumn: true,
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    const selecaoLinhas = {
        mode: 'checkbox',
        hideSelectColumn: reduxModoSelecaoSimcard,
        hideSelectAll: reduxModoSelecaoSimcard,
        onSelect: (row, isSelect, rowIndex, e) => {
            if(limpandoSelecao) setLimpandoSelecao(false);

            if (isSelect) {
                handleSelecionarSimcard(row)
            } else {
                handleDesselecionarSimcard(row.CD_SIMCARD)
                if(showCaption) setShowCaption(false);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if(limpandoSelecao) setLimpandoSelecao(false);

            if (isSelect) {
                handleSelecionarTodosSimcards(rows)
                setShowCaption(true);
            } else {
                handleDesselecionarTodosSimcards(rows)
                if(showCaption) setShowCaption(false);
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} className="input-checkbox-simcard" {...rest} />
                    <label className="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            if(rest.checked && !showCaption) {
                setShowCaption(true);
            }

            return ( // checkbox verde
                <>
                    <input type={mode} className="input-checkbox-header-simcard" {...rest} />
                    <label className="label-checkbox-header-simcard"></label>
                </>
            )
        },
        bgColor: { backgroundColor: 'gray' },
        color: 'red'
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }

    function handleSelecionarTodosSimcards(rows) { // quando SELECIONA o "checkbox verde"
        rows.map((item, i) => {
            simcardsSelecionados.push(rows[i]);
            arrayCdSimcard.push(rows[i].CD_SIMCARD);
        })
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }
    function handleDesselecionarTodosSimcards(rows) {
        rows.map((item, i) => {
            simcardsSelecionados.map((simcards, x) => {
                if (rows[i].CD_SIMCARD === simcardsSelecionados[x].CD_SIMCARD) {
                    simcardsSelecionados.splice(x, 1);
                    arrayCdSimcard.splice(x, 1);
                }
            })
        })
        if (simcardsSelecionados.length === 0) {
            simcardsSelecionados = [];
            despacho(desabilitarAcoes(true));
        }
    }

    function handleSelecionarSimcard(simcard) {
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].CD_SIMCARD === simcard.CD_SIMCARD) {
                simcardsSelecionados.push(itens[i]);
                arrayCdSimcard.push(itens[i].CD_SIMCARD);
                operadorasSelecionadas.push(itens[i].DS_PEDIDO_OPERADORA);
                break;
            }
        }
        
        despacho(desabilitarAcoes(false));
        despacho(desabilitarAcoesCliente(true));
    }

    function handleDesselecionarSimcard(simcard, operadora) {
        for (let i = 0; i < simcardsSelecionados.length; i++) {
            if (simcardsSelecionados[i].CD_SIMCARD === simcard) {
                simcardsSelecionados.splice(i, 1);
                arrayCdSimcard.splice(i, 1);
                operadorasSelecionadas.splice(i, 1);
                break;
            }
        }
        if (simcardsSelecionados.length == 0) {
            despacho(desabilitarAcoes(true));
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange: (page) => { setPaginaAtual(page) }
    }

    function restaurarConfigsIniciaisPagina() {
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 50
        simcardsSelecionados = [];
        despacho(desabilitarAcoes(true));
        despacho(desabilitarAcoesCliente(true));
        setPaginaAtual(1)
    }

    function handleAlternarSelecao(valor) { // Serve para selecionar parque todo (antigo checkbox vermelho). valor=true => selecionado, valor=false => NÃO selecionado.
        alternadorSelecao = valor
        despacho(modoSelecaoSimcard(valor))
        if (valor) {
            despacho(desabilitarAcoes(false));
            despacho(desabilitarAcoesCliente(true));
        } else {
            despacho(desabilitarAcoes(true));
            despacho(desabilitarAcoesCliente(false));
        }
        simcardsSelecionados = [];
    }

    const getLimiteNaPagina = () => contador + valorLimite <= totalItens ? contador + valorLimite : totalItens;
    const getTotalItensNaPagina = () => getLimiteNaPagina() - contador;

    return (
        <>
            {spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye /></div></div>}
            <BotaoInvisivelExportarLinhasNaoEncontradas {...{dadosParaDownloadLinhasNaoEncontradas}} />
            <div className='container-tela'>
                {revelarMensagemInformacao &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <MensagemInformacao
                                handleMensagemInformacao={handleMensagemInformacao}
                                mensagemTitulo={"O arquivo está sendo gerado"}
                                mensagemParagrafo1={"Esse processo levará alguns minutos, você poderá fazer o download do seu arquivo na tela de relatórios assim que o link do mesmo estiver disponível"}
                                mensagemBotao={"Voltar"}
                            />
                        </div>
                    </div>
                }
            { revelarMensagemLimite &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemAtencao
                            handleMensagemAtencao={handleMensagemLimite}
                            mensagemTitulo={'Atenção'}
                            mensagemParagrafo1={mensagemLimite}
                            mensagemBotao={"FECHAR"}
                        />
                    </div>
                </div>
            }

            { mensagemSucesso && 
                <RenderMensagemSucesso {...{mensagemSucesso, handleMensagemSucesso}} />
            }

            {renderDadosParaDownloadLinhasNaoEncontradas && 
                <AtencaoBaixarArquivoLinhasNaoEncontradas
                    {...{handleMensagemAtencao, dadosParaDownloadLinhasNaoEncontradas}}
                />
            }
             
                {revelarDispositivosAlterarCicloDeVidaVar && !ultrapassouLimite &&
                    <>
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <AlterarCicloDeVida
                                    renderizar={renderizar}
                                    setRenderizar={setRenderizar}
                                    simcardsSelecionados={simcardsSelecionados}
                                    operadorasSelecionadas={operadorasSelecionadas}
                                    cdSimcardsArquivo={simcardsArray}
                                    handleRespostaSolicitacao={handleRespostaSolicitacao}
                                >
                                </AlterarCicloDeVida>
                            </div>
                        </div>
                    </>
                }
                {revelarDispositivosAlterarContaVar && !ultrapassouLimite &&
                    <>
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <AlterarConta
                                    renderizar={renderizar}
                                    setRenderizar={setRenderizar}
                                    simcardsSelecionados={simcardsSelecionados}
                                    operadorasSelecionadas={operadorasSelecionadas}
                                    cdSimcardsArquivo={simcardsArray}
                                    handleRespostaSolicitacao={handleRespostaSolicitacao}
                                >
                                </AlterarConta>
                            </div>
                        </div>
                    </>
                }
                {revelarDispositivosAlterarIccidVar && // !ultrapassouLimite &&
                    <>
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <AlterarIccid
                                    renderizar={renderizar}
                                    setRenderizar={setRenderizar}
                                    simcardsSelecionados={simcardsSelecionados}
                                    operadorasSelecionadas={operadorasSelecionadas}
                                    cdSimcardsArquivo={simcardsArray}
                                    handleRespostaSolicitacao={handleRespostaSolicitacao}
                                >
                                </AlterarIccid>
                            </div>
                        </div>
                    </>
                }
                {revelarResetFaturasVar && !ultrapassouLimite &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <Reset
                                renderizar={renderizar}
                                setRenderizar={setRenderizar}
                                itensSelecionados={simcardsSelecionados}
                                handleRespostaSolicitacao={handleRespostaSolicitacao}
                            >
                            </Reset>
                        </div>
                    </div>

                }
                {revelarLiberarSmsFaturasVar && !ultrapassouLimite &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <LiberarSms
                                renderizar={renderizar}
                                setRenderizar={setRenderizar}
                                itensSelecionados={simcardsSelecionados}
                                handleRespostaSolicitacao={handleRespostaSolicitacao}
                            >
                            </LiberarSms>
                        </div>
                    </div>
                }
                {revelarBloquearSmsFaturasVar === true && !ultrapassouLimite &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <BloquearSms
                                renderizar={renderizar}
                                setRenderizar={setRenderizar}
                                itensSelecionados={simcardsSelecionados}
                                handleRespostaSolicitacao={handleRespostaSolicitacao}
                            >
                            </BloquearSms>
                        </div>
                    </div>
                }
                {revelarDispositivosAlterarPlanoVar && !ultrapassouLimite &&
                    <>
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <AlterarPlano
                                    renderizar={renderizar}
                                    setRenderizar={setRenderizar}
                                    simcardsSelecionados={simcardsSelecionados}
                                    operadorasSelecionadas={operadorasSelecionadas}
                                    cdSimcardsArquivo={simcardsArray}
                                    filtro_simcard={filtro_arquivo}
                                    handleRespostaSolicitacao={handleRespostaSolicitacao}
                                >
                                </AlterarPlano>
                            </div>
                        </div>
                    </>
                }
                {revelarDispositivosAlterarServicosVar && !ultrapassouLimite &&
                    <>
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <AlterarServico
                                    renderizar={renderizar}
                                    setRenderizar={setRenderizar}
                                    simcardsSelecionados={simcardsSelecionados}
                                    operadorasSelecionadas={operadorasSelecionadas}
                                    cdSimcardsArquivo={simcardsArray}
                                    // handleRespostaSolicitacao={handleRespostaSolicitacao} ?
                                >
                                </AlterarServico>
                            </div>
                        </div>
                    </>
                }
                {revelarImportarDescricao === true && // !ultrapassouLimite &&
                    <>
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <ImportarDescricao
                                    setRenderizar={setRenderizar}
                                    renderizar={renderizar}
                                >
                                </ImportarDescricao>
                            </div>
                        </div>
                    </>
                }
                {revelarExibirColunas === true &&
                    <>
                        <div className="componente-modal-overlay">
                            <div className="componente-modal">
                                <PersonalizarColunasFaturas
                                    render={props.render}
                                    setRender={props.setRender}
                                    setRenderizar={setRenderizar}
                                    renderizar={renderizar}
                                >
                                </PersonalizarColunasFaturas>
                            </div>
                        </div>
                    </>
                }
                {associarFatura === true &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <ModalAssociarClientes
                                // cdFatFebraban={cdFatFebraban}
                                tituloJanela="Associar"
                                setRenderizar={setRenderizar}
                                renderizar={renderizar}
                            />
                        </div>
                    </div>
                }
                {revelarAlterarPrazoPermanenciaVar === true && !ultrapassouLimite &&
                    <div className="componente-modal-overlay">
                        <div className="componente-modal">
                            <AlterarPrazoPermanencia
                                setRenderizar={setRenderizar}
                                renderizar={renderizar}
                                simcardsSelecionados={simcardsSelecionados}
                                handleRespostaSolicitacao={handleRespostaSolicitacao}
                            />
                        </div>
                    </div>
                }
                {revelarVisualizarDados === true &&
                    <BarraDadosCliente />
                }
                {revelarBarraFiltro === true &&
                    <BarraFiltragem />
                }


                <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                    {
                        ({
                            paginationProps,
                            paginationTableProps
                        }) => (
                            <>

                                {/* ANTIGO checkbox VERMELHO
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-bottom`}>Ative para selecionar o parque inteiro</Tooltip>}>
                                    <div class="container-switch-modo-selecao-dispositivos">
                                        <input type='checkbox' class="input-checkbox-header-simcard-2" id="selecionarTodos" name="selecionarTodos" onChange={(e) => handleAlternarSelecao(e.target.checked)} />
                                        <label for="selecionarTodos" class="label-checkbox-header-simcard-2"></label>
                                    </div>
                                </OverlayTrigger> */}

                                <ReactDragListView.DragColumn
                                    onDragEnd={props.onDragEnd}
                                    nodeSelector="th"
                                    ignoreSelector="th.selection-cell-header, th.expand-cell-header"
                                >
                                    {showCaption && !spinAtivo && (
                                        <BarraTabelaLegenda
                                            setShowCaption={setShowCaption}
                                            onClick={onClickSelecaoParqueTodo}
                                            parqueTodoSelecionado={alternadorSelecao}
                                            filtros={revelarBarraFiltro}
                                            totalItens={totalItens}
                                            getTotalItensNaPagina={getTotalItensNaPagina}
                                        />
                                    )}

                                    <BootstrapTable // TABELA
                                        classes={"tabela"}
                                        condensed={true}
                                        keyField='CD_SIMCARD'
                                        data={itens}
                                        columns={props.colunas}
                                        rowEvents={eventosLinhas}
                                        selectRow={selecaoLinhas}
                                        expandRow={itemExpandido}
                                        bootstrap4={true}
                                        bordered={false}
                                        noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                        {...paginationTableProps}
                                    />
                                </ReactDragListView.DragColumn>

                                {revelarContador && 
                                    <PaginacaoTabela
                                        setPaginaAtual={setPaginaAtual}
                                        totalPaginas={totalPaginas}
                                        totalItens={totalItens}
                                        valorLimite={valorLimite}
                                        paginaAtual={paginaAtual}
                                        page={paginationProps.page}
                                        onPageChange={paginationProps.onPageChange}
                                    />
                                }
                            </>
                        )
                    }
                </PaginationProvider>

                {revelarContador ? // rodapé tabela
                    <div className="itens-exibidos-tabela fonte-cor-1">
                        Mostrando
                        <span className="label-tabela-itens-exibidos">{totalItens > 0 ? contador + 1 : 0}</span>
                        -
                        <span className="label-tabela-itens-exibidos">{getLimiteNaPagina()}</span>
                        de
                        <span className="label-tabela-itens-exibidos">{totalItens}</span>
                    </div>
                    :
                    <div className="itens-exibidos-tabela fonte-cor-1">
                        Carregando...
                    </div>
                }
            </div>
        </>
    )
}

function CarregarColunas(props) {
    let colunaFaturas;
    const [colunas, setColunas] = useState([]);
    const [render, setRender] = useState(false);
    const [colunasMongo, setColunasMongo] = useState();
    const [colunasMongoSimcards, setColunasMongoSimcards] = useState()

    const despacho = useDispatch();
    let ordenador = useSelector(state => state.manejadorState.ordenador);


    function handlerDesativarImagens(imagem, terminacao) {
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-" + terminacao)
    }

    function handleRemoverImagens(imagem) {
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador, nomeDiferenciado) {
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador + nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if (nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]) {
            await ordenador.split(' ').map((item_ordenador, i) => {
                //verifica se existe tal item e muda para decrescente
                if (item_ordenador === 'ASC') {
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador + ' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem, 'ASC')
                }
                if (item_ordenador === 'DESC') {
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        } else if (!isItDescrescente) {
            ordenador = nomeOrdenador + ' ASC'
            //Adiciona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem, 'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));
    }

    const onDragEnd = async (fromIndex, toIndex) => {
        //nao deixa colocar nas primeiras duas colunas que sao o select e o descricao de linha
        if (toIndex >= 0) {
            let columnsCopy = [];
            let clunasHidden = [];
            //retirado dois pois os dois primeiras colunas sao da linha expandida e do select
            //src  https://codesandbox.io/s/qz20y44449?file=/index.js:1290-1525
            //ORDEM NO ARRAY
            await Promise.all(
                colunas.map((item_colunas, i) => {
                    if (!item_colunas.hidden && item_colunas.hidden != true) {
                        columnsCopy.push(item_colunas)
                    }
                    else {
                        clunasHidden.push(item_colunas)
                    }
                })
            )
            //DADOS NO MONGO DA ORDEM
            let itemColunaFrom = JSON.parse(JSON.stringify(columnsCopy[fromIndex]))
            let itemColunaTo = JSON.parse(JSON.stringify(columnsCopy[toIndex]))

            //ORDEM NO ARRAY
            const item = columnsCopy.splice(fromIndex, 1)[0];
            columnsCopy.splice(toIndex, 0, item);

            //ORDEM NO ARRAY
            await Promise.all(
                clunasHidden.map((item_clunasHidden, i) => {
                    columnsCopy.push(item_clunasHidden)
                })
            )

            //ordena as colunas no mongo para poder fazer a alteracao de ordem
            // colunasMongo.sort(function (a, b) { return a.ordem - b.ordem });
            //se for movido da direita pra esquesda
            //adiciona 1 nos elementos que estiverem entre o elemento movido e onde ele foi movido
            if (itemColunaFrom.ordem > itemColunaTo.ordem) {
                await Promise.all(
                    colunasMongo.map((item_colunasMongo, i) => {
                        if (itemColunaFrom.ordem > item_colunasMongo.ordem && itemColunaTo.ordem <= item_colunasMongo.ordem) {
                            item_colunasMongo.ordem += 1
                        }
                        if (itemColunaFrom.dataField === item_colunasMongo.id) {
                            item_colunasMongo.ordem = itemColunaTo.ordem
                        }
                    })
                )
            }
            //se for movido da esquerda pra direita
            //adiciona 1 nos elementos que estiverem entre o elemento movido e onde ele foi movido so que ao contrario
            if (itemColunaFrom.ordem < itemColunaTo.ordem) {
                await Promise.all(
                    colunasMongo.map((item_colunasMongo, i) => {
                        if (itemColunaTo.ordem >= item_colunasMongo.ordem && itemColunaFrom.ordem < item_colunasMongo.ordem) {
                            item_colunasMongo.ordem -= 1
                        }
                        if (itemColunaFrom.dataField === item_colunasMongo.id) {
                            item_colunasMongo.ordem = itemColunaTo.ordem
                        }
                    })
                )
            }

            //ATUALIZA A ORDEM DAS COLUNAS
            await Promise.all(
                columnsCopy.map((item_columnsCopy, i) => {
                    colunasMongo.map((item_colunasMongo, i) => {
                        if (item_columnsCopy.dataField === item_colunasMongo.id) {
                            item_columnsCopy.ordem = item_colunasMongo.ordem
                        }
                    })
                })
            )

            //salva as alteracoes

            await api.post('/m1/servicos/dados/alterar_colunas_simcard', {
                email: decryptJWT('email'),
                colunas: colunasMongoSimcards,
                colunasFaturas: colunasMongo
            })

            setColunas(columnsCopy)
        }
    };




    useEffect(() => {
        setColunas([])
        async function checarFiltros() {
            despacho(mudarManejador("fatDispositivos"));

            await api.get("/m1/servicos/token/buscar_colunas_faturas?email=" + encodeURIComponent(decryptJWT('email')))
                .then(async function (resposta) {
                    colunaFaturas = resposta.data.dados.colunasFaturas
                    setColunasMongo(colunaFaturas)

                    let colunasSimcards = resposta.data.dados.colunas
                    setColunasMongoSimcards(colunasSimcards)

    
                    /* ATENÇÃO! Não apagar as COLUNAS comentadas. 
                        Pois se mudarem de ideia, outro dev pode demorar dias para restaurá-las.
                    */
                    const colunasTemporario = [
                        {
                            dataField: 'DS_CONTA',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("DS_CONTA", 'DS_CONTA')}>
                                Conta
                                <Image id="DS_CONTADS_CONTA" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_CONTA'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'DS_OPERADORA',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DS_OPERADORA', 'DS_OPERADORA')}>
                                Operadora
                                <Image id='DS_OPERADORADS_OPERADORA' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_OPERADORA'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'DS_FORNECEDOR',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DS_FORNECEDOR', 'DS_FORNECEDOR')}>
                                Fornecedor
                                <Image id='DS_FORNECEDORDS_FORNECEDOR' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_FORNECEDOR'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'CALLERID',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("CALLERID", 'CALLERID')}>
                                Linha
                                <Image id="CALLERIDCALLERID" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('CALLERID'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'ICCID',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('ICCID', 'ICCID')}>
                                ICCID
                                <Image id='ICCIDICCID' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('ICCID'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'DS_CICLO_VIDA',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DS_CICLO_VIDA', 'DS_CICLO_VIDA')}>
                                Ciclo de vida
                                <Image id='DS_CICLO_VIDADS_CICLO_VIDA' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_CICLO_VIDA'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'DS_PLANO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DS_PLANO', 'DS_PLANO')}>
                                Nome do plano
                                <Image id='DS_PLANODS_PLANO' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_PLANO'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'FAT_N2_VALOR_PLANO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("FAT_N2_VALOR_PLANO", 'FAT_N2_VALOR_PLANO')}>
                                Valor
                                <Image id="FAT_N2_VALOR_PLANOFAT_N2_VALOR_PLANO" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('FAT_N2_VALOR_PLANO'),
                            formatter: (cell, row) => {
                                const valor = parseFloat(cell);
                                return <p className="fonte-cor-1 label-tabela-1">R$ {(!valor || isNaN(valor)) ? '0.00' : formatarMilhar(valor)} </p>;
                            },
                        },
                        {
                            dataField: 'DATA_ATIVACAO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DATA_ATIVACAO', 'DATA_ATIVACAO')}>
                                Ativação
                                <Image id='DATA_ATIVACAODATA_ATIVACAO' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DATA_ATIVACAO'),
                            formatter: (cell, row) => {
                                return formatarData(cell, true);
                            },
                        },
                        {
                            dataField: 'DS_PEDIDO_OPERADORA',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DS_PEDIDO_OPERADORA', 'DS_PEDIDO_OPERADORA')}>
                                Pedido
                                <Image id='DS_PEDIDO_OPERADORADS_PEDIDO_OPERADORA' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_PEDIDO_OPERADORA'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'DS_NOTA_FISCAL',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DS_NOTA_FISCAL', 'DS_NOTA_FISCAL')}>
                                Nota fiscal
                                <Image id='DS_NOTA_FISCALDS_NOTA_FISCAL' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_NOTA_FISCAL'),
                            formatter: (cell, row) => {
                                return <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>{cell}</p>;
                            },
                        },
                        {
                            dataField: 'DATA_PRAZO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador('DATA_PRAZO', 'DATA_PRAZO')}>
                                Prazo do contrato
                                <Image id='DATA_PRAZODATA_PRAZO' className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DATA_PRAZO'),
                            formatter: (cell, row) => {
                                return formatarData(cell, true);
                            },
                        },
                        {
                            dataField: 'DATA_COMPRA',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("DATA_COMPRA", 'DATA_COMPRA')}>
                                Data da compra
                                <Image id="DATA_COMPRADATA_COMPRA" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DATA_COMPRA'),
                            formatter: (cell, row) => {
                                return formatarData(cell, true);
                            },
                        },
                        {
                            dataField: 'DATA_DESBLOQUEIO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("DATA_DESBLOQUEIO", 'DATA_DESBLOQUEIO')}>
                                Data do desbloqueio
                                <Image id="DATA_DESBLOQUEIODATA_DESBLOQUEIO" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DATA_DESBLOQUEIO'),
                            formatter: (cell, row) => {
                                return formatarData(cell, true);
                            },
                        },
                        {
                            dataField: 'DS_STATUS_SOLIC',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("DS_STATUS_SOLIC", 'DS_STATUS_SOLIC')}>
                                Status solicitação
                                <Image id="DS_STATUS_SOLICDS_STATUS_SOLIC" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('DS_STATUS_SOLIC'),
                            formatter: (cell, row) => {
                                let img;
                                switch (cell) {
                                    case 'Aberta':
                                        img = elipseAzul()
                                        break;
                                    case 'Concluída':
                                        img = elipseVerde()
                                        break;
                                    case 'Cancelada':
                                        img = elipseVermelho()
                                        break;
                                    case 'Em Andamento':
                                        img = elipseAmarelo()
                                        break;
                                    default:
                                        img = elipseCinza()
                                }
                                return (
                                        <p className="fonte-cor-1 label-tabela-1" key={row.CD_SIMCARD}>
                                            <Image className="img-eclipse-status" src={img} />
                                            {cell}
                                        </p>
                                )
                            },
                        },
                        {
                            dataField: 'IA_NOME',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_NOME", 'IA_NOME')}>
                                Nome
                                <Image id="IA_NOMEIA_NOME" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_NOME'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            }
                        },
                        {
                            dataField: 'IA_ENDERECO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_ENDERECO", 'IA_ENDERECO')}>
                                Endereço
                                <Image id="IA_ENDERECOIA_ENDERECO" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_ENDERECO'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            },
                        },
                        {
                            dataField: 'IA_CIDADE',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_CIDADE", 'IA_CIDADE')}>
                                Cidade
                                <Image id="IA_CIDADEIA_CIDADE" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_CIDADE'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            },
                        },
                        {
                            dataField: 'IA_UF',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_UF", 'IA_UF')}>
                                Estado
                                <Image id="IA_UFIA_UF" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_UF'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            },
                        },
                        {
                            dataField: 'IA_CEP',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_CEP", 'IA_CEP')}>
                                CEP
                                <Image id="IA_CEPIA_CEP" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_CEP'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            },
                        },
                        {
                            dataField: 'IA_OBSERVACOES',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_OBSERVACOES", 'IA_OBSERVACOES')}>
                                Informações adicionais
                                <Image id="IA_OBSERVACOESIA_OBSERVACOES" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_OBSERVACOES'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            }
                        },
                        {
                            dataField: 'IA_MODELO_DISPOSITIVO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_MODELO_DISPOSITIVO", 'IA_MODELO_DISPOSITIVO')}>
                                Modelo
                                <Image id="IA_MODELO_DISPOSITIVOIA_MODELO_DISPOSITIVO" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_MODELO_DISPOSITIVO'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            },
                        },
                        {
                            dataField: 'IA_MARCA_DISPOSITIVO',
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IA_MARCA_DISPOSITIVO", 'IA_MARCA_DISPOSITIVO')}>
                                Marca
                                <Image id="IA_MARCA_DISPOSITIVOIA_MARCA_DISPOSITIVO" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('IA_MARCA_DISPOSITIVO'),
                            formatter: (cell, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                            },
                        },
                        // {
                        //     dataField: 'IMEI',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IMEI", 'IMEI')}>
                        //         IMEI
                        //         <Image id="IMEIIMEI" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('IMEI'),
                        //     formatter: (cell, row) => {
                        //         return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                        //     },
                        // },
                        // {
                        //     dataField: 'CONSUMO_DIA_MB',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("CONSUMO_DIA_MB", 'CONSUMO_DIA_MB')}>
                        //         Consumo no dia
                        //         <Image id="CONSUMO_DIA_MBCONSUMO_DIA_MB" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('CONSUMO_DIA_MB'),
                        //     formatter: (cell, row) => {
                        //         return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell.toFixed(2) + ' MB' : '-'}</p>;
                        //     },
                        // },
                        {
                            dataField: 'SMS', //SMS_ATIVADO
                            headerClasses: 'nao-selecionavel',
                            text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("SMS", 'SMS')}>
                                Envio de SMS
                                <Image id="SMSSMS" className='d-none' src={setaDropdown()} />
                            </button>,
                            hidden: !verificarColuna('SMS'),
                            formatter: (smsAtivado, row) => {
                                return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{smsAtivado ? 'Ativo' : 'Bloqueado' }</p>;
                            },
                        },
                        // {
                        //     dataField: 'IMSI',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IMSI", 'IMSI')}>
                        //         IMSI
                        //         <Image id="IMSIIMSI" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('IMSI'),
                        //     formatter: (cell, row) => {
                        //         return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                        //     },
                        // },
                        // {
                        //     dataField: 'IP',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("IP", 'IP')}>
                        //         IP
                        //         <Image id="IPIP" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('IP'),
                        //     formatter: (cell, row) => {
                        //         return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                        //     },
                        // },
                        // {
                        //     dataField: 'NUMPACOTES',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("NUMPACOTES", 'NUMPACOTES')}>
                        //         Pacotes no ciclo
                        //         <Image id="NUMPACOTESNUMPACOTES" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('NUMPACOTES'),
                        //     formatter: (cell, row) => {
                        //         return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                        //     },
                        // },
                        // {
                        //     dataField: 'ULTIMO_EVENTO',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ULTIMO_EVENTO", 'ULTIMO_EVENTO')}>
                        //         Último Evento
                        //         <Image id="ULTIMO_EVENTOULTIMO_EVENTO" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('ULTIMO_EVENTO'),
                        //     formatter: (cell, row) => {
                        //         return formatarData(cell, false);
                        //     },
                        // },
                        // {
                        //     dataField: 'ON_OFF',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("STATUS", 'STATUS')}>
                        //         Status
                        //         <Image id="STATUSSTATUS" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('ON_OFF'),
                        //     formatter: (cell, row) => {
                        //         if (cell && cell.toLowerCase() == 'online') {
                        //             return (
                        //                 <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Inicio - {moment(row.INICIO).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>}>
                        //                     <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVerde()} /> Online</p>
                        //                 </OverlayTrigger>
                        //             )
                        //         }
                        //         else if (cell && cell.toLowerCase() == 'offline') {
                        //             return (
                        //                 <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Fim - {moment(row.FIM).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>}>
                        //                     <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseVermelho()} /> Offline</p>
                        //                 </OverlayTrigger>
                        //             )
                        //         }
                        //         else if (cell && cell.toLowerCase() == 'estoque') {
                        //             return (
                        //                 <OverlayTrigger delay={{ show: 500, hide: 250 }} placement="bottom" overlay={<Tooltip id={`tooltip-bottom`} className={'tooltip-busca'}>Estoque</Tooltip>}>
                        //                     <p className="fonte-cor-1 label-tabela-1 label-tabela-tooltip" key={row.CD_SIMCARD}><Image className="img-eclipse-status" src={elipseAzul()} /> Estoque</p>
                        //                 </OverlayTrigger>
                        //             )
                        //         }
                        //     },
                        // },
                        // {
                        //     dataField: 'TECNOLOGIA',
                        //     headerClasses: 'nao-selecionavel',
                        //     text: <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("TECNOLOGIA", 'TECNOLOGIA')}>
                        //         Tecnologia
                        //         <Image id="TECNOLOGIATECNOLOGIA" className='d-none' src={setaDropdown()} />
                        //     </button>,
                        //     hidden: !verificarColuna('TECNOLOGIA'),
                        //     formatter: (cell, row) => {
                        //         return <p className={"fonte-cor-1 label-tabela-1"} key={row.CD_SIMCARD}>{cell ? cell : '-'}</p>;
                        //     },
                        // }
                    ]

                    let ordenacaoColunas = []

                    await Promise.all(
                        colunasTemporario.map((item_colunasTemporario, i) => {
                            colunaFaturas.map((item_colunaFaturas, ij) => {
                                if (item_colunaFaturas.id == item_colunasTemporario.dataField) {
                                    item_colunasTemporario['ordem'] = item_colunaFaturas.ordem
                                    ordenacaoColunas.push(item_colunasTemporario)
                                }
                            })
                        })
                    )
                    //ordena os elementos
                    ordenacaoColunas.sort(function (a, b) { return a.ordem - b.ordem });
                    //seta as colunas
                    setColunas(ordenacaoColunas)
                    return resposta.data.dados;
                })
        }

        checarFiltros()
    }, [render]);

    function verificarColuna(id) {
        let valor = false
        colunaFaturas.map((item, i) => {
            if (item.id == id) {
                valor = item.valor;
            }
        })
        return valor
    }

    function verificarNome(id) {
        let nome = false
        colunaFaturas.map((item, i) => {
            if (item.id === id) {
                nome = item.nome;
            }
        })
        return nome
    }

    return (
        <>
            {colunas.length !== 0 && <TabelaSimcard onDragEnd={onDragEnd} colunas={colunas} render={render} setRender={setRender} conteudoBusca={props.conteudoBusca} 
            filtros={props.filtros} 
            buscaArquivo={props.buscaArquivo} 
            ordenador={props.ordenador} />}
        </>

    )
}


function mapStateToProps(state) {
    return {
        conteudoBusca: state.manejadorState.conteudoBusca,
        filtros: state.filtrosState.filtros,
        buscaArquivo: state.buscaArquivoState.buscaArquivo,
        ordenador: state.manejadorState.ordenador
    };
}

//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(CarregarColunas))