//IMPORTAÇÕES
import React, { useState, useEffect } from 'react'
import {useDispatch, useSelector, connect} from 'react-redux'
import {api} from '../../conexoes/api'
import BootstrapTable from 'react-bootstrap-table-next'
import Paginador, {PaginationProvider} from 'react-bootstrap-table2-paginator'
//COMPONENTES
import SpinerVeye from '../spinnerVeye/spinnerVeye.js'
import PaginacaoTabela from '../../utils/paginacaoTabela'
import BarraFiltragem from '../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../componentesModais/barraDadosCliente/barraDadosCliente'
import MensagemSucesso from '../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../componentesModais/mensagens/mensagemErro'
import ColetaClickstream from '../clickstreamAnalise/clickstreamColeta.js'
import {formatarData} from '../../utils/formatadorDataTabela'
import {setaDropdown} from '../../utils/alternanciaIcones'
import GerarRelatorio from './acoes/gerarRelatorio'
import { mudarPermissaoElementoAcao } from "../../redux/actions/index";
import { validadorPermissaoElemento } from '../../utils/verificadorPermissaoElemento'
//REDUX
import { 
    limpadorFiltros,
    mudarManejador,
    limpouBusca,
    realizouBusca,
    desabilitarAcoes,
    mudarFiltros,
    revelarBarraFiltragem,
    revelarDownloadRelatorio,
    realizouFiltro,
    mudarOrdenador,
} from "../../redux/actions/index"

let contador = 0
let totalPaginas = 0
let totalItens = 0
let itensVar = []

/*eslint-disable*/
function TabelaRelatorios(props){
    const [itens, setItens] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1)
    const [renderizar, setRenderizar] = useState(false)
    const valorLimite = 50
    const [arrayCdRelatorio, setarrayCdRelatorio] = useState([])
    const [arrayLinkDownloadBackup, setArrayLinkDownloadBackup] = useState([])
    const [arrayLinkDownload, setArrayLinkDownload] = useState([])
    const [refazerChamadas, setRefazerChamadas] = useState(true)
    const [linkDownload, setLinkDownload] = useState('')
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [mensagemSucesso, setMensagemSucesso] = useState(false)
    const [spinAtivo, setSpinAtivo] = useState(false)
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)
    
    const despacho = useDispatch()
    let downloadRelatorio = useSelector(state => state.relatorioState.revelarDownloadRelatorio)
    let gerarRelatorio = useSelector(state => state.relatorioState.revelarGerarRelatorio)
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca)
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca)
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca)
    let filtros = useSelector(state => state.filtrosState.filtros)
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem)
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro)
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados)
    const permissaoElemento = useSelector(state => state.permissaoState.permissaoElemento);

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(899) // 'painelRelatorioTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(()=>{
        if(props.filtros.vazio){
            despacho(revelarBarraFiltragem(false))
            if(!filtros.vazio){
                despacho(mudarFiltros({vazio: 'vazio'}))
            }
            despacho(limpadorFiltros(true))
        }
    }, [])

    useEffect(()=>{
        setSpinAtivo(true)
        despacho(mudarManejador("painelRelatorios"))
        despacho(desabilitarAcoes(true))
        
        async function carregarRelatorios(){
            if(limpouBuscaGeral){
                despacho(limpouBusca(false))
                restaurarConfigsIniciaisPagina()
            }
            
            if(realizouBuscaGeral){
                despacho(realizouBusca(false))
                restaurarConfigsIniciaisPagina()
            }
            
            if(realizouFiltragem){
                despacho(realizouFiltro(false))
                restaurarConfigsIniciaisPagina()
            }  

            contador = (paginaAtual - 1) * valorLimite
        
            if(refazerChamadas === true){
                const contagem = await api.post('/m1/consultar/relatorios',{
                    "carregarLimit": valorLimite,
                    "paginaAtual": contador,
                    "buscar": conteudoBusca,
                    "filtros": filtros,
                    "contar": true
                })
                totalPaginas = Math.ceil(contagem.data.dados[0].CONTADOR / valorLimite)
                totalItens = contagem.data.dados[0].CONTADOR
                setRefazerChamadas(false)
            }
            const data = await api.post('/m1/consultar/relatorios',{
                "carregarLimit": valorLimite,
                "paginaAtual": contador,
                "buscar": conteudoBusca,
                "filtros": filtros,
                "contar": false
            })
            inserirData(data.data.dados)
        }
        carregarRelatorios()
    },[paginaAtual, conteudoBusca, refazerChamadas])
  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i === (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }

    async function restaurarConfigsIniciaisPagina(){
        setItens([])
        setarrayCdRelatorio([])
        setArrayLinkDownload([])
        setArrayLinkDownloadBackup([])
        setRefazerChamadas(true)
        totalPaginas = 0
        contador = 0
        totalItens = 0
        itensVar = []
        setPaginaAtual(1)
    }
     
    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            despacho(revelarDownloadRelatorio(false))
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            setRenderizar(!renderizar)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    const colunas = [
        {
            dataField: 'cd_rel_relatorio',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("cd_rel_relatorio",'cd_rel_relatorio')}>
                        Código <img id="cd_rel_relatoriocd_rel_relatorio" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>
            }
        },
        {
            dataField: 'ds_rel_tipo',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_rel_tipo",'ds_rel_tipo')}>
                        Tipo de relatório <img id="ds_rel_tipods_rel_tipo" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === null || cell === undefined ? '-' : cell}</p>
            }
        },
        {
            dataField: 'ds_descricao',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_descricao",'ds_descricao')}>
                        Descrição
                        <img id="ds_descricaods_descricao" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === null || cell === undefined ? '-' : cell}</p>
            }
        },{
            dataField: 'ds_email',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_email",'ds_email')}>
                        Responsável
                        <img id="ds_emailds_email" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === null || cell === undefined ? '-' : cell}</p>
            }
        },{
            dataField: 'dt_created_at',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_created_at",'dt_created_at')}>
                        Criação <img id="dt_created_atdt_created_at" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === null || cell === undefined ? '-' : formatarData(cell)}</p>
            }
        },{
            dataField: 'dt_updated_at',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("dt_updated_at",'dt_updated_at')}>
                        Disponibilidade <img id="dt_updated_atdt_updated_at" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === null || cell === undefined ? '-' : formatarData(cell)}</p>
            }
        },{
            dataField: 'ds_rel_formato',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_rel_formato",'ds_rel_formato')}>
                        Formato <img id="ds_rel_formatods_rel_formato" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === null || cell === undefined ? '-' : '.'+cell}</p>
            }
        },{
            dataField: 'ds_tamanho',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_tamanho",'ds_tamanho')}>
                        Tamanho <img id="ds_tamanhods_tamanho" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell === null || cell === undefined ? '-' : (parseFloat(parseInt(cell)/1024).toFixed(2))+' Mb'}</p>
            }
        },{
            dataField: 'ds_link_s3',
            headerClasses: 'nao-selecionavel',
            text:   <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_link_s3",'ds_link_s3')}>
                        Download <img id="ds_link_s3" className='d-none' src={setaDropdown()} alt="img-seta-dropdow"/> 
                    </button>,
            sort: true,
            formatter: (cell, row) => {
                if(cell !== '' && cell !== null && cell !== undefined){
                    return <p className="fonte-cor-2 label-tabela-1">Disponível</p>
                }else{
                    return <p className="fonte-cor-3 label-tabela-1">Não disponível</p>
                }
            }
        },
    ]

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    const selecaoLinhas = {
        mode: 'checkbox',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                handleSelecionarItem(row.cd_rel_relatorio)
            }else{
                handleDesselecionarItem(row.cd_rel_relatorio)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if(isSelect){
                handleSelecionarTodosItens(rows)
            }else{
                handleDesselecionarTodosItens(rows)
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-simcard" { ...rest }/>
                    <label class="label-checkbox-simcard"></label>
                </>
            )
        },
        selectionHeaderRenderer: ({ mode, ...rest }) => {
            return (
                <>
                    <input type={mode} class="input-checkbox-header-simcard" { ...rest }/>
                    <label class="label-checkbox-header-simcard"></label>
                </>
            )
        }
    }

    function handleSelecionarItem(row){
        ColetaClickstream(907) // 'painelRelatorioTabelaHandleSelecionarItem'
        for (let i = 0; i < itens.length; i++) {
            if (itens[i].cd_rel_relatorio === row){
                arrayCdRelatorio.push(itens[i].cd_rel_relatorio)
                arrayLinkDownload.push(itens[i].ds_link_s3)
                arrayLinkDownloadBackup.push(itens[i].ds_link_s3)
                break
            }
        }
        despacho(desabilitarAcoes(false));
    }

    function handleDesselecionarItem(row){
        ColetaClickstream(908) // 'painelRelatorioTabelaHandleDesselecionarItem'
        for (let i = 0; i < arrayCdRelatorio.length; i++) {
            if (arrayCdRelatorio[i] === row){
                arrayCdRelatorio.splice(i, 1)
                arrayLinkDownload.splice(i, 1)
                arrayLinkDownloadBackup.splice(i, 1)
                break
            }
        }
        if(arrayCdRelatorio.length === 0){
            despacho(desabilitarAcoes(true))
        }
    }

    function handleSelecionarTodosItens(rows){
        ColetaClickstream(909) // 'painelRelatorioTabelaHandleSelecionarTodosItens'
        rows.map((item, i)=>{
            arrayCdRelatorio.push(rows[i].cd_rel_relatorio)
            arrayLinkDownload.push(rows[i].ds_link_s3)
            arrayLinkDownloadBackup.push(rows[i].ds_link_s3)
        })
        despacho(desabilitarAcoes(false))
    }

    function handleDesselecionarTodosItens(rows){
        ColetaClickstream(910) // 'painelRelatorioTabelaHandleDesselecionarTodosItens'
        rows.map((item, i)=>{
            arrayCdRelatorio.map((relatorio, x)=>{
                if(rows[i].cd_rel_relatorio === arrayCdRelatorio[x]){
                    arrayCdRelatorio.splice(x, 1)
                    arrayLinkDownload.splice(x, 1)
                    arrayLinkDownloadBackup.splice(x, 1)
                }
            })
        })
        if(arrayCdRelatorio.length === 0){
            despacho(desabilitarAcoes(true))
        }
    }

    async function handlerOrdenador(nomeOrdenador, nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador, i)=>{
                if(item_ordenador === 'ASC'){
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    ordenador = ''
                    isItDescrescente = false
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))))
    }

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    useEffect(()=>{
        if(downloadRelatorio === true){
            setSpinAtivo(true)
            async function handleBaixarRelatorio(){
                setMensagemSucesso('O download deve iniciar em alguns instantes.')
                handleMensagemSucesso()
                setLinkDownload('Iniciar')
            }
            handleBaixarRelatorio()
            setSpinAtivo(false)
        }
    },[downloadRelatorio])

    useEffect(()=> {
        let auxLinkDownload = arrayLinkDownload.length
        if(auxLinkDownload > 0){
            setLinkDownload(arrayLinkDownload[(auxLinkDownload-1)])
            arrayLinkDownload.splice((auxLinkDownload-1), 1)
        }else if(arrayLinkDownloadBackup.length > 0){
           setArrayLinkDownload([...arrayLinkDownloadBackup])
        }
        if(linkDownload !== '' && linkDownload !== 'Iniciar'){
            handleClicarParaBaixar()
        }
    },[linkDownload])

    function handleClicarParaBaixar(){
        ColetaClickstream(914) // 'painelRelatorioTabelaHandleClicarParaBaixar'
        const exportar = document.getElementById('baixar-relatorio')
        exportar.click()
    }

    useEffect(()=>{
        async function handlerVerificarPermissaoAcao(){
            despacho(mudarPermissaoElementoAcao( !await validadorPermissaoElemento(permissaoElemento,"veye/painel/relatorios/acoes") ))
        }
        handlerVerificarPermissaoAcao()
    },[])

    return(
        <div className='container-tela'>
            <a className='d-none' id='baixar-relatorio' href={linkDownload} target='_blank'/>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div>
            }
            { visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            { gerarRelatorio === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <GerarRelatorio
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            setRefazerChamadas={setRefazerChamadas}
                        >
                        </GerarRelatorio>
                    </div>
                </div>
            }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={mensagemSucesso}
                            mensagemBotao={"OK"}
                        />
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo1={"Algo deu errado. Por favor tente novamente mais tarde."}
                            mensagemParagrafo2={"Caso o erro persista, entre em contato com o suporte."}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='cd_rel_relatorio' 
                                data={itens} 
                                columns={colunas} 
                                bootstrap4={true}
                                bordered={false}
                                selectRow={selecaoLinhas}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                {...paginationTableProps}
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros }
} 

export default connect(mapStateToProps)(React.memo(TabelaRelatorios))