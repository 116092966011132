//SELECT NOVO 
import React, { useState, useEffect, Fragment } from 'react';/*eslint-disable*/
import { Image, Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";/*eslint-disable*/
import {Link, NavLink  } from "react-router-dom";/*eslint-disable*/
import {CSSTransition} from 'react-transition-group';

import {api} from './conexoes/api'
import decryptJWT from './utils/decryptJWT'
//ICONES
/** Não remova a chamada dos icones mesmo que acuse que não estão sendo usados, porque eles são chamados através do eval() na função renderModulo */
import {
    IconeFaturasVerde,
    IconeFaturas,
    menuFinanceiro,
    menuFinanceiroVerdeAtivo,
    menuSMS,
    menuSMSVerdeAtivo,
    setaBranca,
    menuPedidos,
    menuPedidosVerdeAtivo,
    dispositivo,
    dispositivoVerdeAtivo,
    olho,
    olhoVerdeAtivo,
    admin,
    adminVerdeAtivo,
    elipseVerde,
    elipseEscuro,
    elipseClaro,
    setaMenuLateral,
    teste,
    sms,
    pedidos,
    clientes,
    dashboard,
    dashboardVerdeAtivo,
    usuarios,
    usuariosVerdeAtivo,
    automacao,
    automacaoVerdeAtivo,
    relatorios,
    relatoriosAtivo,
    IconeCadastros,
    IconeSolicitacoes,
    IconeSolicitacoesVerde,
    IconeCadastroVerde,
    fornecedor,
    fornecedorVerde,
    cliente_2,
    clienteVerde_2,
    manutencao,
    manutencaoVerdeAtivo,
    menuInformacao
} from './utils/alternanciaIcones'
import { useDispatch, useSelector, connect } from 'react-redux';
import { validadorPermissaoElemento } from './utils/verificadorPermissaoElemento'
import { expandirNavegacao, expandirNavegacaoHover, imagemPerfil, mudarManejador,  limpouBusca, realizouBusca, visualizarDados, visualizarDadosRefresh, conteudoBusca, revelarAlterarLogin, mudarPermissaoElemento, 
    mudarPermissaoElementoOutrosConfiguracoesConfigurarGrupos, mudarPermissaoElementoOutrosConfiguracoesExibirColunas, mudarPermissaoElementoOutrosInformacoesConsultarCobertura,
     mudarPermissaoElementoOutrosMensagensVisualizarMensagens, mudarPermissaoElementoAcao, mudarBreadCrumbs, revelarTutorial } from "./redux/actions/index";
//ESTILIZAÇÃO
import './css/navegacaoSistema.css'
//PERFIL DO USUARIO
import DadosConta from './components/perfilUsuario/dadosConta'
import PerfilUsuario from './components/perfilUsuario/perfil'
import AlterarLogin from './components/perfilUsuario/alterarLogin'
import AlterarSenha from './components/perfilUsuario/alterarSenha'

import InfoUsuario from './components/componentesModais/infoUsuario'
import Tutoriais from './components/painelTutoriais/painelTutoriais' 

import ColetaClickstream from './components/clickstreamAnalise/clickstreamColeta.js'
import utilsLocalStorage from './utils/localStorage';
import { getTitulosPaginas } from './components/BreadCrumbs/tituloPaginas';
import ModalDetalhesVersao from './components/releaseNotes/ModalDetalhesVersao';
import { RenderMensagemInformacao } from './components/componentesModais/mensagens/mensagemInformacao.js';
import { isPortal } from './utils/nivelCliente.js';

function NavegacaoSistema(props) {
    //STATES
    const [menuTodos, setMenuTodos] = useState([])
    // const [menuModulo, setMenuModulo] = useState([])
    // const [menuTela, setMenuTela] = useState([])
    const [inProp, setInProp] = useState(false);
    const [revelarInfosUsuario, setRevelarInfosUsuario] = useState(false)
    // const [menuState, setMenuState] = useState(false)
    const [menuExpandido, setMenuExpandido] = useState(true)
    const [menuBarraRecolhida, setMenuBarraRecolhida] = useState(false)
    const [telaAtiva, setTelaAtiva] = useState(window.location.href.split('/')[3]+"/"+window.location.href.split('/')[4]+"/"+window.location.href.split('/')[5])
    const [telaFilhaAtiva, setTelaFilhaAtiva] = useState(window.location.href.split('/')[3]+"/"+window.location.href.split('/')[4]+"/"+window.location.href.split('/')[5])
    const [elementosAtivos, setElementosAtivos] = useState(0)
    const [elementosAtivosCor, setElementosAtivosCor] = useState(0)
    const [idElementoCor, setIdElementoCor] = useState(0)
    const [arrayRenderModulos, setArrayRenderModulos] = useState([])
    const [arrayRenderTelas, setArrayRenderTelas] = useState([])
    const [arrayRenderTelasFilhos, setArrayRenderTelasFilhos] = useState([])
    const [renderizar, setRenderizar] = useState(false)
    const [clienteNivel, setClienteNivel] = useState(decryptJWT('clienteNivel'))
    const [clientePrimario, setClientePrimario] = useState(decryptJWT('clientePrimario'))
    const [carregando, setCarregando] = useState(false)
    const [parceiro, setParceiro] = useState('Veye')
    const [logoParceiro, setLogoParceiro] = useState()
    const [parceiroData, setParceiroData] = useState([{}]);
    const [revelarMenuReleaseNotes, setRevelarMenuReleaseNotes] = useState(false)
    const [revelarMensagemInformacao, setRevelarMensagemInformacao] = useState(false)
    const [contadorNovidadesNaoLidas, setContadorNovidadesNaoLidas] = useState(0)

    const localTema = localStorage.getItem('tema')

    let imagem = useSelector(state => state.manejadorState.imagemPerfil);
    const permissaoConsultarCobertura = useSelector(state => state.permissaoState.permissaoElementoOutrosInformacoesConsultarCobertura);
    let revelarMenuTutorial = useSelector(state => state.manejadorState.revelarTutorial) 
    let atualizarAlertaCabecalho = useSelector(state => state.mensagensState.atualizarAlertaCabecalho);
    const manejador = useSelector(state => state.manejadorState.manejador)

    const portalCliente = isPortal();

    const isModuloInformacoes = (modulo) => modulo.cdMenu === 427;

    function renderIcones(icone) {
        switch(icone) {
            case 'dispositivos': return dispositivo(); 
            case "admin": return admin();
            case "manutencao": return manutencao();
            case "teste": return teste();
            case "virtueyes": return olho();
            case "financeiro": return menuFinanceiro();
            case "pedidos": return menuPedidos();
            case "sms": return menuSMS();
            case "sms": return sms();
            case "pedidos": return pedidos();
            case "clientes": return clientes();
            case "dashboard": return dashboard();
            case "usuarios": return usuarios();
            case "automacao": return automacao();
            case "relatorios": return relatorios();
            case "faturas": return IconeFaturas();
            case "cadastros": return IconeCadastros();
            case "solicitacoes": return IconeSolicitacoes();
            case "fornecedores": return fornecedor();
            case "clientes_2": return cliente_2();
            case "info": return menuInformacao();
            
            default: return false;
        }
    }

    function renderIconesVerdes(icone) { 
        switch(icone) {
            case 'dispositivos': return dispositivoVerdeAtivo();
            case "admin": return adminVerdeAtivo();
            case "manutencao": return manutencaoVerdeAtivo();
            case "virtueyes": return olhoVerdeAtivo();
            case "financeiro": return menuFinanceiroVerdeAtivo();
            case "pedidos": return menuPedidosVerdeAtivo();
            case "sms": return menuSMSVerdeAtivo();
            case "dashboard": return dashboardVerdeAtivo();
            case "automacao": return automacaoVerdeAtivo();
            case "usuarios": return usuariosVerdeAtivo();
            case "relatorios": return relatoriosAtivo();
            case "faturas": return IconeFaturasVerde();
            case "cadastros": return IconeCadastroVerde();
            case "solicitacoes": return IconeSolicitacoesVerde();
            case "fornecedores": return fornecedorVerde();
            case "clientes_2": return clienteVerde_2();
            case "info": return menuInformacao();

            default: return false;
        }
    }

    async function getListaParceiros(parceiro){
        try{
            let parceiroObj = await api.get('/m1/servicos/pre_login_cliente_parceiro?rota='+parceiro)
            return parceiroObj.data
        } catch (e) {
            console.error('erro ao acessar a rota cliente_parceiro', e)
            return []
        }


    }

    useEffect(() => {

        let parceiroData
        getListaParceiros(window.location.host).then((response) => {
            parceiroData = response;
            if (parceiroData.dados) {
                setParceiroData(parceiroData.dados);
                setParceiro(parceiroData.dados.NomeDoParceiro);
                setLogoParceiro(parceiroData.dados.Logo);
            } else {
                setParceiro('Veye')
            }
            setCarregando(true);
        })

    }, [])

    const despacho = useDispatch();
    let estadoExpansaoNavegacao = useSelector(state => state.manejadorState.expandirNavegacao);
    const menuLateralExpandido = estadoExpansaoNavegacao === true

    let tooltip = ''

    despacho(expandirNavegacaoHover(menuExpandido))

    if(menuLateralExpandido) {
        tooltip = 'tooltip-menu-navegacao'
    } else {
        tooltip = 'tooltip-menu-navegacao-2'
    }


    function expandeMenu() {
        if(estadoExpansaoNavegacao === false && menuExpandido === true && menuBarraRecolhida === false) {
            despacho(expandirNavegacao(!estadoExpansaoNavegacao))
            setMenuExpandido(false)
            setMenuBarraRecolhida(true)
        }        
    }

    function recolheMenu() {
        if(menuLateralExpandido && menuExpandido === false && menuBarraRecolhida === true) {
            despacho(expandirNavegacao(!estadoExpansaoNavegacao))
            setMenuExpandido(true)
            setMenuBarraRecolhida(false)
        }
    }

    function handleBeforeClick(telaAtiva,cdTelaAtiva,tela){
        if(tela === 'modulo'){
            handleDropTelas("modulo-" + cdTelaAtiva, 'modulo')

        }
        if(tela === 'tela'){
            handleDropFilhoTelas('tela-'+cdTelaAtiva,telaAtiva,'tela') // quando clica em uma tela dentro de um módulo
        }
        setTelaAtiva(telaAtiva)
        despacho(conteudoBusca(''));
        despacho(limpouBusca(true));
    }
    
    function handleBeforeClickTelaFilha(telaAtiva){
        despacho(conteudoBusca(''))
        despacho(limpouBusca(true))
        despacho(mudarManejador(""));
        setTelaFilhaAtiva(telaAtiva)
    }
    
    useEffect(()=>{
        async function renderModulo() {
            setRenderizar(false)
            let objetoTela = []
            let objetoTelaFilhos = []
            let objetoModulo = []

            if((imagem === '' || imagem === null) || !utilsLocalStorage.getUsuarioNomeCompleto()){
                await api.get("/m1/consultar/usuario?cd_pav_usuario="+ decryptJWT('codigo'))
                .then((resolve)=>{
                    if(resolve.data.status === 200 && resolve.data.dados.length != 0){
                        const objDados = resolve.data.dados[0];
                        utilsLocalStorage.setUsuarioNomeCompleto(objDados);
                        if(objDados.bl_imagem != null && objDados.bl_imagem != ''){
                            despacho(imagemPerfil(objDados.bl_imagem))
                            imagem = objDados.bl_imagem
                        }
                    }
                }).catch(function(error){
                    console.error(error)
                })                
            }

            const pegarMenus = await api.post('/m1/servicos/dados/permissao', {
                'id': decryptJWT("id")
            })

            if (pegarMenus.data.dados) {
                setMenuTodos(pegarMenus.data.dados)
                despacho(mudarBreadCrumbs(getTitulosPaginas(pegarMenus.data.dados)))
                // setMenuModulo(pegarMenus.data.dados[1])
                // setMenuTela(pegarMenus.data.dados[2])


                const elementos = JSON.parse(JSON.stringify(pegarMenus.data.dados[3])) 
                despacho(mudarPermissaoElemento(elementos));

                despacho(mudarPermissaoElementoOutrosConfiguracoesExibirColunas( await validadorPermissaoElemento(elementos,"veye/outros/configuracoes/exibirColunas") ));
                despacho(mudarPermissaoElementoOutrosConfiguracoesConfigurarGrupos( await validadorPermissaoElemento(elementos,"veye/outros/configuracoes/configurarGrupos") ));
                despacho(mudarPermissaoElementoOutrosInformacoesConsultarCobertura( await validadorPermissaoElemento(elementos,"veye/outros/informacoes/consultarCobertura") ));
                despacho(mudarPermissaoElementoOutrosMensagensVisualizarMensagens( await validadorPermissaoElemento(elementos,"veye/outros/mensagens/visualizarMensagens") ));
            }
            
            function renderTelaFilho(cdTela){
                objetoTelaFilhos = pegarMenus.data.dados[2].filter(tela => tela.menuPai == cdTela  && tela.n0_nivel >= clienteNivel).map(tela =>{
                    const key = "telaFilha-" + tela.cdMenu;
                    return (
                    <CSSTransition timeout={500} classNames="my-node" in={inProp} key={key}>
                        <li key={key}>
                        <OverlayTriggerX {...{tooltip, label: tela.label}}>
                            <NavLink onClick={()=> handleBeforeClickTelaFilha(tela.rota)} to={"/" + tela.rota} className={telaFilhaAtiva==tela.rota ? "menu-expandido-item-filho-3 menu-expandido-item-tela-filho row" : "menu-expandido-item-filho-2 menu-expandido-item-tela-filho row"}>
                                <Image key='1' className={menuLateralExpandido?"float-left icone-menu-tela-filho-1":"float-left icone-menu-tela-filho-2"} src={telaFilhaAtiva==tela.rota?elipseVerde():elipseEscuro()} />
                                <span className={telaFilhaAtiva==tela.rota?"col menu-texto-selecionado fonte-cor-2 float-left":"col menu-texto float-left"}>{tela.label}</span>
                            </NavLink>
                        </OverlayTriggerX>    
                        </li>
                    </CSSTransition>
                )})
                return objetoTelaFilhos
            }

            function handleRevelarModal(rota) { 
                const auxAcaoMostrarModal = rota.replace('veye/info/', '')
                switch(auxAcaoMostrarModal) {
                    case 'revelarModalTutoriais': 
                        despacho(revelarTutorial(true))
                        break;
                    case 'revelarModalFAQ': 
                        handleMensagemInformacao(false)
                        break;
                    case 'revelarModalNovidades': 
                        revelarModalNovidades()
                        break;
                    default:
                        break
                }
            }

            function revelarModalNovidades() {
                setRevelarMenuReleaseNotes(true)
            }

            function renderTela(cdModulo){
                const SpanMenuTexto = ({tela}) => <span className={telaAtiva==tela.rota?"col menu-texto-selecionado fonte-cor-2 float-left":"col menu-texto float-left"}>{tela.label}</span>;
                const IconeMenuFilhoElipse = ({tela}) => <Image key='2' className="float-left icone-menu-filho" src={telaAtiva==tela.rota?elipseVerde():elipseClaro()} />

                objetoTela = pegarMenus.data.dados[2].filter(tela => tela.menuPai == cdModulo  && tela.n0_nivel >= clienteNivel).map(tela => {
                    const idTela = "tela-" + tela.cdMenu;

                    function LinkPagina() {
                        const rota = tela.rota;
                        const className = telaAtiva==rota?"mouse-hover menu-expandido-item-filho-3  menu-expandido-item-filho row nao-selecionavel menu-expandido-item-filho-borda":"mouse-hover menu-expandido-item-filho row nao-selecionavel";

                        const IconeTextoTela = () => (
                            <Fragment key={tela.rota}>
                                <IconeMenuFilhoElipse tela={tela} key='2' />
                                <SpanMenuTexto tela={tela} />
                            </Fragment>
                        )

                        if(rota?.includes('http')) { // link externo
                            if(!permissaoConsultarCobertura) return null;

                            const linkExterno = rota.replace('veye/', '')
                        
                            return <a 
                                rel="noreferrer" 
                                key={tela.rota}
                                href={linkExterno}
                                target="_blank"
                                className={className}
                            >
                                <IconeTextoTela />
                            </a>
                    
                        } else if(rota?.includes('veye/info')) { // Informações
                                return <div 
                                        key={tela.rota}
                                        onClick={() => handleRevelarModal(rota)}
                                        className={className}
                                    >
                                        <IconeTextoTela />
                                        {contadorNovidadesNaoLidas > 0 && rota?.includes('Novidade') &&
                                            <RedBadge />
                                        }
                                    </div>
                        }
                        else {
                            return ( // tela normal
                                <NavLink
                                    key={tela.rota} 
                                    onClick={(e)=> handleBeforeClick(rota,tela.cdMenu,"tela")} 
                                    to={"/" + rota} 
                                    className={className}
                                >
                                    <IconeTextoTela />
                                </NavLink>
                            )
                        }
                    }

                    return (
                    <CSSTransition timeout={500} classNames="my-node" in={inProp} key={idTela}>
                        {tela.filho === 0?
                            <li id={idTela} key={idTela}> 
                                <OverlayTriggerX {...{tooltip, label: tela.label}}>
                                    <LinkPagina />
                                </OverlayTriggerX>
                            </li>
                        :
                        <li key={idTela}>
                            <OverlayTriggerX {...{tooltip, label: tela.label}}>
                                <div className={telaAtiva==tela.cdMenu ? "mouse-hover menu-expandido-item-filho row nao-selecionavel" : "mouse-hover menu-expandido-item-filho row nao-selecionavel"} onClick={e => handleDropFilhoTelas('tela-'+tela.cdMenu,tela.rota)}>
                                    <IconeMenuFilhoElipse tela={tela} key='3' />
                                    <SpanMenuTexto tela={tela} />
                                    <Image key='4' id={tela.rota} className="float-right icone-menu-com-filho fonte-cor-1 campo-select-icone-desativado" src={setaMenuLateral()} />
                                </div>
                            </OverlayTriggerX>
                                    <ul id={idTela} className="dropdown-desativado-tela">
                                        {renderTelaFilho(tela.cdMenu)}
                                    </ul>
                            </li>
                        }
                    </CSSTransition>
                )})
                return objetoTela
            }

            objetoModulo = pegarMenus.data.dados[1].filter(modulo => modulo.menuPai !== null && modulo.cdMenu != 136 && modulo.n0_nivel >= clienteNivel).map(modulo => {
                const classIconeMenuInvisivel = "float-left icone-menu-invisivel";
                const classIconeMenu = "float-left icone-menu";
                const strModulo = "modulo-" + modulo.cdMenu;
                const classModuloDiv = (strModulo + '-div');

                const imgClass1 = idElementoCor === classModuloDiv?classIconeMenu:classIconeMenuInvisivel;
                const imgClass2 = idElementoCor === classModuloDiv?classIconeMenuInvisivel:classIconeMenu;

                const toRoute = "/" + modulo.rota;

                const SpanX = () => <span to={toRoute}  className="col menu-texto float-left">{modulo.label}</span>
                const idNavLink = strModulo + "-div";
                const classNavLink = "menu-expandido-item row nao-selecionavel";

                if(isModuloInformacoes(modulo) && portalCliente) return null;

                const Images = ({modulo}) => (
                    <Fragment  key={modulo.icone}
                    >
                        <Image key='5' className={imgClass1} src={renderIconesVerdes(modulo.icone)}/>
                        <Image key='6' className={imgClass2} src={renderIcones(modulo.icone)}/>
                        <SpanX />
                    </Fragment>
                )

                return (
                <>
                    {modulo.filho === 0?
                        <li key={strModulo}>
                            <OverlayTriggerX {...{tooltip, label: modulo.label}}>
                            <NavLink id={idNavLink} onClick={(e)=> handleBeforeClick(modulo.rota,modulo.cdMenu,'modulo')} to={toRoute} className={classNavLink}>
                                <Images modulo={modulo} />
                            </NavLink>
                            </OverlayTriggerX>
                        </li>
                    :
                        <li key={strModulo}>
                            {modulo.acionar === 0?
                            <OverlayTriggerX {...{tooltip, label: modulo.label}}>
                                <div id={idNavLink} className={classNavLink} onClick={e => handleDropTelas(strModulo)}>
                                    <Images modulo={modulo} />

                                    {isModuloInformacoes(modulo) && contadorNovidadesNaoLidas > 0 &&
                                        <RedBadge />
                                    }
                                </div>
                            </OverlayTriggerX>
                            :
                                <OverlayTriggerX {...{tooltip, label: modulo.label}}>
                                <NavLink id={idNavLink} onClick={(e)=> {handleBeforeClick(modulo.rota,modulo.cdMenu,'modulo'); handleDropTelas(strModulo)}} to={toRoute} className={classNavLink}>
                                    <Images modulo={modulo} />
                                </NavLink>
                                </OverlayTriggerX>
                            }
                            <ul id={strModulo} className="dropdown-desativado nao-selecionavel">
                                {renderTela(modulo.cdMenu)}
                            </ul>
                        </li>
                    }
                </>
            )})

            setArrayRenderModulos(objetoModulo)
            setArrayRenderTelasFilhos(objetoTelaFilhos)
            setArrayRenderTelas(objetoTela)
            // if(props.imagensTema.img_nav_1 === 'none' || props.imagensTema.img_nav_2 === 'none' || props.imagensTema.img_nav_1 === undefined || props.imagensTema.img_nav_2 === undefined){
                // props.handleAtualizar()
            // }
        }
        renderModulo()
    },[renderizar, localTema, estadoExpansaoNavegacao])

    function OverlayTriggerX({tooltip, label, children}) {
        return (
            <OverlayTrigger 
                delay={{ show: 500, hide: 250 }} 
                placement="right" 
                overlay={<ToolTipX {...{tooltip, label}} />}
            >
                {children}
            </OverlayTrigger>
        )
    }

    const ToolTipX = ({tooltip, label}) => <Tooltip className={tooltip} id={'tooltip-right'}>{label}</Tooltip>

    function escondeDropdown(el){
        ColetaClickstream(934) // 'cabecalhoSistemaEscondeDropdown'
        el.classList.remove("dropdown-ativado");
        el.classList.add("dropdown-desativado");
    }

    function escondeDropdownTela(el, imagemId){
        ColetaClickstream(935) // 'cabecalhoSistemaEscondeDropdownTela'
        el.classList.remove("dropdown-ativado-tela");
        el.classList.add("dropdown-desativado-tela");
    }

    function removerCorAtivado(el){
        el.classList.remove("dropdown-ativado-cor");
    }

    useEffect(()=>{
        if(idElementoCor){
            let divCor = document.getElementById(idElementoCor);
            divCor.classList.add("dropdown-ativado-cor")
        }
        if(elementosAtivosCor.length > 0){
            elementosAtivosCor.forEach(removerCorAtivado)
        }
        if(elementosAtivos.length > 0){
            elementosAtivos.forEach(escondeDropdown)
        }
    },[idElementoCor, elementosAtivos, elementosAtivosCor])

    function handleMensagemInformacao(confirmacao){
        if(revelarMensagemInformacao === false){
            setRevelarMensagemInformacao(true)
        }else{
            setRevelarMensagemInformacao(false)
        }
        // if(confirmacao === true ){}
    }

    function handleDropTelas(elementoId, moduloTela) {
        ColetaClickstream(937) // 'navegacaoSistemaHandleDropTelas'
        let elemento = document.getElementById(elementoId);
        let elementosAtivos = document.querySelectorAll(".dropdown-ativado-tela");

        if(elementosAtivosCor.length > 0){
            elementosAtivosCor.forEach(removerCorAtivado)
        }
        if(elementosAtivos.length > 0){
            elementosAtivos.forEach(escondeDropdown)
        }

        setElementosAtivos(document.querySelectorAll(".dropdown-ativado"))
        setElementosAtivosCor(document.querySelectorAll(".dropdown-ativado-cor"))
        setIdElementoCor(elementoId + "-div")
        
        if(moduloTela === undefined){
            elemento.classList.toggle("dropdown-ativado")
        }

         if(moduloTela != undefined){
             handleDropFilhoTelas(elementoId, moduloTela, moduloTela )
        }
    }

    function handleDropFilhoTelas(elementoId,telaAtiva,tela) {
        setTelaAtiva(telaAtiva)
        let elementosAtivos = document.querySelectorAll(".dropdown-ativado-tela");
        let imagemClasses = document.querySelectorAll('.campo-select-icone-ativado');

        let elemento = document.getElementById(elementoId);
        let imagem = document.getElementById(telaAtiva)
        
        if(tela == undefined){
            elemento.classList.toggle("dropdown-ativado-tela")

            if(elemento.classList.contains("dropdown-desativado-tela")){
                elementosAtivos.forEach(item => item.classList.toggle("dropdown-desativado-tela"));
                elementosAtivos.forEach(item => item.classList.toggle("dropdown-ativado-tela"));
                imagem.classList.toggle("campo-select-icone-desativado")
                imagem.classList.toggle("campo-select-icone-ativado")
            }
        }
        else{
            setTelaFilhaAtiva('')
            imagemClasses.forEach(item => item.classList.toggle("campo-select-icone-desativado"));
            imagemClasses.forEach(item => item.classList.toggle("campo-select-icone-ativado"));
        }
        if(elementosAtivos.length > 0){
            elementosAtivos.forEach(escondeDropdownTela)
        }
    }

    useEffect(()=>{
        //reseta a permissao de acoes
        despacho(mudarPermissaoElementoAcao(false))
    })

    useEffect(() => {
        async function handleCarregarPermissoes() {
           
        }
        handleCarregarPermissoes();
    }, [/* props.renderSistema */]);


    function ContainerModalAcao({children}) {
        return (
            <div className="componente-modal-overlay">
                <div className="componente-modal">
                    {children}
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (!manejador) return;
        buscaNovidadesNaoLidas()
    }, [manejador, atualizarAlertaCabecalho ]);

    async function buscaNovidadesNaoLidas() {
        // TODO: Como o 'Informações' foi movido para o menu lateral, 
            // verificar se faz sentido mover isso também + useEffect acima para "navegacaoSistema.js"
        
        const { data } = await api.get('/m1/consultar/usuario_versionamento');
        let lastReleaseNoteRead;
        let lastReleaseNotes;

        if (data?.status == 200) {
            const auxDadosNovidades = data?.dados;
            if (auxDadosNovidades.length > 0) {
                const ultimaNovidade = auxDadosNovidades[0];
                lastReleaseNoteRead = ultimaNovidade.cd_pav_release_ultima_lida;
                lastReleaseNotes = ultimaNovidade.cd_pav_release;
                localStorage.setItem('releaseNotes', JSON.stringify({ lastReleaseNotes, lastReleaseNoteRead }));
            }
            // setNovidadesNaoLidas(auxDadosNovidades)

            if (lastReleaseNotes > lastReleaseNoteRead) {
                setContadorNovidadesNaoLidas(auxDadosNovidades?.length) // mostrar alerta 
            } else { // não mostrar alertas
                if (contadorNovidadesNaoLidas > 0) {
                    setContadorNovidadesNaoLidas(0);
                }
            }
        }
    }

    return (
        <>
            { carregando ?
                <div className="fundo-cor-5">
                    { props.revelarDadosContaUsuario &&
                        <ContainerModalAcao>
                                <DadosConta/>
                        </ContainerModalAcao>
                    }
                    {props.revelarPerfilContaUsuario &&
                        <ContainerModalAcao>
                                <PerfilUsuario/>
                        </ContainerModalAcao>
                    }
                    {props.revelarAlterarLogin &&
                        <ContainerModalAcao>
                                <AlterarLogin/>
                        </ContainerModalAcao>
                    }
                    {props.revelarAlterarSenha &&
                       <AlterarSenha/>
                    }

                    {revelarMensagemInformacao &&
                        <RenderMensagemInformacao
                            handleMensagemInformacao={handleMensagemInformacao}
                            mensagemParagrafo1={"Caro cliente, para melhor atende-lo, nosso FAQ está em processo de contrução, em breve teremos mais novidades."}
                            mensagemBotao={"FECHAR"}
                        />
                    }

                    {revelarMenuTutorial === true &&
                        <div className="componente-modal-overlay-tutorial">
                            <div className="componente-modal">
                                <Tutoriais /> 
                            </div>
                        </div>
                    }

                    {revelarMenuReleaseNotes === true &&
                        <div className="componente-modal-overlay-novidades">
                            <div className="componente-modal">
                                <ModalDetalhesVersao setRevelarMenuReleaseNotes={setRevelarMenuReleaseNotes} />
                            </div>
                        </div>
                    }

                    <div className={menuLateralExpandido?clientePrimario == 2455? "container-navegacao-sistema-algar":"container-navegacao-sistema-1":"container-navegacao-sistema-2"}>
                        <nav className="nav" onMouseEnter={() => expandeMenu()} onMouseLeave={() => recolheMenu()}>
                            <Link to="/">
                                <>
                                    {clientePrimario == 2455 ?
                                        <div className="container-logo-veye container-logo-veye-2">
                                            <div className='container-logo-algar' onClick={() => window.location.href = 'https://iot.algartelecom.com.br/%27'}>
                                                <img alt="logo-algar" to="/" href="/" className={menuLateralExpandido?"logo-algar-1":"logo-algar-2"} src={props.imagensTema.img_nav_1} />
                                                <img alt="logo-algar-default"  to="/" href="/" className={menuLateralExpandido?"logo-algar":"logo-algar-default"} src={props.imagensTema.img_nav_2} />
                                            </div>
                                        </div>
                                        :clientePrimario == 1 ?
                                            <div>
                                                { parceiro === 'Veye' ?
                                                    <div className="container-logo-veye">
                                                        <img alt="logo-vityueyes"  to="/" href="/" className={menuLateralExpandido?"logo-virtueyes-simbolo-1":"logo-virtueyes-simbolo-2"} src={props.imagensTema.img_nav_1}/>
                                                        <img alt="logo-vityueyes-palavra" to="/" href="/" className={menuLateralExpandido?"logo-virtueyes-palavra-1":"logo-virtueyes-palavra-2"} src={props.imagensTema.img_nav_2}/>
                                                    </div>
                                                    :
                                                    <div className="container-logo-veye">
                                                        <img  alt="logo-palavra-2" to="/" href="/"
                                                             className={menuLateralExpandido ? `logo-parceiro-1` : "logo-virtueyes-palavra-2"}
                                                             src={parceiroData.link_logo_escuro && localTema === 'claro' ? logoParceiro : parceiroData.link_logo_escuro}/>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div className="container-logo-veye container-logo-veye-2">
                                                <img alt="logo-experience-1" to="/" href="/" className={menuLateralExpandido ? "logo-navegacao-experience-1-1" : "logo-navegacao-experience-1-2"} src={props.imagensTema.img_nav_1} />
                                                <img alt="logo-experience-2" to="/" href="/" className={menuLateralExpandido ? "logo-navegacao-experience-2-1" : "logo-navegacao-experience-2-2"} src={props.imagensTema.img_nav_2} />
                                                <img alt="logo-experience-3" to="/" href="/" className={menuLateralExpandido ? "logo-navegacao-experience-3-1" : "logo-navegacao-experience-3-2"} src={props.imagensTema.img_nav_3} />
                                            </div>
                                    }
                                </>
                            </Link>
                            <div className={menuLateralExpandido?"container-usuario-logado":"container-usuario-logado-2"}>
                                {imagem != null && imagem != ''?
                                    <IconeUsuarioLogado src={imagem} menuLateralExpandido={menuLateralExpandido} />
                                    :
                                    <IconeUsuarioLogado src="https://jazzaero.com.br/wp-content/uploads/2017/05/default-placeholder-profile-icon-avatar-gray-woman-90197997.jpg" alt="perfil" menuLateralExpandido={menuLateralExpandido} />
                                }
                                <div className={menuLateralExpandido?"container-dropdown-perfil-cabecalho-sistema" : "container-dropdown-perfil-cabecalho-sistema-2"} />
                                <div className={menuLateralExpandido?"container-usuario-logado-dados":"container-usuario-logado-dados-2"}>
                                    <div className={menuLateralExpandido?"usuario-logado-dados-nome-1":"usuario-logado-dados-nome-2"}><div className="fonte-cor-1">{decryptJWT('nomeDeUsuario')}</div></div>
                                    <p className={menuLateralExpandido?"usuario-logado-dados-email-1":"usuario-logado-dados-email-2"}>{decryptJWT('email')}</p>
                                </div>
                                {revelarInfosUsuario === false &&
                                    <Button className={menuLateralExpandido ? "botao-expandir-infos-usuario" : "botao-expandir-infos-usuario-2" } onClick={() => setRevelarInfosUsuario(true)}>
                                        <Image key='11' className="icone-botao-expandir-infos-usuario-desativado" src={setaBranca()} />
                                    </Button>
                                }
                                {revelarInfosUsuario === true &&
                                    <InfoUsuario setRevelarInfosUsuario={setRevelarInfosUsuario} />
                                }
                            </div>
                            <div className={menuLateralExpandido?"container-menus-navegacao":"container-menus-navegacao-1"}>
                                <ul className="menu-links" onClick={e => setRenderizar(!renderizar)}>
                                    {arrayRenderModulos}
                                </ul>
                            </div>
                            {
                                    <div className='powered-virtueyes-container'>
                                        <div className='fonte-cor-1 powered-virtueyes-texto'>Powered by</div>
                                        <img alt="powered-virtueyes" className='powered-virtueyes-imagem' src={props.imagensTema.img_nav_2}/>
                                    </div>
                            }
                        </nav>
                    </div>
                </div>
                :
                <div></div>
            }
        </>


    )
}

const IconeUsuarioLogado = ({menuLateralExpandido, src, alt=''}) => <img className={menuLateralExpandido?"icone-usuario-logado-1":"icone-usuario-logado-2"} src={src} alt={alt} />

const RedBadge = () => (
    <Badge variant="danger" className="badge-novidade-menu-lateral">
    !
    </Badge>
)

function mapStateToProps(state) {
    return { visualizarDados: state.virtueyesState.visualizarDados,
            revelarDadosContaUsuario: state.virtueyesState.revelarDadosContaUsuario,
            revelarPerfilContaUsuario: state.virtueyesState.revelarPerfilContaUsuario,
            revelarAlterarLogin: state.virtueyesState.revelarAlterarLogin,
            revelarAlterarSenha: state.virtueyesState.revelarAlterarSenha,};
}

export default connect(mapStateToProps)(NavegacaoSistema);
