//IMPORTAÇÕES
import React,{useState, useEffect} from 'react';
import {api} from '../../../conexoes/api';/*eslint-disable*/
import {Image} from 'react-bootstrap';/*eslint-disable*/
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador,{PaginationProvider} from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
//IMPORTAÇÕES ICONES
import { setaDropdown } from '../../../utils/alternanciaIcones'
//IMPORTAÇÕES REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { revelarDoppelgangerCliente, visualizarDadosRefresh, revelarMensagemAtencao,mudarOrdenador, mudarManejador, desabilitarAcoes, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, revelarExcluirCicloVida as revelarExcluirCicloVidaFunc,realizouFiltro, } from "../../../redux/actions/index";
//IMPORTAÇÕES COMPONENTES
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import NovaCiclo from './novoCiclo/novoCiclo'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
//ESTILIZAÇÃO
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'
import '../../../css/painelFaturas/fatCicloVida/tabela.css'

const valorLimite = 3
let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaClientesVirtueyes(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);  
    const [flPadrao, setFlPadrao] = useState(0);

    const [cdCicloVida, setCdCicloVida] = useState(-1);
    const [status, setStatus] = useState()
    const [statusOperadora, setStatusOperadora] = useState()
    const [statusInicial, setStatusInicial] = useState()
    const [statusAtivo, setStatusAtivo] = useState()
    const [fatura, setFatura] = useState()
    const [automacao, setAutomacao] = useState()
    const [cancelamento, setCancelamento] = useState()

    const [mensagemErro, setMensagemErro] = useState('')
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemAtencaoCicloPadrao, setRevelarMensagemAtencaoCicloPadrao] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    let itensVar = []

    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let estadoVisualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let revelarNovoCiclo =  useSelector(state => state.faturasState.revelarNovoCicloVida);
    let revelarEditarCiclo =  useSelector(state => state.faturasState.revelarEditarCicloVida);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let revelarExcluirCicloVida = useSelector(state => state.faturasState.revelarExcluirCicloVida);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDadosRefreshVar = useSelector(state => state.virtueyesState.visualizarDadosRefresh);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let temFiltros = false;
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);

    const despacho = useDispatch();
    const cd_pav_usuario = decryptJWT('codigo')
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(729) // 'fatCicloVidaTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio' || filtros.vazio === undefined) {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("faturasCicloVida"));
        despacho(desabilitarAcoes(true));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            } 

            if(ordenador){
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fat_ciclo_vida?paginaAtual='+contador+'&ordenador='+ordenador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+"&cd_pav_usuario="+cd_pav_usuario);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    filtros: filtros,
                    ordenador: ordenador,
                    cd_pav_usuario: cd_pav_usuario
                }
                if (controladorPagina <= paginaAtual) {
                    
                    const { data } = await api.post("/m1/filtrar/fat_ciclo_vida", dados);

                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fat_ciclo_vida?paginaAtual='+contador+'&ordenador='+ordenador+'&carregarLimit='+valorLimite+"&cd_pav_usuario="+cd_pav_usuario);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador])

    function inserirData(data){

        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }

    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual && filtros.vazio) {
            apiData = await api.get('/m1/consultar/fat_ciclo_vida?contar=true&buscar='+conteudoBusca+'&ordenador='+ordenador+"&cd_pav_usuario="+cd_pav_usuario);
        }else{
            const dados = {
                paginaAtual: contador,
                carregarLimit: valorLimite,
                buscar:conteudoBusca,
                filtros: filtros,
                contar: true,
                ordenador,
                cd_pav_usuario: cd_pav_usuario
            }
            
            apiData = await api.post("/m1/filtrar/fat_ciclo_vida", dados);
        }
        totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
        totalItens = apiData.data.dados[0].CONTADOR;
        for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
            arrayItens.push(i)
        }
        itensVar = arrayItens
    }

    const colunas = [

        {
            dataField: 'CD_CICLO_VIDA',
            text: 'ID',
            headerClasses: 'nao-selecionavel',
            hidden: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
        {
            dataField: 'DS_STATUS',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_status",'ds_status')}>
            Status
            <Image id="ds_statusds_status" className='d-none' src={setaDropdown()}/>
            </button>,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : -1}</p>;
            }
        },
        {
            dataField: 'DS_STATUS_OPERADORA',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_status_operadora",'ds_status_operadora')}>
            Status operadora
            <Image id="ds_status_operadorads_status_operadora" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? cell : "-"}</p>;
            }
        },
        {
            dataField: 'FL_INICIAL',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("fl_status_inicial",'fl_status_inicial')}>
            Status inicial
            <Image id="fl_status_inicialfl_status_inicial" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? (cell == 1?'Sim': 'Não'):'Não'}</p>;
            }
        },
        {
            dataField: 'FL_ATIVO',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("fl_status_ativo",'fl_status_ativo')}>
            Staus ativo
            <Image id="fl_status_ativofl_status_ativo" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? (cell == 1?'Sim': 'Não'):'Não'}</p>;
            }
        },
        {
            dataField: 'FL_FATURA',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("fl_fatura",'fl_fatura')}>
            Fatura
            <Image id="fl_faturafl_fatura" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? (cell == 1?'Sim': 'Não'):'Não'}</p>;
            }
        },
        {
            dataField: 'FL_AUTOMACAO',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("fl_automacao",'fl_automacao')}>
            Automação
            <Image id="fl_automacaofl_automacao" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? (cell == 1?'Sim': 'Não'):'Não'}</p>;
            }
        },
        {
            dataField: 'FL_CANCELAMENTO',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("fl_cancelamento",'fl_cancelamento')}>
            Cancelamento
            <Image id="fl_cancelamentofl_cancelamento" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell !== '' || cell !== null || cell !== undefined ? (cell == 1?'Sim': 'Não'):'Não'}</p>;
            }
        },
        {
            dataField: 'n0_simcards',
            headerClasses: 'nao-selecionavel',
            text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_simcards",'n0_simcards')}>
            Número de SIM cards
            <Image id="n0_simcardsn0_simcards" className='d-none' src={setaDropdown()}/>
            </button>,
            sort: true,
            formatter: (cell, row) => {
                return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
            }
        },
    ]

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {

            if(isSelect){

                if(row.FL_PADRAO === 0){
                    handleSelecionarCicloVida(
                        row.CD_CICLO_VIDA, 
                        row.DS_STATUS,
                        row.DS_STATUS_OPERADORA,
                        row.FL_INICIAL,
                        row.FL_ATIVO,
                        row.FL_FATURA,
                        row.FL_AUTOMACAO,
                        row.FL_CANCELAMENTO,
                        row.FL_PADRAO
                    )
                }else{
                    setCdCicloVida('')
                    setStatus('')
                    setStatusOperadora('')
                    setStatusInicial('')
                    setStatusAtivo('')
                    setFatura('')
                    setAutomacao('')
                    setCancelamento('')
                    despacho(desabilitarAcoes(true));
                }
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    function handleSelecionarCicloVida(codigo, status, status_operadora, status_inicial, status_ativo, fatura, automacao, cancelamento, fl_padrao){
        setCdCicloVida(codigo)
        setStatus(status)
        setStatusOperadora(status_operadora)
        setStatusInicial(status_inicial)
        setStatusAtivo(status_ativo)
        setFatura(fatura)
        setAutomacao(automacao)
        setCancelamento(cancelamento)
        setFlPadrao(fl_padrao)
        despacho(desabilitarAcoes(false));

    }

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setCdCicloVida(row.CD_CICLO_VIDA)
 
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        despacho(desabilitarAcoes(true));
        setPaginaAtual(1)
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            despacho(revelarDoppelgangerCliente(false))
            despacho(visualizarDadosRefresh(!visualizarDadosRefreshVar))
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    async function handleCancelar() {
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
        itensVar = []
        setRevelarMensagemAtencaoCicloPadrao(false)
        despacho(revelarExcluirCicloVidaFunc(false))
    }

    function handleMensagemAtencao(confirmacao){
        if(flPadrao === 0){
            if(revelarMensagemAtencao === false){
                setRevelarMensagemAtencao(true)
            }else{
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirCicloVidaFunc(false))
                setRevelarMensagemAtencaoCicloPadrao(false)
                if(confirmacao === true){
                    ColetaClickstream(1303) // fatCicloVidaTabelaDropAcoesExcluirCicloVidaDeletar
                    handleDeletar()
                } else {
                    ColetaClickstream(743) // fatCicloVidaTabelaDropAcoesExcluirCicloVidaCancelar
                }
            }
        }
        if(flPadrao === 1){
            if(revelarMensagemAtencaoCicloPadrao === false){
                setRevelarMensagemAtencaoCicloPadrao(true)
            }else{
                setRevelarMensagemAtencaoCicloPadrao(false)
                despacho(revelarExcluirCicloVidaFunc(false))
                if(confirmacao === true){
                    handleCancelar()
                }
            }
        }

    }

    async function handleDeletar(){
        setSpinAtivo(true)

        await api.get('/m1/deletar/fat_ciclo_vida?cd_fat_ciclo_vida='+cdCicloVida)
        .then(function (response) {

            if(response.data.status === 600){
                setMensagemErro(response.data.info)
                despacho(revelarExcluirCicloVidaFunc(false))
                handleMensagemErro(false)
                setSpinAtivo(false)
            }else{
                setRenderizar(!renderizar)
                despacho(revelarExcluirCicloVidaFunc(false))
                handleMensagemSucesso(false)
                setSpinAtivo(false)
            }

        })
        .catch(function (error) {

            setMensagemErro("Erro ao excluir ciclo de vida!")
            despacho(revelarExcluirCicloVidaFunc(false))
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    useEffect(() => {
        if(revelarExcluirCicloVida){
            setRevelarMensagemAtencao(true)
        }
    }, [revelarExcluirCicloVida])
    

    return(
        <div className='container-tela'>
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMensagemSucesso &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemSucesso
                            handleMensagemSucesso={handleMensagemSucesso}  
                            mensagemTitulo={"Sucesso"}
                            mensagemParagrafo1={"O ciclo de vida "+status+" foi deletado."}
                            mensagemBotao={"OK"}
                        /> 
                    </div>
                </div>
            }
            { revelarMensagemErro &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MensagemErro
                            handleMensagemErro={handleMensagemErro}
                            mensagemTitulo={"Erro"}
                            mensagemParagrafo3={mensagemErro}
                            mensagemBotao={"Retornar"}
                        />
                    </div>
                </div>
            }
            {revelarNovoCiclo === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <NovaCiclo
                            tituloJanela="Cadastrar"
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        />
                    </div>
                </div>
            }
            
            {revelarEditarCiclo === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <NovaCiclo
                            tituloJanela="Editar"
                            cdCicloVida={cdCicloVida}
                            status={status}
                            statusOperadora={statusOperadora}
                            statusInicial={statusInicial}
                            statusAtivo={statusAtivo}
                            fatura={fatura}
                            automacao={automacao}
                            cancelamento={cancelamento}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        />
                    </div>
                </div>
            }

            {revelarMensagemAtencao === true &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar "+ status}
                />
            }

            {revelarMensagemAtencaoCicloPadrao === true &&
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Atenção!"}
                    mensagemParagrafo1={"Não é possivel deletar um ciclo de vida padrão!"}
                    mensagemBotao={"Voltar"}
                />
            }

            {estadoVisualizarDados === true &&
                <BarraDadosCliente/>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='CD_CICLO_VIDA' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={selecaoLinhas}
                                bootstrap4={true}
                                bordered={false}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            
                           <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
        </div>
    )
}

function mapStateToProps(state) {
    return { 
        conteudoBusca: state.manejadorState.conteudoBusca, 
        filtros: state.filtrosState.filtros, 
        visualizarDados: state.virtueyesState.visualizarDados,
        visualizarDadosRefreshVar: state.virtueyesState.visualizarDadosRefresh
    };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaClientesVirtueyes));