//IMPORTAÇÕES 
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import { connect } from "react-redux";/*eslint-disable*/
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { EstilosGlobais } from "./css/global";

//COMPONENTES
import CabecalhoSistema from './cabecalhoSistema'
import NavegacaoSistema from './navegacaoSistema'
import logout from './components/logout/logout';
//TELAS PAINEL ADMINISTRATIVO [PAV]
import TabelaSistema from './components/painelAdministrativo/pavSistema/tabela';
import TabelaModulo from './components/painelAdministrativo/pavModulo/tabela';
import TabelaTela from './components/painelAdministrativo/pavTela/tabela';
import TabelaElemento from './components/painelAdministrativo/pavElemento/tabela';
import TabelaTipoPessoa from './components/painelAdministrativo/pavTipoPessoa/tabela';
import TabelaCliente from './components/painelAdministrativo/pavCliente/tabela';
import TabelaParceiro from './components/painelAdministrativo/pavParceiro/tabela';
import TabelaCrudRBAC from './components/painelAdministrativo/pavCrudRBAC/tabela';
import TabelaUsuario from './components/painelAdministrativo/pavUsuario/tabela';
import TabelaVersionamento from './components/painelAdministrativo/pavVersionamento/tabela';
import TabelaGit from './components/painelAdministrativo/pavGit/tabela';
import TabelaPerfil from './components/painelAdministrativo/pavPerfil/tabela';
import TabelaImeiLog from './components/painelAdministrativo/logs/imeiLog/tabela';
import TabelaMenu from './components/painelAdministrativo/pavMenu/tabela';

import  './components/clickstreamAnalise/clickstreamColeta.js'
import clickstreamAnalise from './components/clickstreamAnalise/clickstreamAnalise.js'
import TabelaRequestLog from './components/painelAdministrativo/logs/requestLog/tabela'
import CadastroPermissao from './components/painelAdministrativo/pavPermissao/cadastro'
import TabelaAcessoLog from './components/painelAdministrativo/logs/acessoLog/tabela'
//TELAS PAINEL DISPOSITIVOS [DSP]
import TabelaDispositivos from './components/painelDispositivos/dspSimcard/tabelaSimcard'
//TELAS DO PAINEL VIRTUEYES [VIRTU]
import TabelaClientes from './components/painelVirtueyes/virtuTabelaClientes/tabelaClientes'
import TabelaCentralMensagens from './components/painelVirtueyes/centralMensagens/tabela'
import MigracaoPlanoGerenciamento from './components/painelVirtueyes/migracaoPlano/tabela';
import MigracaoPlanoDashboard from './components/painelVirtueyes/migracaoPlano/dashboard/index.js';
import TabelaPedidoSmS from './components/painelVirtueyes/pedidoSms/tabelaPedidoSms'
import TabelaClientesAcoes from './components/painelVirtueyes/solicitacaoCliente/tabela'
import TabelaOperadoraAcoes from './components/painelVirtueyes/solicitacaoOperadora/tabela'
import TabelaBloqueioAcoes from './components/painelVirtueyes/solicitacaoBloqueio/tabela'
import TabelaCadastroPerfil from './components/painelCadastros/perfil/tabela'
//TELAS DO PAINEL SMS
import TabelaMensagensSms from './components/painelSms/smsMensagens/tabela'
import TabelaTemplateSms from './components/painelSms/smsTemplate/tabela'
//PAINEL PEDIDOS
import TabelaNovoPedidoSms from './components/painelPedidos/pedidosSms/tabela'
import TabelaPedidoImporta from './components/painelPedidos/pedidosImportado/tabela'
// PAINEL FINANCEIRO 
import TabelaFinanceiro from './components/painelFinanceiro/tabela'
// PAINEL AUTOMAÇÃO
import TabelaAutomacao from './components/painelAutomacao/tabelaAutomacao'
/* PAINEL TUTORIAIS */
import PainelTutoriais from './components/painelTutoriais/painelTutoriais'

//TELAS DO PAINEL PEDIDOS
import TabelaSimcardPedidos from './components/painelPedidos/pedidosSimcard/tabela'

//MÓDULO DE LINHAS RESETADAS DA TRUCKS
import TabelaLinhasRestTrucks from './components/painelResetTrucks/tabela'

//MÓDULO MENSAGENS
import TabelaMensagens from './components/painelMensagens/tabela'
import DashboardGeral from './components/painelDashboard/dashboard'
import DashboardSMS from './components/painelDashboard/dashboardsms'
//PAINEL USUARIOS
import TabelaUsuarioCliente from './components/painelUsuarios/tabela'

//PAINEL FATURAS
import FaturasPedidos from './components/painelFaturas/fatPedidos/tabela'
import FaturasCadastroContas from './components/painelFaturas/fatContas/tabela'
import FatTabelaPlanos from './components/painelFaturas/fatPlanos/tabela'
import FaturasCadastroEmpresas from './components/painelFaturas/fatEmpresas/tabela'
import FatTabelaFornecedores from './components/painelFaturas/fatFornecedores/tabela'
import FatTabelaServico from './components/painelFaturas/fatServico/tabela'
import FatTabelaOperadoras from './components/painelFaturas/fatOperadoras/tabela'
import TabelaDispostiviosFaturas from './components/painelFaturas/fatDispositivos/tabela'
import FaturasCadastroCicloVida from './components/painelFaturas/fatCicloVida/tabela'
import FatTabelaFebraban from './components/painelFaturas/fatFebraban/tabela'
import TabelaFatSolicitacoes from './components/painelFaturas/fatSolicitacoes/tabela'
import TabelaConectSolicitacoes from './components/painelFaturas/conectSolicitacoes/tabela'
import FatFaturas from './components/painelFaturas/fatFaturas/tabela'
import FatConciliacao from './components/painelFaturas/fatConciliacao/tabela'
import FatContestacao from './components/painelFaturas/fatContestacao/tabela'
import DashboardFaturas from './components/painelFaturas/fatDashboard/dashboard'
import FatRelatorios from './components/painelFaturas/fatRelatorios/tabela'
// PAINEL RELATORIOS
import PainelRelatorio from './components/painelRelatorio/tabela'
// PAINEL CADASTRO
import PainelCadastroCliente from './components/painelCadastros/cadCliente/tabela'

import { claroTema, escuroTema } from './css/temas';


// IMPORTACAO TRIAL 
import trial from './components/painelFaturas/fatTrial/trial'
//ESTILIZAÇÃO

// import BackOfficeUsuario from './components/painelVirtueyes/usuario/cadastro/cadastro'

// Para a IMPRESSÃO do clickstream
import TelaInicial from './components/telaInicial/telaInicial';

import PainelLogin from './components/painelLogin/painelLogin'
import telaLogin from './components/login/login'
import BreadCrumbs from './components/BreadCrumbs/BreadCrumbs';
import { isPortal } from './utils/nivelCliente.js';
const url = window.location.href;

const IMAGEMS_TEMA_ESCURO = {
  img_nav_1:
    "https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesSimbolo.svg",
  img_nav_2:
    "https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesBranco.svg",
  img_nav_3: "none",
  img_inicial: "none",
};

const IMAGEMS_TEMA_CLARO = {
  img_nav_1:
    "https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesSimbolo.svg",
  img_nav_2:
    "https://veye.s3.sa-east-1.amazonaws.com/icones/virtueyesPreto.svg",
  img_nav_3: "",
  img_inicial:
    "https://veye.s3.sa-east-1.amazonaws.com/icones/tela_inicial_v2.svg",
};


function App(){
    const portalCliente = isPortal();
    //HOOKS

    // armazena o valor do tema (claro ou algarClaro) ou (escuro ou algarEscuro)
    // que será usado para definir com qual tema será iniciado o sistema
    // OBS.: Os temas algarClaro e algarEscuro, parecem estar em desuso, mas foi mantido
    // como item verificador para o caso de por alguma ocasião, vir com este valor.
    const localTema = localStorage.getItem("tema");
    let temaAtual = localTema || "claro";
    const isEscuro = temaAtual === "escuro";
    // Função que retorna os valores de coresTema, de acordo com o valor de @localTema
    const corTema = () => {
      if (isEscuro) return escuroTema;
      else return claroTema;
    };

    // Função que retorna os valores de imagensTema, de acordo com o valor de @localTema
    const imgTema = () => {
      temaAtual = localTema === "escuro" ? "escuro" : "claro";

      if (isEscuro) return IMAGEMS_TEMA_ESCURO;
      else return IMAGEMS_TEMA_CLARO;
    };

    // UseStates chama as funções quando necessário
    const [coresTema, setCoresTema] = useState(
        corTema()
    )

    const [imagensTema, setImagensTema] = useState(
        imgTema()
    )
    
    const [modoTema, setModoTema] = useState(false)
    const [buscarTema, setBuscarTema] = useState(false) 
    const [flChat, setFlChat] = useState(true)      

    //CAPTURA A URL E O LOCALSTORAGE ATUAL
    const url = window.location.href;

    //VALIDA O CONTEÚDO DA URL CAPTURADA
    const validation = url.includes("sso") ? "sso" : url.includes("algar") ? "algar" : "veye"

    //OBJETOS DE REDIRECIONAMENTO
    const algar = {
        pathname:"/login",
        algar:true
    }

    const sso = {
        pathname:"/login",
        sso:true
    }
    
    const PrivateRoute = ({ component: Component, ...rest }) => (
        <Route {...rest} render={props => localStorage.getItem("@veye-Token") ? (<Component {...props} />) : (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)} />
    ) 

    useEffect(() => {

        if((localTema === 'claro' && coresTema.backgroud === '#000000') || (localTema === 'escuro'  && coresTema.backgroud === '#FFFFFF')) {
            
            setCoresTema(corTema())
            setImagensTema(imgTema())
        }

        return;

    }, [buscarTema, modoTema])

    const originalSetItem = localStorage.setItem;

    localStorage.setItem = function(){
        const event = new Event('itemInserted');
        document.dispatchEvent(event);
        originalSetItem.apply(this, arguments);
    };

    const getFlChat = () => localStorage.getItem('fl_chat')
    const isTelaLogin = () => window.location.href.includes('/login');

    const localStorageSetHandler = function() {
        if((!isTelaLogin() && getFlChat() == 1) && !portalCliente) {
                if(document.getElementById("fivehub-chat-button-container") == null) {
                    setFlChat(true);
                }
        }
        else {
            setFlChat(false);
        }
            
      };

    document.addEventListener("itemInserted", localStorageSetHandler, false);

    useEffect(() => {
      const handleStorageChange = () => {
        const flagChat = getFlChat();
        if (flagChat == 1 && flChat && !isTelaLogin()){
            /* TODO como o mesmo código abaixo está repetido em 'index.html', 
                verificar se não quebra ao criar um único arquivo que tenha esse código em apenas 1 lugar. Tem riscos, principalmente porque sei se cria o 'script' abaixo em uma pasta específica
            */
            var script = document.createElement('script');
            script.id = '5chat_script';
            script.src = "https://5chat.5hub.com.br/v2/static/plugin-pipechat.min.js";
            script.setAttribute('data-subdomain', "virtueyes")
            script.setAttribute('data-activation-key',"virtueye.63875a30305179.72989000")
            document.head.appendChild(script);
        } else { 
            const element = document.getElementById("5chat_script");
            const element2 = document.getElementById("fivehub-chat-button-container");
            if(element !== null) element.remove();
            if(element2 !== null) element2.remove(); 
        }
      };
  
      handleStorageChange();
    }, [flChat]);

    //RENDERIZAÇÃO DO COMPONENTE
    return (
        <>
            <ThemeProvider theme={coresTema}>
                <EstilosGlobais/>
                <BrowserRouter>
                
                {validation === "sso" || validation === "algar" ?
                    <Route path="/login" exact component={() => <PainelLogin buscarTema={buscarTema} setBuscarTema={setBuscarTema}/>}/>
                :
                    <Route path="/login" exact={true} component={telaLogin}/>
                }
                    { validation === "sso" ?
                        <Route path="/" exact>
                            <Redirect from="/" to={sso}/>
                        </Route>
                    : validation === "algar" ?
                        <Route path="/" exact>
                            <Redirect from="/" to={algar}/>
                        </Route>
                    :
                        <Route path="/" exact>
                            <Redirect from="/" to="/veye"/>
                        </Route>
                    }
                    <Route path="/logout" exact component={logout}/>
                    <Route path="/trial" component={trial} />
                    <Route path="/veye" render={({match:{url}}) => localStorage.getItem("@veye-Token") ? 
                        <>
                            <NavegacaoSistema imagensTema={imagensTema}/>
                            <div id="container-tela">
                                <CabecalhoSistema modoTema={modoTema} setModoTema={setModoTema}/>
                                <div id="container-corpo"> 
                                    <BreadCrumbs />

                                    {/* TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH */}
                                    <PrivateRoute path={url + "/"} exact  component={() => <TelaInicial imagensTema={imagensTema}/>} />
                                    <PrivateRoute path={url + "/dispositivos"} exact component={() => <TabelaDispositivos/>}/>
                                    
                                    <PrivateRoute path={url + "/painel/sistema"} exact component={() => <TabelaSistema/>}/>
                                    <PrivateRoute path={url + "/painel/modulo"} exact component={() => <TabelaModulo/>}/>
                                    <PrivateRoute path={url + "/painel/tela"} exact component={() => <TabelaTela/>}/>
                                    <PrivateRoute path={url + "/painel/elemento"} exact component={() => <TabelaElemento/>}/>
                                    <PrivateRoute path={url + "/painel/tipoPessoa/"} exact component={() => <TabelaTipoPessoa/>}/>
                                    <PrivateRoute path={url + "/painel/usuarios"} exact component={() => <TabelaUsuario/>}/>
                                    <PrivateRoute path={url + "/tabela/versoes"} exact component={() => <TabelaVersionamento/>}/>
                                    <PrivateRoute path={url + "/painel/versionamento"} exact component={() => <TabelaVersionamento permissoesAdm />}/>
                                    <PrivateRoute path={url + "/painel/git"} exact component={() => <TabelaGit/>}/>
                                    <PrivateRoute path={url + "/painel/clientes"} exact component={() => <TabelaCliente/>}/>
                                    <PrivateRoute path={url + "/painel/parceiros"} exact component={() => <TabelaParceiro/>}/>
                                    <PrivateRoute path={url + "/painel/papeis"} exact component={() => <TabelaCrudRBAC/>}/>
                                    <PrivateRoute path={url + "/painel/perfil"} exact component={() => <TabelaPerfil/>}/>
                                    <PrivateRoute path={url + "/painel/requestLog"} exact component={() => <TabelaRequestLog/>}/>
                                    <PrivateRoute path={url + "/painel/acessoLog"} exact component={() => <TabelaAcessoLog/>}/>
                                    <PrivateRoute path={url + "/painel/permissoes"} exact component={() => <CadastroPermissao/>}/>
                                    <PrivateRoute path={url + "/painel/clickstreamAnalise"} exact component={clickstreamAnalise} />
                                    
                                    <PrivateRoute path={url + "/virtueyes/virtuClientes"} exact component={() => <TabelaClientes/>}/>
                                    <PrivateRoute path={url + "/virtueyes/centralMensagens"} exact component={() => <TabelaCentralMensagens/>}/>
                                    <PrivateRoute path={url + "/virtueyes/pedidosSMS"} exact component={() => <TabelaPedidoSmS/>}/>

                                    <PrivateRoute path={url + "/virtueyes/migracaoPlano/gerenciamento"} exact component={() => <MigracaoPlanoGerenciamento/>}/>
                                    <PrivateRoute path={url + "/virtueyes/migracaoPlano/dashboard"} exact component={() => <MigracaoPlanoDashboard/>}/>

                                    {/* Central de ações */}
                                    <PrivateRoute path={url + "/virtueyes/clientes"} exact component={() => <TabelaClientesAcoes/>}/>
                                    <PrivateRoute path={url + "/virtueyes/operadora"} exact component={() => <TabelaOperadoraAcoes/>}/>
                                    <PrivateRoute path={url + "/virtueyes/bloqueio"} exact component={() => <TabelaBloqueioAcoes/>}/>
                                    
                                    <PrivateRoute path={url + "/sms/smsMensagens"} exact component={() => <TabelaMensagensSms/>}/>
                                    <PrivateRoute path={url + "/sms/smsTemplate"} exact component={() => <TabelaTemplateSms/>}/>
                                    
                                    <PrivateRoute path={url + "/pedidos/Sms"} exact component={() => <TabelaNovoPedidoSms/>}/>
                                    <PrivateRoute path={url + "/pedidos/pedidosSimcard"} exact component={() => <TabelaSimcardPedidos/>}/>
                                    
                                    <PrivateRoute path={url + "/financeiro"} exact component={() => <TabelaFinanceiro/>}/>
                                    <PrivateRoute path={url + "/dashboard/dispositivos"} exact component={() => <DashboardGeral/>}/>
                                    <PrivateRoute path={url + "/dashboard/sms"} exact component={() => <DashboardSMS/>}/>
                                    <PrivateRoute path={url + "/automacao"} exact component={() => <TabelaAutomacao/>}/>
                                    <PrivateRoute path={url + "/tutoriais"} exact component={() => <PainelTutoriais/>}/>
                                    <PrivateRoute path={url + "/mensagens"} exact component={() => <TabelaMensagens/>}/>
                                    <PrivateRoute path={url + "/usuarios"} exact component={() => <TabelaUsuarioCliente/>}/>
                                    
                                    <PrivateRoute path={url + "/faturas/pedidos"} exact component={() => <FaturasPedidos/>}/>
                                    <PrivateRoute path={url + "/faturas/contas"} exact component={() => <FaturasCadastroContas/>}/>
                                    <PrivateRoute path={url + "/faturas/febraban"} exact component={() => <FatTabelaFebraban/>}/>
                                    <PrivateRoute path={url + "/faturas/empresas"} exact component={() => <FaturasCadastroEmpresas/>}/>
                                    <PrivateRoute path={url + "/faturas/planos"} exact component={() => <FatTabelaPlanos/>}/>
                                    <PrivateRoute path={url + "/faturas/servicos"} exact component={() => <FatTabelaServico/>}/>
                                    <PrivateRoute path={url + "/faturas/cicloVida"} exact component={() => <FaturasCadastroCicloVida/>}/>
                                    <PrivateRoute path={url + "/faturas/operadoras"} exact component={() => <FatTabelaOperadoras/>}/>
                                    
                                    <PrivateRoute path={url + "/solicitacoes/faturas"} exact component={() => <TabelaFatSolicitacoes/>}/>
                                    <PrivateRoute path={url + "/solicitacoes/conectividade"} exact component={() => <TabelaConectSolicitacoes/>}/>
                                    
                                    <PrivateRoute path={url + "/faturas/dispositivos"} exact  component={() => <TabelaDispostiviosFaturas/>} />
                                    <PrivateRoute path={url + "/faturas/fornecedores"} exact  component={() => <FatTabelaFornecedores/>} />
                                    <PrivateRoute path={url + "/faturas/faturas"} exact  component={() => <FatFaturas/>} />
                                    <PrivateRoute path={url + "/faturas/conciliacao"} exact  component={() => <FatConciliacao/>} />
                                    <PrivateRoute path={url + "/faturas/contestacao"} exact  component={() => <FatContestacao/>} />
                                    <PrivateRoute path={url + "/faturas/dashboard"} exact  component={() => <DashboardFaturas/>} />
                                    <PrivateRoute path={url + "/faturas/relatorios"} exact  component={() => <FatRelatorios/>} />
                                    
                                    <PrivateRoute path={url + "/painel/relatorios"} exact  component={() => <PainelRelatorio/>} />
                                    <PrivateRoute path={url + "/painel/imeiLog"} exact  component={() => <TabelaImeiLog/>} />
                                    
                                    <PrivateRoute path={url + "/virtueyes/perfil"} exact  component={() => <TabelaPerfilBackOffice/>} />
                                    <PrivateRoute path={url + "/painel/menu"} exact  component={() => <TabelaMenu/>} />
                                    <PrivateRoute path={url + "/cadastro/perfil"} exact  component={() => <TabelaCadastroPerfil/>} />
                                    <PrivateRoute path={url + "/cadastro/cliente"} exact  component={() => <PainelCadastroCliente/>} />
                                    <PrivateRoute path={url + "/portal/cliente"} exact  component={() => <PainelCadastroCliente/>} />
                                    <PrivateRoute path={url + "/pedidos/importa"} exact  component={() => <TabelaPedidoImporta/>} />
                                    <PrivateRoute path={url + "/linhasReset"} exact  component={() => <TabelaLinhasRestTrucks/>} />
                                    {/* TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH TRASH */}
                                </div>
                            </div>
                        </>
                    :
                        <Redirect to={{ pathname: "/login" }}/>
                    }/>
                </BrowserRouter>
            </ThemeProvider>
        </>
    )
}

function mapStateToProps(state){
    return {
        "systemThemeState" : state.systemControlStates.systemTheme
    }
}

export default connect(mapStateToProps)(React.memo(App))