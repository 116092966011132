export function getTitulosPaginas(dados){
    
    const menuNivel1 = dados[1]; // módulo (se não tiver filho, então é uma "tela")
    const menuNivel2 = dados[2]; // tela
    // const menuNivel3 = dados[3]; // elemento

    let titulosPaginas = [];

    menuNivel1.map(modulo => {
        if(modulo.ativo) {
            if(modulo.filho === 0) { // é uma página
                const { cdMenu, label, rota } = modulo;
                titulosPaginas.push({ cdMenu, label, /*tituloPagina: label,*/ rota: '/'+rota })
            } else { // não é uma página e possui submenus
                const { cdMenu, label, rota, filho } = modulo;

                const objMenu = { cdMenu, label, rota, filho };

                const subPaginas = menuNivel2.filter(tela => tela.menuPai === cdMenu);
                const labelPai = modulo.label;

                subPaginas.map(tela => {
                    const { cdMenu, menuPai, label, rota } = tela;
                    // const tituloPagina = `${labelPai} ${label}`;
                    titulosPaginas.push({ cdMenu, menuPai, labelPai, label, /*tituloPagina,*/ rota: '/'+rota })
                    return null;
                })
                titulosPaginas.push(objMenu)
            }
        }
        return null;
    })

    return titulosPaginas;
}

export function getItemBreadCrumbDados(breadCrumbsObj, manejador, locatinPathname) {
    let breadCrumbItem;
    let breadCrumbArr = [];

    const breadCrumbFound = breadCrumbsObj.find(tela => tela.rota === locatinPathname);

    if(breadCrumbFound) {
        const { /*tituloPagina,*/ label, rota, labelPai } = breadCrumbFound;
        breadCrumbItem = { tituloPagina: label, rota, label}

        if(manejador === 'dashboardGeral') {
            return [{ label: 'Dashboard' }, { tituloPagina: 'Dashboard Dispositivos', label: 'Dispositivos', rota }]
        }

        if(labelPai) {
            return [{ label: labelPai }, breadCrumbItem]
        } else {
            return [breadCrumbItem];
        }
    } else {
        const rota = locatinPathname;
        const arrVirtuAcoes = [{ label: 'Virtueyes' }, { label: 'Central de Ações' }];
        const arrVirtuMigracaoPlano = [{ label: 'Virtueyes' }, { label: 'Migração de Plano' }]; 

        const getBreadcrumArrVirtuAcoes = (label, rota) => [...arrVirtuAcoes, { label, rota }]

        const getBreadcrumArrVirtuMigracaoPlano = (label, rota) => [...arrVirtuMigracaoPlano, { label, rota }]


        switch(manejador) {
            case 'dashboardSMS':
                breadCrumbArr = [{ label: 'Dashboard' }, { tituloPagina: 'Dashboard SMS', label: 'SMS', rota }]
                break;

            case 'dashboardFaturas':
                breadCrumbArr = [{ label: 'Dashboard' }, { tituloPagina: 'Dashboard Faturas', label: 'Faturas', rota }]
                break;

            case 'solicitacaoBloqueio':
                breadCrumbArr = getBreadcrumArrVirtuAcoes('Bloqueio', "/veye/virtueyes/bloqueio");
                break;

            case 'solicitacaoCliente':
                breadCrumbArr = getBreadcrumArrVirtuAcoes('Clientes', "/veye/virtueyes/clientes");
                break;

            case 'solicitacaoOperadora':
                breadCrumbArr = getBreadcrumArrVirtuAcoes('Operadora', "/veye/virtueyes/operadora");
                break;

            case 'migracaoPlanoGerenciamento':
                breadCrumbArr = getBreadcrumArrVirtuMigracaoPlano('Gerenciamento', "/veye/virtueyes/migracaoPlano/gerenciamento");
                break;

            case 'migracaoPlanoDashboard':
                breadCrumbArr = getBreadcrumArrVirtuMigracaoPlano('Dashboard', "/veye/virtueyes/migracaoPlano/dashboard");
                break;
            default:
                breadCrumbArr = [{
                    tituloPagina: manejador,
                    label: manejador,
                    rota
                }]
                break;
        }
    }

    return breadCrumbArr;
}
