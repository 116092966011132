import { alterarDadosDashboardMigracaoPlano } from "./dashboardMigracaoPlano"

export { alterarDadosDashboardMigracaoPlano };

export function systemTheme(data) {
    return {
        "type": "SYSTEM_THEME",
        "data": data
    }
}

export function currentScreenId(data) {
    return {
        "type": "CURRENT_SCREEN_ID",
        "data": data
    }
}

export function componentRendering(data) {
    return {
        "type": "COMPONENT_RENDERING",
        "data": data
    }
}

export function navigationExpansion(data) {
    return {
        "type": "NAVIGATION_EXPANSION",
        "data": data
    }
}



export function revelarRelatorioResumoFebraban(parametro) {
    return {
        type: "REVELAR_RELATORIO_RESUMO_FEBRABAN",
        parametro
    }
}

export function exportarCdSms(parametro) {
    return {
        "type": "exportar_CdSms",
        parametro
    }
}

export function revelarTutorial(parametro) {
    return {
        type: "REVELAR_TUTORIAL",
        parametro
    }
}

export function modoSelecaoSimcard(parametro) {
    return {
        type: "MODO_SELECAO_SIMCARD",
        parametro
    }
}


export function verificadorBloqueioFinanceiro(parametro) {
    return {
        type: "VERIFICADOR_BLOQUEIO_FINANCEIRO",
        parametro
    }
}

export function mudarManejador(parametro) {
    return {
        type: "MUDAR_MANEJADOR",
        parametro
    }
}

export function exportarLinhasDesbloqueioCiclo(parametro) 
{
    return {
        type: "REVELAR_EXPORTAR_DESBLOQUEIO_CICLO" ,
        parametro
    }
}
export function mudarItensSimcard(parametro) {
    return {
        type: "MUDAR_ITENS_SIMCARD",
        parametro
    }
}

export function imagemPerfil(parametro) {
    return {
        type: "IMAGE_PERFIL",
        parametro
    }
}

export function revelarRelatorioConsumo(parametro) {
    return {
        type: "REVELAR_RELATORIO_CONSUMO_DISPOSITIVOS",
        parametro
    }
}

export function exportarLinhas(parametro) {
    return {
        type: "EXPORTAR_LINHAS_DISPOSITIVOS",
        parametro
    }
}

export function exportarLinhasOperadora(parametro) {
    return {
        type: "EXPORTAR_LINHAS_DISPOSITIVOS_OPERADORA",
        parametro
    }
}

export function revelarRelatorioDisponibilidade(parametro) {
    return {
        type: "REVELAR_RELATORIO_DISPONIBILIDADE_DISPOSITIVOS",
        parametro
    }
}

export function exportarOperadora(parametro) {
    return {
        type: "EXPORTAR_OPERADORA",
        parametro
    }
}

export function expandirNavegacao(parametro) {
    return {
        type: "EXPANDIR_NAVEGACAO",
        parametro
    }
}

export function expandirNavegacaoHover(parametro) {
    return {
        type: "EXPANDIR_NAVEGACAO_HOVER",
        parametro
    }
}

export function mudarFiltros(parametro) {
    return {
        type: "MUDAR_FILTROS",
        parametro
    }
}

export function maximoTagsExibidas(parametro) {
    return {
        type: "MAXIMO_TAGS_EXIBIDAS",
        parametro
    }
}

export function mudarBuscaArquivo(parametro) {
    return {
        type: "MUDAR_BUSCA_ARQUIVO",
        parametro
    }
}

export function limpadorBuscaArquivo(parametro) {
    return {
        type: "LIMPAR_BUSCA_ARQUIVO",
        parametro
    }
}

export function mudarDoppleGangerCliente(parametro) {
    return {
        type: "MUDAR_DOPPLEGANGER_CLIENTE",
        parametro
    }
}

export function revelarCancelamentoDeConta(parametro) {
    return {
        type: "REVELAR_CANCELAMENTO_DE_CONTA",
        parametro
    }
}

export function revelarIotTelecom(parametro) {
    return {
        type: "REVELAR_ATIVACAO_SIMCARD",
        parametro
    }
}

export function revelarAtivarLinhasAWS(parametro) {
    return {
        type: "REVELAR_ATIVACAO_LINHAS_AWS",
        parametro
    }
}

export function revelarLiberarSms(parametro) {
    return {
        type: "REVELAR_LIBERAR_SMS",
        parametro
    }
}

export function revelarBloquearSms(parametro) {
    return {
        type: "REVELAR_BLOQUEAR_SMS",
        parametro
    }
}

export function revelarEnviarSms(parametro) {
    return {
        type: "REVELAR_ENVIAR_SMS",
        parametro
    }
}

export function revelarAdicionarPacoteExtra(parametro) {
    return {
        type: "REVELAR_ADICIONAR_PACOTE_EXTRA",
        parametro
    }
}

export function revelarReset(parametro) {
    return {
        type: "REVELAR_RESET",
        parametro
    }
}

export function revelarTesteConexao(parametro) {
    return {
        type: "REVELAR_TESTE_CONEXAO",
        parametro
    }
}

export function revelarFalhaNaOperadora(parametro) {
    return {
        type: "REVELAR_FALHAR_NA_OPERADORA",
        parametro
    }
}

export function revelarSuporteTecnico(parametro) {
    return {
        type: "REVELAR_SUPORTE_TECNICO",
        parametro
    }
}

export function revelarRealocacao(parametro) {
    return {
        type: "REVELAR_REALOCACAO",
        parametro
    }
}

export function revelarCancelamentoReposicao(parametro) {
    return {
        type: "REVELAR_CANCELAMENTO_REPOSICAO",
        parametro
    }
}

export function revelarCancelamento(parametro) {
    return {
        type: "REVELAR_CANCELAMENTO",
        parametro
    }
}

export function revelarAtivarLinha(parametro) {
    return {
        type: "REVELAR_ATIVAR_LINHA",
        parametro
    }
}

export function revelarReativarLinha(parametro) {
    return {
        type: "REVELAR_REATIVAR_LINHA",
        parametro
    }
}

export function revelarDesbloquearUso(parametro) {
    return {
        type: "REVELAR_DESBLOQUEAR_USO",
        parametro
    }
}

export function revelarDesbloqueioCiclo(parametro)
{
    return {
        type: "REVELAR_DESBLOQUEIO_CICLO",
        parametro
    }
}

export function revelarRemoverBloqueioTemporario(parametro) {
    return {
        type: "REVELAR_REMOVER_BLOQUEIO_TEMPORARIO",
        parametro
    }
}

export function revelarSuspenderLinha(parametro) {
    return {
        type: "REVELAR_SUSPENDER_LINHA",
        parametro
    }
}

export function revelarBloquearUso(parametro) {
    return {
        type: "REVELAR_BLOQUEAR_USO",
        parametro
    }
}

export function revelarBloquearTemporariamento(parametro) {
    return {
        type: "REVELAR_BLOQUEAR_TEMPORARIAMENTE",
        parametro
    }
}

export function revelarAlterarPlanoValor(parametro) {
    return {
        type: "REVELAR_ALTERAR_PLANO_VALOR",
        parametro
    }
}

export function revelarRenegociarContrato(parametro) {
    return {
        type: "REVELAR_RENEGOCIAR_CONTRATO",
        parametro
    }
}

export function revelarTrocaDeTitularidade(parametro) {
    return {
        type: "REVELAR_TROCA_DE_TITULARIDADE",
        parametro
    }
}

export function revelarAtivarDemonstracao(parametro) {
    return {
        type: "REVELAR_ATIVAR_DEMONSTRACAO",
        parametro
    }
}

export function revelarDesativarDemonstracao(parametro) {
    return {
        type: "REVELAR_DESATIVAR_DEMONSTRACAO",
        parametro
    }
}

export function revelarImportarDescricao(parametro) {
    return {
        type: "REVELAR_IMPORTAR_DESCRICAO",
        parametro
    }
}

export function revelarImportarClientesUsuarios(parametro) {
    return {
        type: "REVELAR_IMPORTAR_CLIENTES_USUARIOS",
        parametro
    }
}

export function conteudoBusca(parametro) {
    return {
        type: "CONTEUDO_BUSCA",
        parametro
    }
}

export function limpouBusca(parametro) {
    return {
        type: "LIMPOU_BUSCA",
        parametro
    }
}

export function realizouBusca(parametro) {
    return {
        type: "REALIZOU_BUSCA",
        parametro
    }
}

export function separadorLinhaBusca(parametro) {
    return {
        type: "SEPARADOR_LINHA_BUSCA",
        parametro
    }
}

export function realizouFiltro(parametro) {
    return {
        type: "REALIZOU_FILTRO",
        parametro
    }
}

export function limpadorFiltros(parametro) {
    return {
        type: "LIMPAR_FILTROS",
        parametro
    }
}

export function desabilitarAcoes(parametro) {
    return {
        type: "DESABILITAR_ACOES",
        parametro
    }
}

export function simcardSelecionados(parametro) {
    return {
        type: "SIMCARD_SELECIONADOS",
        parametro
    }
}

export function desabilitarEditar(parametro) {
    return {
        type: "DESABILITAR_EDITAR",
        parametro
    }
}

export function renderiza(parametro) {
    return {
        type: "RENDERIZA",
        parametro
    }
}

export function setRenderiza(parametro) {
    return {
        type: "SET_RENDERIZA",
        parametro
    }
}

export function operadoraSelecionadas(parametro) {
    return {
        type: "OPERADORAS_SELECIONADAS",
        parametro
    }
}

export function desabilitarAcoesCliente(parametro) {
    return {
        type: "DESABILITAR_ACOES_CLIENTE",
        parametro
    }
}

export function revelarMapa(parametro) {
    return {
        type: "REVELAR_MAPA",
        parametro
    }
}

export function revelarAlterarGrupo(parametro) {
    return {
        type: "REVELAR_ALTERAR_GRUPO",
        parametro
    }
}

export function revelarConfigurarGrupo(parametro) {
    return {
        type: "REVELAR_CONFIGURAR_GRUPO",
        parametro
    }
}

export function revelarExibirColunas(parametro) {
    return {
        type: "EXIBIR_COLUNAS",
        parametro
    }
}

/******************PERFIL DO USUARIO*********************/
export function visualizarDados(parametro) {
    return {
        type: "VISUALIZAR_DADOS",
        parametro
    }
}

export function visualizarDadosRefresh(parametro) {
    return {
        type: "VISUALIZAR_DADOS_REFRESH",
        parametro
    }
}

export function revelarDadosContaUsuario(parametro) {
    return {
        type: "REVELAR_DADOS_CONTA_USUARIO",
        parametro
    }
}

export function revelarPerfilContaUsuario(parametro) {
    return {
        type: "REVELAR_PERFIL_CONTA_USUARIO",
        parametro
    }
}

export function revelarAlterarLogin(parametro) {
    return {
        type: "REVELAR_ALTERAR_LOGIN",
        parametro
    }
}

export function revelarAlterarSenha(parametro) {
    return {
        type: "REVELAR_ALTERAR_SENHA",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES DO FILTRO DA TELA DE SIMCARDS *//*  *//*  *//*  */
export function filtrosArrayBarra(parametro) {
    return {
        type: "ADICIONAR_FILTRO_SIMCARD",
        parametro
    }
}

export function revelarBarraFiltragem(parametro) {
    return {
        type: "REVELAR_BARRA_FILTRAGEM",
        parametro
    }
}

export function recarregarBarraFiltragem(parametro) {
    return {
        type: "RECARREGAR_BARRA_FILTRAGEM",
        parametro
    }
}

export function renderizaOperadora(parametro) {
    return {
        type: "RENDERIZA_OPERADORA",
        parametro
    }
}

export function renderizaFiltroStatusMigracaoPlano(parametro) {
    return {
        type: "RENDERIZA_FILTRO_STATUS_MIGRACAO_PLANO",
        parametro
    }
}

export function mudarOrdenador(parametro) {
    return {
        type: "MUDAR_ORDENADOR",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES DA TELA DE CLIENTES VIRTUEYES *//*  *//*  *//*  */
export function revelarHistoricoAcessos(parametro) {
    return {
        type: "REVELAR_HISTORICO_ACESSO",
        parametro
    }
}

export function revelarCadastrarUsuario(parametro) {
    return {
        type: "REVELAR_CADASTRAR_USUARIO",
        parametro
    }
}

export function revelarEdicaoUsuario(parametro) {
    return {
        type: "REVELAR_EDICAO_USUARIO",
        parametro
    }
}

export function revelarClonarUsuario(parametro) {
    return {
        type: "REVELAR_EDICAO_USUARIO",
        parametro
    }
}

export function revelarAdicionarLogin(parametro) {
    return {
        type: "REVELAR_ADICIONAR_LOGIN",
        parametro
    }
}

export function revelarLogReset(parametro) {
    return {
        type: "REVELAR_LOG_RESET",
        parametro
    }
}

export function revelarAlterarAdicionarLogin(parametro) {
    return {
        type: "REVELAR_ALTERAR_ADICIONAR_LOGIN",
        parametro
    }
}

export function revelarConsultarSimcard(parametro) {
    return {
        type: "REVELAR_CONSULTAR_SIMCARD",
        parametro
    }
}

export function revelarDoppelgangerCliente(parametro) {
    return {
        type: "REVELAR_DOPPELGANGER_CLIENTE",
        parametro
    }
}

export function revelarProcessarPedido(parametro) {
    return {
        type: "REVELAR_PROCESSAR_PEDIDO",
        parametro
    }
}

export function revelarConcluirPedido(parametro) {
    return {
        type: "REVELAR_CONCLUIR_PEDIDO",
        parametro
    }
}

export function revelarCancelarPedido(parametro) {
    return {
        type: "REVELAR_CANCELAR_PEDIDO",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES DO MENU DE SMS *//*  *//*  *//*  */
export function revelarEnviarSmsModulo(parametro) {
    return {
        type: "REVELAR_ENVIAR_SMS_MODULO",
        parametro
    }
}

export function revelarVerConversasSms(parametro) {
    return {
        type: "REVELAR_VER_CONVERSAS_SMS",
        parametro
    }
}

export function revelarAtualizarSms(parametro) {
    return {
        type: "REVELAR_ATUALIZAR_SMS",
        parametro
    }
}

export function revelarNovoTemplateSms(parametro) {
    return {
        type: "REVELAR_NOVO_TEMPLATE_SMS",
        parametro
    }
}

export function revelarEditarTemplateSms(parametro) {
    return {
        type: "REVELAR_EDITAR_TEMPLATE_SMS",
        parametro
    }
}

export function revelarExcluirTemplateSms(parametro) {
    return {
        type: "REVELAR_EXCLUIR_TEMPLATE_SMS",
        parametro
    }
}

export function revelarRelatorioEnvioSms(parametro) {
    return {
        type: "REVELAR_RELATORIO_ENVIO_SMS",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES CENTRAL DE ACOES - CLIENTE *//*  *//*  *//*  */
export function solicitacaConcluirVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_CONCLUIR_CENTRAL_ACOES",
        parametro
    }
}

export function solicitacaCancelarVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_CANCELAR_CENTRAL_ACOES",
        parametro
    }
}

export function solicitacaReenviarProtocoloVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_REENVIAR_PROTOCOLO_CENTRAL_ACOES",
        parametro
    }
}

export function solicitacaReenviarTrocaOcorrenciaVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_REENVIAR_TROCA_OCORRENCIA",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES CENTRAL DE ACOES - OPERADORA *//*  *//*  *//*  */
export function confimarRecebimentoVirtueyes(parametro) {
    return {
        type: "CONFIRMAR_RECEBIMENTO_CENTRAL_ACOES",
        parametro
    }
}

export function reenviarEmailVirtueyes(parametro) {
    return {
        type: "REENVIAR_EMAIL_CENTRAL_ACOES",
        parametro
    }
}

export function novaSolicitacaoVirtueyes(parametro) {
    return {
        type: "NOVA_SOLICITACAO_CENTRAL_ACOES",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES CENTRAL DE NOVO PEDIDO SMS *//*  *//*  *//*  */
export function revelarNovoPedidoSMS(parametro) {
    return {
        type: "REVELAR_NOVO_PEDIDO_SMS",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES PEDIDOS - SIMCARDS *//*  *//*  *//*  */
export function revelarNovoPedidoSimcard(parametro) {
    return {
        type: "REVELAR_NOVO_PEDIDO_SIMCARD",
        parametro
    }
}

export function mudarQtdSimcardPedido(parametro) {
    return {
        type: "QTD_SIMCARDS_PEDIDO",
        parametro
    }
}

export function mudarBodyCancelamentoComReposicao(parametro) {
    return {
        type: "BODY_CANCELAMENTO_REPOSICAO",
        parametro
    }
}

export function revelarMostrarEmDispositivos(parametro) {
    return {
        type: "REVELAR_MOSTRAR_EM_DISPOSITIVOS",
        parametro
    }
}

export function revelarExportarDetalhamento(parametro) {
    return {
        type: "REVELAR_EXPORTAR_DETALHAMENTO",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES MODULO FINANCEIRO *//*  *//*  *//*  */
export function revelarExportarDetalhamentoFinanceiro(parametro) {
    return {
        type: "REVELAR_EXPORTAR_DETALHAMENTO_FINANCEIRO",
        parametro
    }
}

export function revelarDownloadBoleto(parametro) {
    return {
        type: "REVELAR_DOWNLOAD_BOLETO",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES MODULO AUTOMAÇÃO *//*  *//*  *//*  */
export function revelarNovaRegra(parametro) {
    return {
        type: "NOVA_REGRA_AUTOMACAO",
        parametro
    }
}

export function revelarEditarRegra(parametro) {
    return {
        type: "EDITAR_REGRA_AUTOMACAO",
        parametro
    }
}

export function revelarExcluirRegra(parametro) {
    return {
        type: "EXCLUIR_REGRA_AUTOMACAO",
        parametro
    }
}

export function revelarHabilitarRegra(parametro) {
    return {
        type: "HABILITAR_REGRA_AUTOMACAO",
        parametro
    }
}

export function revelarDesabilitarRegra(parametro) {
    return {
        type: "DESABILITAR_REGRA_AUTOMACAO",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES CENTRAL DE ACOES - BLOQUEIO *//*  *//*  *//*  */
export function revelarSolicitacaBloquearVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_BLOQUEAR_CENTRAL_ACOES",
        parametro
    }
}

export function revelarSolicitacaRemoverBloqueioVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_REMOVER_BLOQUEIO_CENTRAL_ACOES",
        parametro
    }
}

export function revelarSolicitacaEnviarAvisoVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_ENVIAR_AVISO_CENTRAL_ACOES",
        parametro
    }
}

export function revelarSolicitacaExportarVirtueyes(parametro) {
    return {
        type: "SOLICITACAO_EXPORTAR_CENTRAL_ACOES",
        parametro
    }
}

/*  *//*  *//*  *//* AÇÕES MODULO E CENTRAL DE MENSAGENS *//*  *//*  *//*  */

export function atualizarMensagensCabecalho(parametro) {
    return {
        type: "ATUALIZAR_MENSAGENS_CABECALHO",
        parametro
    }
}

export function atualizarMensagemCabecalhoSelecionada(parametro) {
    return {
        type: "ATUALIZAR_MENSAGEM_CABECALHO_SELECIONADA",
        parametro
    }
}

export function revelarMensagemCabecalhoSelecionada(parametro) {
    return {
        type: "REVELAR_MENSAGEM_CABECALHO_SELECIONADA",
        parametro
    }
}

export function revelarNovaMensagem(parametro) {
    return {
        type: "REVELAR_NOVA_MENSAGEM",
        parametro
    }
}

export function revelarMensagemTabela(parametro) {
    return {
        type: "REVELAR_MENSAGEM_TABELA",
        parametro
    }
}

//  ALERTAS    //
export function atualizarAlertasCabecalho(parametro) {
    return {
        type: "ATUALIZAR_ALERTAS_CABECALHO",
        parametro
    }
}

export function atualizarAlertaCabecalhoSelecionado(parametro) {
    return {
        type: "ATUALIZAR_ALERTA_CABECALHO_SELECIONADO",
        parametro
    }
}

export function revelarAlertaCabecalhoSelecionado(parametro) {
    return {
        type: "REVELAR_ALERTA_CABECALHO_SELECIONAD0",
        parametro
    }
}

export function revelarAlertaTabela(parametro) {
    return {
        type: "REVELAR_ALERTA_TABELA",
        parametro
    }
}

/*********************USUARIOS CLIENTE************************* */
export function revelarNovoUsuarioCliente(parametro) {
    return {
        type: "REVELAR_NOVO_USUARIO_CLIENTE",
        parametro
    }
}

export function revelarEditarUsuarioCliente(parametro) {
    return {
        type: "REVELAR_EDITAR_USUARIO_CLIENTE",
        parametro
    }
}

export function revelarExcluirUsuarioCliente(parametro) {
    return {
        type: "REVELAR_EXCLUIR_USUARIO_CLIENTE",
        parametro
    }
}

export function revelarReenviarLinkPrimeiroAcessoUsuarioCliente(parametro) {
    return {
        type: "REVELAR_REENVIAR_LINK_PRIMEIRO_ACESSO_USUARIO_CLIENTE",
        parametro
    }
}

/********************PAINEL ADMINISTRATIVO*********************** */
export function revelarNovoUsuario(parametro) {
    return {
        type: "REVELAR_NOVO_USUARIO",
        parametro
    }
}

export function revelarEditarUsuario(parametro) {
    return {
        type: "REVELAR_EDITAR_USUARIO",
        parametro
    }
}

export function revelarExcluirUsuario(parametro) {
    return {
        type: "REVELAR_EXCLUIR_USUARIO",
        parametro
    }
}

export function revelarNovoCliente(parametro) {
    return {
        type: "REVELAR_NOVO_CLIENTE",
        parametro
    }
}

export function revelarEditarCliente(parametro) {
    return {
        type: "REVELAR_EDITAR_CLIENTE",
        parametro
    }
}

export function revelarExcluirCliente(parametro) {
    return {
        type: "REVELAR_EXCLUIR_CLIENTE",
        parametro
    }
}

export function revelarNovoElemento(parametro) {
    return {
        type: "REVELAR_NOVO_ELEMENTO",
        parametro
    }
}

export function revelarEditarElemento(parametro) {
    return {
        type: "REVELAR_EDITAR_ELEMENTO",
        parametro
    }
}

export function revelarExcluirElemento(parametro) {
    return {
        type: "REVELAR_EXCLUIR_ELEMENTO",
        parametro
    }
}

export function revelarNovoModulo(parametro) {
    return {
        type: "REVELAR_NOVO_MODULO",
        parametro
    }
}

export function revelarEditarModulo(parametro) {
    return {
        type: "REVELAR_EDITAR_MODULO",
        parametro
    }
}

export function revelarExcluirModulo(parametro) {
    return {
        type: "REVELAR_EXCLUIR_MODULO",
        parametro
    }
}

export function revelarNovoPerfil(parametro) {
    return {
        type: "REVELAR_NOVO_PERFIL",
        parametro
    }
}

export function revelarEditarPerfil(parametro) {
    return {
        type: "REVELAR_EDITAR_PERFIL",
        parametro
    }
}

export function revelarExcluirPerfil(parametro) {
    return {
        type: "REVELAR_EXCLUIR_PERFIL",
        parametro
    }
}

export function revelarNovoSistema(parametro) {
    return {
        type: "REVELAR_NOVO_SISTEMA",
        parametro
    }
}

export function revelarEditarSistema(parametro) {
    return {
        type: "REVELAR_EDITAR_SISTEMA",
        parametro
    }
}

export function revelarExcluirSistema(parametro) {
    return {
        type: "REVELAR_EXCLUIR_SISTEMA",
        parametro
    }
}

export function revelarNovoTela(parametro) {
    return {
        type: "REVELAR_NOVO_TELA",
        parametro
    }
}

export function revelarEditarTela(parametro) {
    return {
        type: "REVELAR_EDITAR_TELA",
        parametro
    }
}

export function revelarExcluirTela(parametro) {
    return {
        type: "REVELAR_EXCLUIR_TELA",
        parametro
    }
}

export function mudarBreadCrumbs(parametro) {
    return {
        type: "BREAD_CRUMB",
        parametro
    }
}

/*******************PERMISSAO ELEMENTO*******************/
export function mudarPermissaoElemento(parametro) {
    return {
        type: "PERMISSAO_ELEMENTO",
        parametro
    }
}

export function mudarPermissaoElementoAcao(parametro) {
    return {
        type: "PERMISSAO_ELEMENTO_ACAO",
        parametro
    }
}

//OUTROS
export function mudarPermissaoElementoOutrosConfiguracoesExibirColunas(parametro) {
    return {
        type: "PERMISSAO_ELEMENTO_OUTROS_CONFIGURACOES_EXIBIR_COLUNAS",
        parametro
    }
}

export function mudarPermissaoElementoOutrosConfiguracoesConfigurarGrupos(parametro) {
    return {
        type: "PERMISSAO_ELEMENTO_OUTROS_CONFIGURACOES_CONFIGURAR_GRUPOS",
        parametro
    }
}

export function mudarPermissaoElementoOutrosInformacoesConsultarCobertura(parametro) {
    return {
        type: "PERMISSAO_ELEMENTO_OUTROS_INFORMACOES_CONSULTAR_COBERTURA",
        parametro
    }
}

export function mudarPermissaoElementoOutrosMensagensVisualizarMensagens(parametro) {
    return {
        type: "PERMISSAO_ELEMENTO_OUTROS_MENSAGENS_VISUALIZAR_MENSAGENS",
        parametro
    }
}

export function revelarNovaEmpresa(parametro) {
    return {
        type: "NOVA_EMPRESA_FATURAS",
        parametro
    }
}

export function revelarNovoCicloVida(parametro) {
    return {
        type: "NOVO_CICLO_FATURAS",
        parametro
    }
}

/*******************FATURAS OPERADORAS*******************/
export function revelarNovaOperadora(parametro) {
    return {
        type: "NOVA_OPERADORA_FATURAS",
        parametro
    }
}

export function revelarEditarOperadora(parametro) {
    return {
        type: "EDITAR_OPERADORA_FATURAS",
        parametro
    }
}

export function revelarExcluirOperadora(parametro) {
    return {
        type: "EXCLUIR_OPERADORA_FATURAS",
        parametro
    }
}

/*******************FATURAS PLANOS*******************/
export function revelarNovoPlano(parametro) {
    return {
        type: "NOVO_PLANO_FATURAS",
        parametro
    }
}

export function revelarEditarPlano(parametro) {
    return {
        type: "EDITAR_PLANO_FATURAS",
        parametro
    }
}

export function revelarExcluirPlano(parametro) {
    return {
        type: "EXCLUIR_PLANO_FATURAS",
        parametro
    }
}

/*******************FATURAS FORNECEDORES*******************/

export function revelarNovaConta(parametro) {
    return {
        type: "NOVA_CONTA_FATURAS",
        parametro
    }
}

export function revelarEditarConta(parametro) {
    return {
        type: "EDITAR_CONTA_FATURAS",
        parametro
    }
}

export function revelarEditarEmpresa(parametro) {
    return {
        type: "EDITAR_EMPRESA_FATURAS",
        parametro
    }
}

export function revelarExcluirConta(parametro) {
    return {
        type: "EXCLUIR_CONTA_FATURAS",
        parametro
    }
}

/*******************FATURAS FORNECEDORES*******************/
export function revelarNovoFornecedor(parametro) {
    return {
        type: "NOVO_FORNECEDOR_FATURAS",
        parametro
    }
}

export function revelarEditarFornecedor(parametro) {
    return {
        type: "EDITAR_FORNECEDOR_FATURAS",
        parametro
    }
}

export function revelarExcluirFornecedor(parametro) {
    return {
        type: "EXCLUIR_FORNECEDOR_FATURAS",
        parametro
    }
}

/*******************FATURAS SERVICOS*******************/
export function revelarNovoServico(parametro) {
    return {
        type: "NOVO_SERVICO_FATURAS",
        parametro
    }
}

export function revelarEditarServico(parametro) {
    return {
        type: "EDITAR_SERVICO_FATURAS",
        parametro
    }
}

export function revelarExcluirServico(parametro) {
    return {
        type: "EXCLUIR_SERVICO_FATURAS",
        parametro
    }
}

export function revelarEditarCicloVida(parametro) {
    return {
        type: "EDITAR_CICLO_FATURAS",
        parametro
    }
}

export function revelarExcluirEmpresa(parametro) {
    return {
        type: "EXCLUIR_EMPRESA_FATURAS",
        parametro
    }
}

export function revelarExcluirCicloVida(parametro) {
    return {
        type: "EXCLUIR_CICLO_FATURAS",
        parametro
    }
}

export function revelarDispositivosAlterarServicos(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_SERVICOS",
        parametro
    }
}

export function revelarDispositivosAlterarPlano(parametro) { // DISPOSITIVOS

    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_PLANO",
        parametro
    }
}

export function revelarAlterarplanoFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_PLANO_FATURAS",
        parametro
    }
}

//
export function revelarAlteracaoprazodepermanenciaFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_PRAZO_PERMANENCIA_FATURAS",
        parametro
    }
}

export function revelarDispositivosAlterarCicloDeVida(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_CICLO_VIDA",
        parametro
    }
}

export function revelarAlterarciclodevidaFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_CICLO_VIDA",
        parametro
    }
}

export function revelarResetFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_RESET",
        parametro
    }
}

export function revelarLiberarSMSFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_LIBERAR_SMS",
        parametro
    }
}

// revelarDesativarSMSFaturas
export function revelarDesativarSMSFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_DESATIVAR_LIBERAR_SMS_FATURAS",
        parametro
    }
}

export function revelarDispositivosAlterarIccid(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_ICCID",
        parametro
    }
} //
export function revelarAlterarICCIDFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_ICCID_FATURAS",
        parametro
    }
}

export function revelarDispositivosAlterarConta(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_CONTA",
        parametro
    }
}

export function revelarAlterarcontaFaturas(parametro) {
    return {
        type: "DIPOSITIVOS_FATURAS_ALTERAR_CONTA_FATURAS",
        parametro
    }
}

export function revelarImportarFebrabans(parametro) {
    return {
        type: "REVELAR_IMPORTAR_FEBRABANS",
        parametro
    }
}

export function revelarExcluirFebraban(parametro) {
    return {
        type: "REVELAR_EXCLUIR_FEBRABAN",
        parametro
    }
}

export function revelarAssociarFatura(parametro) {
    return {
        type: "REVELAR_ASSOCIAR_FATURA",
        parametro
    }
}

export function revelarAlterarPrazoPermanencia(parametro) {
    return {
        type: "REVELAR_ALTERAR_PRAZO_PERMANENCIA",
        parametro
    }
}

export function esconderMenuAcoesDispositivosBloqueio(parametro) {
    return {
        type: "ESCONDER_ACOES_DISPOSITIVOS_BLOQUEIO",
        parametro
    }
}

/*******************FATURAS PEDIDOS*******************/
export function revelarNovoFatPedidos(parametro) {
    return {
        type: "REVELAR_NOVO_FATPEDIDO",
        parametro
    }
}

export function revelarEditarFatPedidos(parametro) {
    return {
        type: "REVELAR_EDITAR_FATPEDIDO",
        parametro
    }
}

export function revelarExcluirFatPedidos(parametro) {
    return {
        type: "REVELAR_EXCLUIR_FATPEDIDO",
        parametro
    }
}

export function revelarEditarSolicitacao(parametro) {
    return {
        type: "REVELAR_EDITAR_SOLICITACAO",
        parametro
    }
}

export function revelarRelatorioLinhasAfetadas(parametro) {
    return {
        type: "REVELAR_RELATORIO_LINHAS_AFETADAS",
        parametro
    }
}

export function revelarExcluirSolicitacao(parametro) {
    return {
        type: "REVELAR_EXCLUIR_SOLICITACAO",
        parametro
    }
}

export function revelarFiltrarSolicitacaoPorArquivo(parametro) {
    return {
        type: "REVELAR_FILTRAR_SOLICITACAO_POR_ARQUIVO",
        parametro
    }
}

export function mudarFiltroPorArquivoFatSolicitacao(parametro) {
    return {
        type: "MUDAR_FILTRO_POR_ARQUIVO_FAT_SOLICITACAO",
        parametro
    }
}

export function limparFiltroPorArquivoFatSolicitacao(parametro) {
    return {
        type: "LIMPAR_FILTRO_POR_ARQUIVO_FAT_SOLICITACAO",
        parametro
    }
}

export function revelarMostrarEmDispositivosSolicitacao(parametro) {
    return {
        type: "REVELAR_MOSTRAR_EM_DISPOSITIVO_SOLICITACAO",
        parametro
    }
}

export function revelarReenviarSolicitacaoFaturas(parametro) {
    return {
        type: "REVELAR_REENVIAR_SOLICITACAO_FATURAS",
        parametro
    }
}

export function revelarMostrarEmDispositivosSolicitacaoReset(parametro) {
    return {
        type: "REVELAR_MOSTRAR_EM_DISPOSITIVO_SOLICITACAO_RESET",
        parametro
    }
}

export function revelarConcluirFatSolicitacao(parametro) {
    return {
        type: "REVELAR_CONCLUIR_FAT_SOLICITACAO",
        parametro
    }
}

export function abrirModal(parametro) {
    return {
        type: "ABRIR_MODAL",
        parametro
    }
}

export function clicouFora(parametro) {

    return {
        type: "CLICOU_FORA",
        parametro
    }
}

export function abrirSubItem(parametro) {

    return {
        type: "ABRIR_SUB_ITEM",
        parametro
    }
}

export function revelarAbrirTelaConciliacao(parametro) {
    return {
        type: "REVELAR_ABRIR_TELA_CONCILIACAO",
        parametro
    }
}


export function revelarCadastrarFatura(parametro) {
    return {
        type: "REVELAR_CADASTRAR_FATURA",
        parametro
    }
}

export function revelarEditarFatura(parametro) {
    return {
        type: "REVELAR_EDITAR_FATURA",
        parametro
    }
}

export function revelarDeletarFatura(parametro) {
    return {
        type: "REVELAR_DELETAR_FATURA",
        parametro
    }
}

export function revelarExcluirFatura(parametro) {
    return {
        type: "REVELAR_EXCLUIR_FATURA",
        parametro
    }
}

export function revelarContestarFatura(parametro) {
    return {
        type: "REVELAR_CONTESTAR_FATURA",
        parametro
    }
}

export function revelarRecalcularFatura(parametro) {
    return {
        type: "REVELAR_RECALCULAR_FATURA",
        parametro
    }
}

export function revelarRecalcularFaturaReferencia(parametro) {
    return {
        type: "REVELAR_RECALCULAR_FATURA_REFERENCIA",
        parametro
    }
}

export function revelarRecalcularFaturaConta(parametro) {
    return {
        type: "REVELAR_RECALCULAR_FATURA_CONTA",
        parametro
    }
}

export function revelarRecalcularFaturaEmpresa(parametro) {
    return {
        type: "REVELAR_RECALCULAR_FATURA_EMPRESA",
        parametro
    }
}

export function revelarRelatorioPreviewFatura(parametro) {
    return {
        type: "REVELAR_RELATORIO_PREVIEW_FATURA",
        parametro
    }
}

export function revelarRelatorioFatura(parametro) {
    return {
        type: "REVELAR_RELATORIO_FATURA",
        parametro
    }
}

export function revelarRelatorioNotaFiscal(parametro) {
    return {
        type: "REVELAR_RELATORIO_NOTA_FISCAL",
        parametro
    }
}

export function revelarRelatorioNotaFiscalGeral(parametro) {
    return {
        type: "REVELAR_RELATORIO_NOTA_FISCAL_GERAL",
        parametro
    }
}

export function revelarRelatorioHistorico(parametro) {
    return {
        type: "REVELAR_RELATORIO_HISTORICO",
        parametro
    }
}

export function revelarRelatorioFebraban(parametro) {
    return {
        type: "REVELAR_RELATORIO_FEBRABAN",
        parametro
    }
}

export function revelarRelatorioFaturaDispositivos(parametro) {
    return {
        type: "REVELAR_RELATORIO_FATURA_DISPOSITIVOS",
        parametro
    }
}

export function revelarRelatorioConciliacao(parametro) {
    return {
        type: "REVELAR_RELATORIO_CONCILIACAO",
        parametro
    }
}

export function revelarAtualizarConciliacao(parametro) {
    return {
        type: "REVELAR_ATUALIZAR_CONCILIACAO",
        parametro
    }
}

export function valorCdFatura(parametro) {
    return {
        type: "VALOR_CD_FATURA",
        parametro
    }
}

export function revelarNovoPedidoSmsVirtueyes(parametro) {
    return {
        type: "REVELAR_NOVO_PEDIDO_SMS_VIRTUEYES",
        parametro
    }
}

export function paginaDashboardFaturas(parametro) {
    return {
        type: "PAGINA_DASHBOARD_FATURAS",
        parametro
    }
}

export function revelarNovoMenu(parametro) {
    return {
        type: "REVELAR_NOVO_MENU",
        parametro
    }
}

export function revelarEditarMenu(parametro) {
    return {
        type: "REVELAR_EDITAR_MENU",
        parametro
    }
}

export function revelarExcluirMenu(parametro) {
    return {
        type: "REVELAR_EXCLUIR_MENU",
        parametro
    }
}


export function revelarCadastroClienteVirtueyes(parametro) {
    return {
        type: "REVELAR_CADASTRO_CLIENTE_VIRTUEYES",
        parametro
    }
}

/*******************PAINEL RELATORIOS*******************/
export function revelarDownloadRelatorio(parametro) {
    return {
        type: "REVELAR_DOWNLOAD_RELATORIO",
        parametro
    }
}

export function revelarCadastroCliente(parametro) {
    return {
        type: "REVELAR_CADASTRO_CLIENTE",
        parametro
    }
}

export function revelarGerarRelatorio(parametro) {
    return {
        type: "PAGINA_GERAR_RELATORIO",
        parametro
    }
}

export function revelarNovoRelease(parametro) {
    return {
        type: "REVELAR_NOVO_RELEASE",
        parametro
    }
}

export function revelarGerarRelatorios(parametro) {
    return {
        type: "REVELAR_GERAR_RELATORIOS",
        parametro
    }
}

export function revelarCadastroClienteNovo(parametro) {
    return {
        type: "REVELAR_CLIENTE_NOVO",
        parametro
    }
}

export function revelarCadastroClienteEditar(parametro) {
    return {
        type: "REVELAR_CLIENTE_EDITAR",
        parametro
    }
}

export function revelarCadastroClienteExcluir(parametro) {
    return {
        type: "REVELAR_CLIENTE_EXCLUIR",
        parametro
    }
}

export function revelarCadastroClienteCarregar(parametro) {
    return {
        type: "REVELAR_CLIENTE_CARREGAR",
        parametro
    }
}

export function revelarTransferirLinhaClienteFinal(parametro) {
    return {
        type: "REVELAR_TRANSFERIR_LINHA_CLIENTE_FINAL",
        parametro
    }
}

export function revelarNovoPedidoImportado(parametro) {
    return {
        type: "REVELAR_NOVO_PEDIDO_IMPORTADO",
        parametro
    }
}

export function revelarDeletarPedidoImportado(parametro) {
    return {
        type: "REVELAR_DELETAR_PEDIDO_IMPORTADO",
        parametro
    }
}

export function conciliacaoEmLote(parametro) {
    return {
        type: "CONCIALIACAO_EM_LOTE",
        parametro
    }
}

export function RevelarBuscarEFiltro(parametro) {
    return {
        type: "REVELAR_BUSCAR_E_FILTRO",
        parametro
    }
}

//ATRIBUIR & DESATRIBUIR LINHAS

export function revelarAtribuirLinhas(parametro) {
    return {
        type: "REVELAR_ATRIBUIR_LINHAS",
        parametro
    }
}

export function revelarDesatribuirLinhas(parametro) {
    return {
        type: "REVELAR_DESATRIBUIR_LINHAS",
        parametro
    }
}
