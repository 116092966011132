//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';/*eslint-disable*/
import { Image, Button} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import decryptJWT from '../../../utils/decryptJWT'
import {formatarMilhar} from '../../../utils/formatarMilhar'
//COMPONENTES
import CadastroPlano from './cadastro'
import DetalhamentoPlano from './detalhamento'
import MensagemAtencao from '../../componentesModais/mensagens/mensagemAtencao'
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import GirarSetaDropdown from '../../../utils/girarSetaDropdown'
//IMPORTAÇÕES ICONES
import { setaDropdown } from '../../../utils/alternanciaIcones'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarOrdenador, mudarManejador, limpouBusca, limpadorFiltros, realizouBusca, revelarBarraFiltragem, realizouFiltro, mudarFiltros, revelarNovoPlano, revelarEditarPlano, revelarExcluirPlano, desabilitarAcoes } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../spinnerVeye/spinnerVeye.js'
//ESTILIZACAO
import '../../../css/painelFaturas/fatPlanos/fatPlanos.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function TabelaPlanos(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    const [cdPlano, setCdPlano] = useState()
    const [dsNomePlano, setDsNomePlano] = useState()
    const [cdFornecedor, setCdFornecedor] = useState()
    const [dsFornecedor, setDsFornecedor] = useState()
    const [cdOperadora, setCdOperadora] = useState()
    const [dsOperadora, setDsOperadora] = useState()
    const [dsValorTotal, setDsValorTotal] = useState()
    const [n0Simcards, setN0Simcards] = useState()
    const [arrayServicos, setArrayServicos] = useState([])
    const [isSelect, setIsSelect] = useState(false);
    //HOOKS MENSAGENS
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let estadoExpansaoMenu = useSelector(state => state.manejadorState.expandirNavegacao);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let NovoPlano = useSelector(state => state.faturasState.revelarNovoPlano);
    let editarPlano = useSelector(state => state.faturasState.revelarEditarPlano);
    let ordenador = useSelector(state => state.manejadorState.ordenador);
    let excluirPlano = useSelector(state => state.faturasState.revelarExcluirPlano);
    let temFiltros = false;

    const cd_pav_usuario = decryptJWT('codigo')
    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(635) // 'fatPlanosTabela'
            setColetaUsabilidade(false)
        }
    },[])

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("faturasPlanos"))
        despacho(desabilitarAcoes(true))
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(ordenador){
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const queryString = 'paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario
                    const { data } = await api.get('/m1/consultar/fat_plano?'+queryString);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    ordenador: ordenador,
                    cd_pav_usuario: cd_pav_usuario
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/fat_plano", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/fat_plano?paginaAtual='+contador+'&ordenador='+ordenador+'&carregarLimit='+valorLimite+'&cd_pav_usuario='+cd_pav_usuario);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros, ordenador])

  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
        if (contador === i){
            let k = i
            for(let j=0;j<data.length;j++){
            itensVar[k] = data[j]
            k++
            }
        }
        if(i == (totalItens-1)){
            setItens(JSON.parse(JSON.stringify(itensVar)))
        }
        }
    }
  
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/fat_plano?contar=true&buscar='+conteudoBusca+'&ordenador='+ordenador+'&cd_pav_usuario='+cd_pav_usuario);
            }else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true,
                    ordenador: ordenador,
                    cd_pav_usuario: cd_pav_usuario
                }
                apiData = await api.post("/m1/filtrar/fat_plano", dados);
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    
    const colunas = [{
        dataField: 'cd_fat_plano',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("cd_fat_plano",'cd_fat_plano')}>
        Cód. plano
        <Image id="cd_fat_planocd_fat_plano" className='d-none' src={setaDropdown()}/>
        </button>,
        headerClasses: 'nao-selecionavel',
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_plano',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_plano",'ds_plano')}>
        Nome do plano
        <Image id="ds_planods_plano" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_apelido',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_apelido",'ds_apelido')}>
        Operadora
        <Image id="ds_apelidods_apelido" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'ds_fat_nome_fantasia',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("ds_fat_fornecedor",'ds_fat_fornecedor')}>
        Fornecedor
        <Image id="ds_fat_fornecedords_fat_fornecedor" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'n2_valor_plano',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n2_valor_plano",'n2_valor_plano')}>
        Valor
        <Image id="n2_valor_planon2_valor_plano" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">R$ {cell?formatarMilhar(cell):'0.00'}</p>;
        }
    },
    {
        dataField: 'n0_simcards',
        headerClasses: 'nao-selecionavel',
        text:  <button className='btn-header-nome fonte-cor-1' onClick={() => handlerOrdenador("n0_simcards",'n0_simcards')}>
        Número de SIM cards
        <Image id="n0_simcardsn0_simcards" className='d-none' src={setaDropdown()}/>
        </button>,
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    }]

    function handlerDesativarImagens(imagem, terminacao){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))

        imagem.classList.remove("d-none")
        imagem.classList.add("icone-ordenacao-"+terminacao)
    }

    function handleRemoverImagens(imagem){
        const elementosAtivosDESC = document.querySelectorAll(".icone-ordenacao-DESC");
        const elementosAtivosASC = document.querySelectorAll(".icone-ordenacao-ASC");
        elementosAtivosDESC.forEach(el => el.classList.add("d-none"))
        elementosAtivosASC.forEach(el => el.classList.add("d-none"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        elementosAtivosDESC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-ASC"))
        elementosAtivosASC.forEach(el => el.classList.remove("icone-ordenacao-DESC"))
        imagem.classList.remove("icone-ordenacao-ASC")
        imagem.classList.remove("icone-ordenacao-DESC")
    }

    async function handlerOrdenador(nomeOrdenador,nomeDiferenciado){
        let isItDescrescente = false
        const imagem = document.getElementById(nomeOrdenador+nomeDiferenciado)
        //verifica esta mudando a forma de ordenacao no mesmo elemento
        if(nomeOrdenador === ordenador.split(' ASC')[0] || nomeOrdenador === ordenador.split(' DESC')[0]){
            await ordenador.split(' ').map((item_ordenador,i)=>{
                //verifica se existe tal item e muda para decrescente
                if(item_ordenador === 'ASC'){
                    //Sera tal ordenador para decrescente
                    ordenador = nomeOrdenador +' DESC'
                    isItDescrescente = true
                    //transforma a imagem para o modo DESC ao qual mosta a imagem normalmente e faz a limpeza dos outros icones
                    handlerDesativarImagens(imagem,'ASC')            
                }
                if(item_ordenador === 'DESC'){
                    //Sera tal ordenador para crescente
                    ordenador = ''
                    isItDescrescente = false
                    //transforma a imagem para o modo ASC ao qual mosta a imagem rotacionada 180deg e faz a limpeza dos outros icones
                    handleRemoverImagens(imagem)
                }
            })
        }else if(!isItDescrescente){
            ordenador = nomeOrdenador +' ASC'
            //Adicaiona a classe de crescente para a imagem e faz a limpeza dos outros icones
            handlerDesativarImagens(imagem,'DESC')
        }
        despacho(mudarOrdenador(JSON.parse(JSON.stringify(ordenador))));        
    }

    const itemExpandido = {
        className: 'fundo-cor-1',
        renderer: (row, rowIndex) => (
            <div className={estadoExpansaoMenu===true?"expand-row-tabela-planos-faturas-1":"expand-row-tabela-planos-faturas-2"}>
                <DetalhamentoPlano cdPlano={row.cd_fat_plano} fatura={row}/>
            </div>
        ),
        expandHeaderColumnRenderer: (row, rowIndex) => (
            <div className="fundo-cor-1"></div>
        ),
        expandColumnRenderer: ( rowKey ) => {
            return (
                <Button className="botao-expandir-row" onClick={e => GirarSetaDropdown( rowKey.rowKey )}>
                    <Image id={ rowKey.rowKey } className="icone-botao-expandir-row nao-selecionavel" src={setaDropdown()}/>
                </Button>
            );
        },
        showExpandColumn: true, // ADICIONAR VALIDAÇÃO DE PERMISSÔES
        expandByColumnOnly: true,
        headerClasses: 'tabela-coluna-primeira-header',
    };

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            setIsSelect(isSelect)
            if(isSelect){
                setCdPlano(row.cd_fat_plano)
                setDsNomePlano(row.ds_plano)
                setN0Simcards(row.n0_simcards)
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    async function handleDeletar(){
        ColetaClickstream(645) // 'fatPlanosTabelaDropAcoesExcluirPlanoExcluir'
        setSpinAtivo(true)
        await api.get('/m1/deletar/fat_plano?cd_fat_plano='+cdPlano+'&cd_pav_usuario='+decryptJWT('codigo'))
        .then(function (response) {
            if(response.data.status === 600){
                setMensagemErro(response.data.info)
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirPlano(false))
                handleMensagemErro(false)
                setSpinAtivo(false)
            }else{
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirPlano(false))
                handleMensagemSucesso(false)
                setRenderizar(!renderizar)
                setSpinAtivo(false)
            }
        })
        .catch(function (error) {
            setMensagemErro(error.response.data.info)
            handleMensagemErro(false)
            setSpinAtivo(false)
        })
    }

    useEffect(() => {
        if(excluirPlano === true){
            setRevelarMensagemAtencao(true)
        }
    },[excluirPlano])

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                setRevelarMensagemAtencao(false)
                handleDeletar()
            } else {
                ColetaClickstream(646) // 'fatPlanosTabelaDropAcoesExcluirPlanoCancelar'
                setRevelarMensagemAtencao(false)
                despacho(revelarExcluirPlano(false))
            }
        }
    }


    


    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    return(
        <div className='container-tela'>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }    
            { NovoPlano === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroPlano
                            tituloJanela={'Cadastrar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                        >
                        </CadastroPlano>
                    </div>
                </div>
            }
            { editarPlano === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroPlano
                            tituloJanela={'Editar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            cdPlano={cdPlano}
                            dsNomePlano={dsNomePlano}
                            cdFornecedor={cdFornecedor}
                            dsFornecedor={dsFornecedor}
                            cdOperadora={cdOperadora}
                            dsOperadora={dsOperadora}
                            dsValorTotal={dsValorTotal}
                            n0Simcards={n0Simcards}
                            arrayServicos={arrayServicos}
                        >
                        </CadastroPlano>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                classes="tabela"
                                condensed={true}
                                keyField='cd_fat_plano' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                expandRow={itemExpandido}
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Plano "+ dsNomePlano +" excluído."}
                    mensagemBotao={"Continuar"}
                />
            }
            { excluirPlano &&
                <MensagemAtencao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Excluir plano"}
                    mensagemParagrafo1={"Tem certeza que deseja excluir o plano "+ dsNomePlano +"?"}
                    mensagemBotao={"Excluir"}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(TabelaPlanos));