import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  alterarDadosDashboardMigracaoPlano,
  mudarManejador,
} from "../../../../redux/actions";
import BarraDadosCliente from "../../../componentesModais/barraDadosCliente/barraDadosCliente";
import SpinnerVeye from "../../../spinnerVeye/spinnerVeye";
import GraficoAnaliseDeEconomia from "./GraficoAnaliseDeEconomia";
import GraficoAnaliseDeExcedentes from "./GraficoAnaliseDeExcedentes";
import GraficoPlanoReal from "./GraficoPlanoReal";
import GraficoStatusMigracao from "./GraficoStatusMigracao";
import { getDadosDashboard } from "./services";

//ESTLIZAÇÃO
import "../../../../css/painelDashboard/dashboard.css";
import ExibicaoSimultanea from "./ExibicaoSimultanea/ExibicaoSimultanea";
import "./index.css";

function Dashboard(props) {
  //OUTROS HOOKS
  const despacho = useDispatch();

  const [spinAtivo, setSpinAtivo] = useState(true);
  const [carregou, setCarregou] = useState(false);

  const [cicloGeral, setCicloGeral] = useState("atual");

  let visualizarDados = useSelector(
    (state) => state.virtueyesState.visualizarDados
  );

  function handleAlterarCicloGeral(ciclo) {
    setCicloGeral(ciclo);
    setCarregou(false);
  }

  useEffect(() => {
    async function handleCarregarGraficosDashboard() {
      const dash = await getDadosDashboard({
        grafico: "todos",
        ciclo: cicloGeral,
      });

      if (!dash) {
        setSpinAtivo(false);
        return;
      }

      despacho(alterarDadosDashboardMigracaoPlano(dash));

      setSpinAtivo(false);
      setCarregou(true);
    }

    if (!carregou) {
      despacho(mudarManejador("migracaoPlanoDashboard"));
      setSpinAtivo(true);
      handleCarregarGraficosDashboard();
    }
  }, [despacho, carregou, cicloGeral]);

  return (
    <>
      {visualizarDados === true && <BarraDadosCliente />}
      <ExibicaoSimultanea
        cicloGeral={cicloGeral}
        handleAlterarCicloGeral={handleAlterarCicloGeral}
      />
      <div className="container-dashboard-tela-migracao-plano">
        {spinAtivo && (
          <div className="componente-spinner-overlay">
            <div className="componente-spinner-container">
              <SpinnerVeye />
            </div>
          </div>
        )}
        <div className="container-dashboard-fundo-migracaoPlano">
          <GraficoPlanoReal />
          <GraficoAnaliseDeEconomia />
          <GraficoAnaliseDeExcedentes />
          <GraficoStatusMigracao />
        </div>
      </div>
    </>
  );
}

//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default React.memo(Dashboard);
