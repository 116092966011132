//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {api} from '../../../conexoes/api';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {Image} from 'react-bootstrap';
//COMPONENTES
import CadastroMenu from './cadastro'
import MensagemConfirmarExclusao from '../../componentesModais/mensagens/mensagemConfirmarExclusao';
import MensagemErro from '../../componentesModais/mensagens/mensagemErro'
import PaginacaoTabela from '../../../utils/paginacaoTabela'
import MensagemSucesso from '../../componentesModais/mensagens/mensagemSucesso'
import BarraFiltragem from '../../componentesModais/barraFiltragem/barraFiltragem'
import BarraDadosCliente from '../../componentesModais/barraDadosCliente/barraDadosCliente'
import { elipseVerde, elipseVermelho } from '../../../utils/alternanciaIcones'
//REDUX
import { useDispatch, useSelector, connect } from 'react-redux';
import { visualizarDados, mudarManejador, limpadorFiltros, limpouBusca, realizouBusca, mudarFiltros, revelarBarraFiltragem, realizouFiltro, revelarEditarMenu, revelarExcluirMenu, desabilitarAcoes } from "../../../redux/actions/index";
// SPINNER
import SpinerVeye from '../../../components/spinnerVeye/spinnerVeye.js'
//ESTILIZACAO

// COLETA CLICKSTREAM
import ColetaClickstream from '../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0

function Grid(props){
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(true);
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50);
    //HOOKS VARIÁVEIS
    const [cdMenu, setCdMenu] = useState('');
    const [cdMenuSuperior, setCdMenuSuperior] = useState('');
    const [cdMenuTipo, setCdMenuTipo] = useState('');
    const [cdTipo, setCdTipo] = useState('');
    const [dsDescricao, setDsDescricao] = useState('');
    const [dsIcone, setDsIcone] = useState('');
    const [dsMenuSuperior, setDsMenuSuperior] = useState('');
    const [dsTipoSuperior, setDsTipoSuperior] = useState('');
    const [dsMenu, setDsMenu] = useState('');
    const [dsMenuTipo, setDsMenuTipo] = useState('');
    const [dsRota, setDsRota] = useState('');
    const [flAcionar, setFlAcionar] = useState('');
    const [flAtivo, setFlAtivo] = useState('');
    const [flFilho, setFlFilho] = useState('');
    const [flPadrao, setFlPadrao] = useState('');
    const [n0Nivel, setN0Nivel] = useState('');
    const [n0Ordem, setN0Ordem] = useState('');

    //HOOKS MENSAGENS
    const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false)
    const [revelarMensagemErro, setRevelarMensagemErro] = useState(false)
    const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false)
    const [mensagemErro, setMensagemErro] = useState("Ocorreu um erro ao executar esta ação");
    //HOOKS REDUX
    const despacho = useDispatch();
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let novoMenu = useSelector(state => state.manejadorState.revelarNovoMenu);
    let editarMenu = useSelector(state => state.manejadorState.revelarEditarMenu);
    let excluirPerfil = useSelector(state => state.manejadorState.revelarExcluirMenu);
    let temFiltros = false;

    let itensVar = []
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(147) // 'pavMenuTabelaGrid'
            setColetaUsabilidade(false)
        }
    },[])

    function handleLimparDados(){
        contador = 0
        controladorPagina = 1
        totalPaginas = 0
        totalItens = 0
    }

    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                temFiltros = false;
            } else {
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }        
        despacho(limpadorFiltros(true))
    }, [])

    useEffect(() => {
        setSpinAtivo(true)
        despacho(mudarManejador("menu"))
        despacho(desabilitarAcoes(true))
        async function carregarTabela(){

             //clicou no botão de limpar a busca geral e retorna tudo ao estado original
             if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            //clicou no botão para filtro
            if(realizouFiltragem){
                despacho(realizouFiltro(false));
                restaurarConfigsIniciaisPagina()
            }

            if(conteudoBusca  && filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/consultar/menu?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(!filtros.vazio){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/filtrar/menu", dados);
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/consultar/menu?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
            setSpinAtivo(false)
        }
        carregarTabela();

    }, [paginaAtual, conteudoBusca, renderizar, filtros])
  
    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                    itensVar[k] = data[j]
                    k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
    }
   
    async function contarItens(){
        let arrayItens = []
        let apiData = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) { 
                apiData = await api.get('/m1/consultar/menu?contar="true"&buscar='+conteudoBusca);
            }else{
                const dados = {
                    paguinaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/menu", dados);
            }
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    function formatarFl(fl){
        return <p className="fonte-cor-1 label-tabela-1">
            {fl || fl == 0 ? 
                fl == 1 ?
                        <>
                        <Image className="img-eclipse-status" src={elipseVerde()}/>
                        Sim
                        </>
                    :
                    <>
                        <Image className="img-eclipse-status" src={elipseVermelho()}/>
                        Não
                    </> 
                :
                ''
            }
        </p>;
    }

    const colunas = [{
        dataField: 'cd_pav_menu',
        text: 'Cod. menu',
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell && cell !== null ? cell : cell === 0 ?'0':'-'}</p>;
        }
    },
    {
        dataField: 'ds_pav_menu',
        headerClasses: 'nao-selecionavel',
        text: 'Nome',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell&&cell!="undefined"?cell:'-'}</p>;
        }
    },
    {
        dataField: 'ds_pav_menu_tipo',
        headerClasses: 'nao-selecionavel',
        text: 'Menu tipo',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell&&cell!="undefined"?cell:'-'}</p>;
        }
    },
    {
        dataField: 'ds_menu_superior',
        headerClasses: 'nao-selecionavel',
        text: 'Menu pai',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell&&cell!="undefined"?cell:'-'}</p>;
        }
    },
    {
        dataField: 'ds_rota',
        headerClasses: 'nao-selecionavel',
        text: 'Rota',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell&&cell!="undefined"?cell:'-'}</p>;
        }
    },
    {
        dataField: 'ds_descricao',
        headerClasses: 'nao-selecionavel',
        text: 'Descrição',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell&&cell!="undefined"?cell:'-'}</p>;
        }
    },
    {
        dataField: 'ds_icone',
        headerClasses: 'nao-selecionavel',
        text: 'Icone',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell&&cell!="undefined"?cell:'-'}</p>;
        }
    },
   
    {
        dataField: 'fl_acionar',
        headerClasses: 'nao-selecionavel',
        text: 'Acionar',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{formatarFl(cell)}</p>;
        } 
    },
    {
        dataField: 'fl_ativo',
        headerClasses: 'nao-selecionavel',
        text: 'Ativo',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{formatarFl(cell)}</p>;
        }
    },
    {
        dataField: 'fl_filho',
        headerClasses: 'nao-selecionavel',
        text: 'filho',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{formatarFl(cell)}</p>;
        }
    },
    {
        dataField: 'fl_padrao',
        headerClasses: 'nao-selecionavel',
        text: 'Padrão',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{formatarFl(cell)}</p>;
        }
    },
    {
        dataField: 'n0_nivel',
        headerClasses: 'nao-selecionavel',
        text: 'Nível',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell && cell !== null ? cell : cell === 0 ?'0':'-'}</p>;
        }
    },
    {
        dataField: 'n0_ordem',
        headerClasses: 'nao-selecionavel',
        text: 'Ordem',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell && cell !== null ? cell : cell === 0 ?'0':'-'}</p>;
        }
    },
]

    const selecaoLinhas = {
        mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {
            if(isSelect){
                setCdMenu(row.cd_pav_menu)
                setCdMenuSuperior(row.cd_pav_menu_superior)
                setCdMenuTipo(row.cd_pav_menu_tipo)
                setCdTipo(row.cd_tipo)
                setDsDescricao(row.ds_descricao)
                setDsIcone(row.ds_icone)
                setDsTipoSuperior(row.ds_tipo_superior)
                setDsMenuSuperior(row.ds_menu_superior)
                setDsMenu(row.ds_pav_menu)
                setDsMenuTipo(row.ds_pav_menu_tipo)
                setDsRota(row.ds_rota)
                setFlAcionar(row.fl_acionar)
                setFlAtivo(row.fl_ativo)
                setFlFilho(row.fl_filho)
                setFlPadrao(row.fl_padrao)
                setN0Nivel(row.n0_nivel)
                setN0Ordem(row.n0_ordem)
                despacho(desabilitarAcoes(false));
            }
        },
        selectionRenderer: ({ mode, ...rest }) => {
            return (
                <label className="label-checkbox-cliente-virtueyes fonte-cor-1">
                    <input type={mode} id=" CLIENTEVEYE" name="CLIENTE"/>
                    <span className="checkbox-cliente-virtueyes radio-button-cor-1"></span>
                </label>
            )
        }
    };

    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            setCdMenu(row.cd_pav_menu);
            setDsMenu(row.ds_pav_menu);
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }


    async function handleDeletar(){
        setSpinAtivo(true)
        await api.get('/m1/deletar/menu?cd_pav_perfil='+'cdpavPerfil')
            .then(function (response) {
                handleMensagemSucesso(false)
                despacho(revelarExcluirMenu(false))
                setRenderizar(!renderizar)
                setSpinAtivo(false)
            })
            .catch(function (error) {
                despacho(revelarExcluirMenu(false))
                setMensagemErro(error.response.data.info)
                handleMensagemErro(false)
                setSpinAtivo(false)
            })
    }

    function handleCancelar(){
        setRevelarMensagemAtencao(false)
        setRevelarMensagemErro(false)
        setRevelarMensagemSucesso(false)
        setRenderizar(!renderizar)
    }

    function handleMensagemAtencao(confirmacao){
        if(revelarMensagemAtencao === false){
            setRevelarMensagemAtencao(true)
        }else{
            setRevelarMensagemAtencao(false)
            if(confirmacao === true){
                handleDeletar()
            }
        }
    }

    function handleMensagemErro(confirmacao){
        if(revelarMensagemErro === false){
            setRevelarMensagemErro(true)
        }else{
            setRevelarMensagemErro(false)
            if(confirmacao === true){
                //ALGUMA FUNÇÃO
            }
        }
    }

    function handleMensagemSucesso(confirmacao){
        if(revelarMensagemSucesso === false){
            setRevelarMensagemSucesso(true)
        }else{
            setRevelarMensagemSucesso(false)
            if(confirmacao === true){
                handleCancelar()
                setRenderizar(!renderizar)

            }
        }
    }
    return(
        <>
            {visualizarDados === true &&
                <BarraDadosCliente/>
            }
            { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { novoMenu === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroMenu
                            tituloJanela={'Cadastrar'}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            handleLimparDados={handleLimparDados}
                        >
                        </CadastroMenu>
                    </div>
                </div>
            }
            { editarMenu === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <CadastroMenu
                            tituloJanela={'Editar'}
                            cdMenu={cdMenu}
                            cdMenuSuperior={cdMenuSuperior}
                            cdMenuTipo={cdMenuTipo}
                            cdTipo={cdTipo}
                            dsDescricao={dsDescricao}
                            dsIcone={dsIcone}
                            dsMenuSuperior={dsMenuSuperior}
                            dsTipoSuperior={dsTipoSuperior}
                            dsMenu={dsMenu}
                            dsMenuTipo={dsMenuTipo}
                            dsRota={dsRota}
                            flAcionar={flAcionar}
                            flAtivo={flAtivo}
                            flFilho={flFilho}
                            flPadrao={flPadrao}
                            n0Nivel={n0Nivel}
                            n0Ordem={n0Ordem}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            handleLimparDados={handleLimparDados}
                        >
                        </CadastroMenu>
                    </div>
                </div>
            }
            { revelarBarraFiltro === true &&
                <BarraFiltragem/>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='cd_pav_menu' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                selectRow={ selecaoLinhas }
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                            <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
            { revelarMensagemSucesso &&
                <MensagemSucesso
                    handleMensagemSucesso={handleMensagemSucesso}
                    mensagemTitulo={"Sucesso"}
                    mensagemParagrafo1={"Menu "+ dsMenu +" deletado."}
                    mensagemBotao={"Continuar"}
                />
            }
            { excluirPerfil &&
                <MensagemConfirmarExclusao
                    handleMensagemAtencao={handleMensagemAtencao}
                    mensagemTitulo={"Deletar "+ dsMenu}
                />
            }
            { revelarMensagemErro &&
                <MensagemErro
                    handleMensagemErro={handleMensagemErro}
                    mensagemTitulo={mensagemErro}
                    mensagemParagrafo1={"Erro"}
                    mensagemParagrafo2={mensagemErro}
                    mensagemBotao={"Retornar"}
                />
            }
        </>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));