//IMPORTAÇÕES
import React, { useState, useEffect } from 'react';/*eslint-disable*/
import {formatarData} from '../../../../utils/formatadorDataTabela'/*eslint-disable*/

import { useDispatch, useSelector, connect } from 'react-redux';
import { mudarManejador, mudarFiltros, limpouBusca, realizouBusca, limpadorFiltros, revelarBarraFiltragem } from "../../../../redux/actions/index"
import {api} from '../../../../conexoes/api';
import {Button, ButtonToolbar} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Paginador, { PaginationProvider } from 'react-bootstrap-table2-paginator';
//ICONES
import {lupa} from '../../../../utils/alternanciaIcones'
//COMPONENTES
import PaginacaoTabela from '../../../../utils/paginacaoTabela'
import MaisInformacoes from './maisInformacoes';
import BarraDadosCliente from '../../../componentesModais/barraDadosCliente/barraDadosCliente'
import BarraFiltragem from '../../../componentesModais/barraFiltragem/barraFiltragem'
// SPINNER
import SpinerVeye from '../../../../components/spinnerVeye/spinnerVeye.js'
//ESTLIZACAO
import '../../../../css/painelAdministrativo/logs/acessoLog/acessoLog.css'
// COLETA CLICKSTREAM
import ColetaClickstream from '../../../../components/clickstreamAnalise/clickstreamColeta.js'

let contador = 0
let controladorPagina = 1
let totalPaginas = 0
let totalItens = 0
let itensVar = []

function Grid(props){
    //HOOKS TABELA
    const [itens, setItens] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [renderizar, setRenderizar] = useState(false);
    const [valorLimite, setValorLimite] = useState(50); // eslint-disable-next-line
    const [maisInfo, setMaisInfo] = useState([])
    const [revelarMaisInformacoes, setRevelarMaisInformacoes] = useState(false)
    const [renderizarMaisInformacoes, setRenderizarMaisInformacoes] = useState(false)
    //HOOKS REDUX
    let visualizarDados =  useSelector(state => state.virtueyesState.visualizarDados);
    let conteudoBusca = useSelector(state => state.manejadorState.conteudoBusca);
    let limpouBuscaGeral = useSelector(state => state.manejadorState.limpouBusca);
    let realizouBuscaGeral = useSelector(state => state.manejadorState.realizouBusca);
    
    // SPINNER
    const [spinAtivo, setSpinAtivo] = useState(false);

    //REDUX VARIÁVEIS
    const despacho = useDispatch();
    let revelarBarraFiltro = useSelector(state => state.filtrosState.revelarBarraFiltragem);
    let realizouFiltragem = useSelector(state => state.manejadorState.realizouFiltro);
    let filtros = useSelector(state => state.filtrosState.filtros);
    let temFiltros = false;
    // USABILIDADE
    const [coletaUsabilidade, setColetaUsabilidade] = useState(true)

    useEffect(() => {
        if(coletaUsabilidade) {
            ColetaClickstream(164) // 'logsAcessoLogTabela'
            setColetaUsabilidade(false)
        }
    },[])
    
    useEffect(() => {
        function checarFiltros() {
            if (filtros.vazio === 'vazio') {
                setItens("")
                temFiltros = false;
            } else {
                setItens("")
                temFiltros = true;
            }
        }
        checarFiltros();
    }, [filtros]);

    useEffect(() => {
        
        setSpinAtivo(true)
        //despacho redux
        despacho(mudarManejador("acessoLog"));
        async function carregarTabela(){

            //clicou no botão de limpar a busca geral e retorna tudo ao estado original
            if(limpouBuscaGeral){
                despacho(limpouBusca(false));
                restaurarConfigsIniciaisPagina()
            }

            //clicou no botão de busca geral
            if(realizouBuscaGeral){
                despacho(realizouBusca(false));
                restaurarConfigsIniciaisPagina()
            }    

            if(conteudoBusca  && !temFiltros){
                if(totalPaginas == 0){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }
                else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    await contarItens();
                    const { data } = await api.get('/m1/log/consultar/acessoLog?paginaAtual='+contador+'&carregarLimit='+valorLimite+'&buscar='+conteudoBusca);
                    inserirData(data.dados)
                }
            }else if(temFiltros){
                if(totalPaginas == 0 || temFiltros){
                    contador = 0;
                    itensVar = [];
                    setItens([])
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                const dadosConsulta = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar: conteudoBusca,
                    filtros: filtros,
                    contar: false
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.post("/m1/log/filtrar/acessoLog", dadosConsulta);
                    
                    inserirData(data.dados)
                }
            }else{
                if(totalPaginas == 0){
                    await contarItens();
                }else{
                    contador = (paginaAtual - 1) * valorLimite;
                }
                if (controladorPagina <= paginaAtual) {
                    const { data } = await api.get('/m1/log/consultar/acessoLog?paginaAtual='+contador+'&carregarLimit='+valorLimite);
                    inserirData(data.dados)
                }
            }
        }
        carregarTabela();
    }, [paginaAtual, conteudoBusca, renderizar, filtros])

    useEffect(()=>{
        despacho(revelarBarraFiltragem(false))
        if(!filtros.vazio){
            despacho(mudarFiltros({vazio: 'vazio'}))
        }
        despacho(limpadorFiltros(true))
    }, [])

    function inserirData(data){
        for(let i=0;i<totalItens;i++){
            if (contador === i){
                let k = i
                for(let j=0;j<data.length;j++){
                itensVar[k] = data[j]
                k++
                }
            }
            if(i == (totalItens-1)){
                setItens(JSON.parse(JSON.stringify(itensVar)))
            }
        }
        setSpinAtivo(false)
    }
  
    async function contarItens(){
        let apiData = []
        let arrayItens = []
        if (controladorPagina <= paginaAtual) { 
            if (controladorPagina <= paginaAtual && filtros.vazio) {
                apiData = await api.get('/m1/consultar/acessoLog?contar="true"&buscar='+conteudoBusca);
            }else{
                const dadosContagem = {
                    paginaAtual: contador,
                    carregarLimit: valorLimite,
                    buscar:conteudoBusca,
                    filtros: filtros,
                    contar: true
                }
                apiData = await api.post("/m1/filtrar/acessoLog", dadosContagem);
                
            }
            itensVar = []
            if(apiData.data.dados){
                totalPaginas = Math.ceil(apiData.data.dados[0].CONTADOR / valorLimite)
                totalItens = apiData.data.dados[0].CONTADOR;
                for( var i = 0 ; i < apiData.data.dados[0].CONTADOR; i++){
                    arrayItens.push(i)
                }
                itensVar = arrayItens
            }
        }
    }

    function restaurarConfigsIniciaisPagina(){
        totalPaginas = 0
        contador = 0
        controladorPagina = 1
        totalItens = 0
        setPaginaAtual(1)
    }

    const colunas = [{
        dataField: 'id',
        text: 'ID',
        hidden:true,
        sort: true,
        headerClasses: 'tabela-coluna-primeira-header nao-selecionavel',
        headerStyle: (colum, colIndex) => {
            return { width: '130px'}
        },
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
   
    {
        dataField: 'usuario',
        headerClasses: 'nao-selecionavel',
        text: 'Usuário',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell ? cell : "-"}</p>;
        }
    },
    
    {
        dataField: 'status_resposta',
        headerClasses: 'nao-selecionavel',
        text: 'Status resposta',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: 'acao',
        headerClasses: 'nao-selecionavel',
        text: 'Ação',
        sort: true,
        formatter: (cell, row) => {
            return <p className="fonte-cor-1 label-tabela-1">{cell}</p>;
        }
    },
    {
        dataField: "dt_created_at",
        headerClasses: 'nao-selecionavel',
        text: 'Data',
        sort: true,
        formatter: (cell, row) => {
            if(cell && cell != "0000-00-00 00:00:00"){
                cell = cell.replace('T', ' ').split('.')
                if(cell.length > 0){
                    cell = cell[0]
                }
            }
            return formatarData(cell);
        }
    },
    
    {
        dataField: '',
        text: colunaAcoes(),
        headerClasses: 'centralizar tabela-coluna-ultima-header nao-selecionavel',
        classes: 'botoes-barra-acoes',
        formatter: (cell, row) => {
            return (
                <ButtonToolbar>
                    <Button variant="info" className="botoes-tabela-acoes" size="sm" onClick={() => handleVisualizarMaisInformacoes()}>
                        <img alt="lupa" className="icone-botao-acao-tabela" src={lupa()}/>
                    </Button>
                </ButtonToolbar>
            )
        }
    }
    ]

    function colunaAcoes(){
        return(
            <p className="acoes">Mais 
            </p>
        );
    }
    
    function handleVisualizarMaisInformacoes(){
        setSpinAtivo(true)
        setRevelarMaisInformacoes(true)
        setRenderizarMaisInformacoes(!renderizarMaisInformacoes)
        setSpinAtivo(false)
    }
 
    const eventosLinhas = {
        onClick: (e, row, rowIndex) => {
            let maisInfo = {}
            maisInfo.ip = row.ip
            maisInfo.token= row.token ? row.token : '-';
            setMaisInfo(maisInfo)            
        }
    }

    const opcoesPaginacao = {
        page: paginaAtual,
        showTotal: true,
        sizePerPage: valorLimite,
        firstPageTitle: 'Primeira',
        lastPageTitle: 'Última',
        nextPageTitle: 'Proxima',
        prePageTitle: 'Anterior',
        custom: true,
        onPageChange:(page) => {setPaginaAtual(page)}
    }

    return(
        <div className='container-tela'>
        {visualizarDados === true &&
            <BarraDadosCliente/>
        }
        { revelarBarraFiltro === true &&
            <BarraFiltragem/>
        }
        { spinAtivo && <div className="componente-spinner-overlay"><div className="componente-spinner-container"><SpinerVeye/></div></div> }
            { revelarMaisInformacoes === true &&
                <div className="componente-modal-overlay">
                    <div className="componente-modal">
                        <MaisInformacoes
                            maisInfo={maisInfo}
                            renderizar={renderizar}
                            setRenderizar={setRenderizar}
                            setRevelarMaisInformacoes={setRevelarMaisInformacoes}
                        >
                        </MaisInformacoes>
                    </div>
                </div>
            }
            <PaginationProvider pagination={Paginador(opcoesPaginacao)} >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <BootstrapTable
                                hover={true}
                                condensed={true}
                                keyField='ID' 
                                data={itens} 
                                columns={colunas} 
                                rowEvents={eventosLinhas}
                                bootstrap4={true}
                                bordered={false}
                                classes="tabela"
                                noDataIndication={!spinAtivo && "Nenhum item encontrado"}
                                { ...paginationTableProps }
                            />
                             <PaginacaoTabela
                                setPaginaAtual={setPaginaAtual}
                                totalPaginas={totalPaginas}
                                totalItens={totalItens}
                                valorLimite={valorLimite}
                                paginaAtual={paginaAtual}
                                page={paginationProps.page}
                                onPageChange={paginationProps.onPageChange}
                            />
                        </>
                    )
                }
            </PaginationProvider>
            <text className="itens-exibidos-tabela fonte-cor-1">
                Mostrando 
                <span className="label-tabela-itens-exibidos">{contador + 1}</span>
                    - 
                <span className="label-tabela-itens-exibidos">{ contador + valorLimite <= totalItens ? contador + valorLimite : totalItens }</span>
                    de 
                <span className="label-tabela-itens-exibidos">{totalItens}</span>
            </text>
           
        </div>
    )
}

function mapStateToProps(state) {
    return { conteudoBusca: state.manejadorState.conteudoBusca, filtros: state.filtrosState.filtros };
} 
//React.memo - nao permitir a renderizacao se o state e o props nao mudarem de estado
export default connect(mapStateToProps)(React.memo(Grid));