//IMPORTAÇÕES
import React, { useState, useEffect, useRef, useCallback } from "react";

import { useForm } from "react-hook-form";
import { Button, Form, Col } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import decryptJWT from "./../../utils/decryptJWT";

//ICONES
import {
  importarArquivo,
  elipseVerde,
  fecharJanela,
  exclamacao,
} from "../../utils/alternanciaIcones";
import iconeTemplate from "../../icones/exportarBranco.svg";
//REDUX
import { useDispatch, useSelector } from "react-redux";
//MODAIS
import MensagemAtencao from "../componentesModais/mensagens/mensagemAtencao";
import MensagemSucesso from "../componentesModais/mensagens/mensagemSucesso";
import MensagemErro from "../componentesModais/mensagens/mensagemErro";
// SPINNER
import SpinerVeye from "../spinnerVeye/spinnerVeye.js";
//CSS
import "../../css/pavFiltros/filtros.css";

// COLETA CLICKSTREAM
import ColetaClickstream from "../../components/clickstreamAnalise/clickstreamColeta.js";

import {
  revelarImportarFebrabans,
  revelarAssociarFatura,
} from "../../redux/actions";
import {
  exportarXlsx,
  extrairArquivoParaBusca,
} from "../../utils/exportarArquivo";
import { CustomTooltip } from "../tooltip/index.jsx";

export default function BuscaArquivo(props) {
  // SPINNER
  const [arquivos, setArquivos] = useState([]);
  const [spinAtivo, setSpinAtivo] = useState(true);
  //HOOKS VALIDAÇÂO
  //HOOKS PRINCIPAIS
  const [mensagemErro, setMensagemErro] = useState(
    "Ocorreu um erro ao executar esta ação"
  );
  const [nomeArquivo, setNomeArquivo] = useState("");

  //HOOKS MODAIS
  const [revelarMensagemAtencao, setRevelarMensagemAtencao] = useState(false);
  const [revelarMensagemSucesso, setRevelarMensagemSucesso] = useState(false);
  const [revelarMensagemErro, setRevelarMensagemErro] = useState(false);
  //OUTROS HOOKS
  const despacho = useDispatch();
  const inputFileRef = useRef(null);
  const modal = useRef();
  const { handleSubmit } = useForm();

  const manejador = useSelector((state) => state.manejadorState.manejador);

  // USABILIDADE
  const [coletaUsabilidade, setColetaUsabilidade] = useState(true);

  const [arrayDadosExportacaoXlsXlsx, setarrayDadosExportacaoXlsXlsx] =
    useState([
      ["Linha", "ICCID"],
      ["'", "'"],
    ]);

  const handleCancelar = useCallback(() => {
    ColetaClickstream(1166); /*dropFiltrosFiltroPorArquivoHandleCancelar*/
    setSpinAtivo(false);
    props.setRenderizar(!props.renderizar);
    despacho(revelarImportarFebrabans(false));
    despacho(revelarAssociarFatura(false));
  }, [despacho, props]);

  const handleClickFora = useCallback(
    (e) => {
      if (!modal.current.contains(e.target)) {
        handleCancelar();
      }
    },
    [handleCancelar]
  );

  useEffect(() => {
    if (coletaUsabilidade) {
      ColetaClickstream(1165); /*dropFiltrosFiltroPorArquivo*/
      setColetaUsabilidade(false);
    }
  }, [coletaUsabilidade]);

  useEffect(() => {
    setSpinAtivo(true);
    document.addEventListener("mousedown", handleClickFora);
    setSpinAtivo(false);
    return () => document.removeEventListener("mousedown", handleClickFora);
  }, [handleClickFora]);

  async function handleSalvar(e) {
    ColetaClickstream(1169); /*dropFiltrosFiltroPorArquivoFiltrar*/
    const target = e.target;
    e.preventDefault();

    setSpinAtivo(true);

    const { erroExportar, dadosArquivo } = await extrairArquivoParaBusca({
      arquivoImportado: arquivos,
      manejador,
    });

    if (dadosArquivo) {
      props.setNomeArquivo(nomeArquivo);
    } else if (erroExportar) {
      setSpinAtivo(false);
      setMensagemErro(erroExportar?.msg || "Arquivo inválido.");
      setRevelarMensagemErro(true);
    }

    props?.arrayFiltrosSimcard?.push("Busca por arquivo?BUSCA_ARQUIVO");
    if (props?.handleFuncaoEstadosRedux)
      await props?.handleFuncaoEstadosRedux();

    if (props?.enviarBuscaArquivo) {
      await props.enviarBuscaArquivo({ target }, dadosArquivo);
    } else if (props?.enviarFiltros) {
      await props.enviarFiltros(e, dadosArquivo);
    }
    props.setRenderizar(!props.renderizar);
    setSpinAtivo(false);
  }

  function handleMensagemAtencao(confirmacao) {
    if (revelarMensagemAtencao === false) {
      setRevelarMensagemAtencao(true);
    } else {
      setRevelarMensagemAtencao(false);
      if (confirmacao === true) {
      }
    }
  }

  function handleMensagemSucesso(confirmacao) {
    if (revelarMensagemSucesso === false) {
      setRevelarMensagemSucesso(true);
    } else {
      setRevelarMensagemSucesso(false);
      handleCancelar();
    }
    if (confirmacao) {
    }
  }

  function handleMensagemErro(confirmacao) {
    if (revelarMensagemErro === false) {
      setRevelarMensagemErro(true);
    } else {
      setRevelarMensagemErro(false);
    }
    if (confirmacao === true) {
      //ALGUMA FUNÇÃO
    }
  }

  //Funcao para ler os dados do arquivo
  function handleLerArquivo(event) {
    let files = event.target.files[0];
    setNomeArquivo(files.name);
    setArquivos(files);
  }

  function ListaArquivos({ files }) {
    return (
      <div className="Container">
        {files.length != 0 && (
          <>
            <p className="titulo-lista-pedidos-fatura fonte-cor-1">
              Arquivo selecionado
            </p>
            <div className="titulo-lista-pedidos-fatura fonte-cor-1">
              <li className="lista-pedidos-faturas campo-texto-cor-3">
                <p className="lista-nome-pedidos-pedidos">{nomeArquivo}</p>
                <div>
                  <MdCheckCircle size={24} color="#36D896" />
                </div>
                <div className="botaoLixeira">
                  <button
                    className="excluir"
                    onClick={(e) => handleDelete(e, arquivos)}
                  >
                    <div className="lixeira" />
                  </button>
                </div>
              </li>
            </div>
          </>
        )}
      </div>
    );
  }

  function handleDelete(e) {
    e.preventDefault();
    setArquivos([]);
    setNomeArquivo("");
    if (inputFileRef.current) inputFileRef.current.value = "";
  }

  const handleClickOutroBotao = (e) => {
    ColetaClickstream(1167); /*dropFiltrosFiltroPorArquivoImportar*/
    const importar = document.getElementById("importar-filtro-arquivo");
    importar.click();
  };

  function exportarXlsXlsx() {
    ColetaClickstream(1168); /*dropFiltrosFiltroPorArquivoExportarXlsXlsx*/

    exportarXlsx(arrayDadosExportacaoXlsXlsx, "Template.xls");
  }

  return (
    <Form ref={modal} onSubmit={handleSubmit((e) => handleSalvar(e))}>
      <div
        className={
          props.rota && props.rota == "filtroSimcard"
            ? "modal-filtro-arquivo-simcard fundo-cor-6"
            : "modal-filtro-arquivo fundo-cor-6"
        }
      >
        {spinAtivo && (
          <div className="componente-spinner-overlay">
            <div className="componente-spinner-container">
              <SpinerVeye />
            </div>
          </div>
        )}
        <Form.Row>
          <Col>
            <img
              className="icone-label-janela nao-selecionavel"
              src={elipseVerde()}
              alt="elipse"
            />
            <Form.Label>
              <div className="fonte-cor-1 label-janela">
                Importar arquivo para busca
              </div>
            </Form.Label>
            <Button
              className="botao-fechar-janela"
              variant="danger"
              onClick={() => handleCancelar()}
            >
              <img
                className="botao-fechar-janela-icone"
                src={fecharJanela()}
                alt="fechar-janela"
              />
            </Button>
          </Col>
        </Form.Row>
        {/* CORPO */}

        <Form.Row className="usuario-dados-corpo-linha">
          <Col>
            <Form.Label>
              <div className="fonte-cor-1 label-campo">Arquivo</div>
            </Form.Label>
            <div>
              <input
                ref={inputFileRef}
                id="importar-filtro-arquivo"
                type="file"
                onChange={handleLerArquivo}
                className="input-filtro-arquivo"
              />
              <CustomTooltip text="Importar arquivos">
                <Button
                  className="btn-filtro-arquivo"
                  onClick={(e) => handleClickOutroBotao(e)}
                >
                  <div className="fonte-cor-4">Importar</div>
                  <img
                    alt="filtro-arquivo"
                    className="img-filtro-arquivo"
                    src={importarArquivo()}
                  />
                </Button>
              </CustomTooltip>
            </div>
          </Col>

          <Col>
            <Form.Label>
              <div className="fonte-cor-1 label-campo">Template</div>
            </Form.Label>
            <div>
              <CustomTooltip text="Template Arquivo">
                <Button
                  className="btn-filtro-arquivo-template"
                  onClick={() => exportarXlsXlsx()}
                >
                  <div className="fonte-cor-4 texto-arquivo-template-2">
                    Template
                  </div>
                  <img
                    alt="filtro-arquivo-template"
                    className="img-filtro-arquivo-template"
                    src={iconeTemplate}
                  />
                </Button>
              </CustomTooltip>
            </div>
          </Col>
        </Form.Row>
        <ListaArquivos files={nomeArquivo} />
        <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-filtro-arquivo">
          {iconeAlerta} Os tipos de arquivos suportados são '.csv e .xlsx'
        </p>
        <p className="label-alerta-pedidos-margem fonte-cor-3 label-alerta-filtro-arquivo">
          {iconeAlerta} O arquivo deve conter um cabeçalho com uma coluna com
          'LINHA' ou 'ICCID'
        </p>

        <div className="drop-dados-conta-divisora campo-select-divisor-cor-1 conta-dados-divisoria-corpo" />
        <Form.Row className="espaco-reativar-linha">
          <Col>
            <>
              {arquivos.length != 0 ? (
                <Button
                  type="submit"
                  className="botao-confirmar"
                  variant="success"
                  size="sm"
                  onClick={(e) => handleSalvar(e)}
                >
                  <div className="fonte-cor-4">Buscar</div>
                </Button>
              ) : (
                <Button className="botao-confirmar-desabilitado" size="sm">
                  <div className="fonte-cor-4">Buscar</div>
                </Button>
              )}
              <Button
                className={"botao-cancelar"}
                variant="success"
                size="sm"
                onClick={() => handleCancelar()}
              >
                <div className="fonte-cor-2">Cancelar</div>
              </Button>
            </>
          </Col>
        </Form.Row>
      </div>
      {revelarMensagemSucesso && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemSucesso
              handleMensagemSucesso={handleMensagemSucesso}
              mensagemTitulo={"Sucesso"}
              mensagemParagrafo1={"Sua solicitação foi enviada."}
              mensagemBotao={"Continuar"}
            />
          </div>
        </div>
      )}
      {revelarMensagemAtencao && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemAtencao
              handleMensagemAtencao={handleMensagemAtencao}
              mensagemTitulo={"Remover Linha?"}
              mensagemBotao={"Remover"}
            />
          </div>
        </div>
      )}
      {revelarMensagemErro && (
        <div className="componente-modal-overlay">
          <div className="componente-modal">
            <MensagemErro
              handleMensagemErro={handleMensagemErro}
              mensagemTitulo={"Erro"}
              mensagemParagrafo1={mensagemErro}
              mensagemBotao={"Retornar"}
            />
          </div>
        </div>
      )}
    </Form>
  );
}

const iconeAlerta = (
  <img className="icone-alerta" src={exclamacao()} alt="exclamacao" />
);
